<template>
  <Teleport to="body">
    <div
      class="popup__bg"
      @click="close"
    />
    <div
      ref="modal"
      class="popup"
    >
      <div
        id="popup-content"
        class="popup-content"
        :class="[modeNoPaddingClass]"
      >
        <img
          src="/images/icon_close_new.png"
          alt="close"
          class="popup__close"
          @click="close"
        >
        <div class="content-wrapper scrollable-Y">
          <slot name="content" />
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script>
export default {
  name: 'Popup',

  props: {
    modeNoPadding: {
      type: Boolean,
      require: false,
      default: false,
    },
  },

  emits: ['close'],

  computed: {
    modeNoPaddingClass() {
      return this.modeNoPadding ? 'modeNoPadding' : '';
    },
  },

  mounted() {
    this.$refs.modal.style.top = `calc(15vh + ${window.scrollY}px)`;
    this.onResize();
    window.addEventListener('resize', this.onResize);
    document.body.style.overflow = 'hidden';
  },

  unmounted() {
    window.removeEventListener('resize', this.onResize);
    if (!document.getElementsByClassName('popup__background')[0]) {
      // Костыль, на случай наличия другого открытого попапа
      document.body.style.overflow = 'initial';
    }
  },

  methods: {
    close() {
      this.$emit('close');
    },

    onResize() {
      document.getElementById('popup-content').style.maxHeight = `calc(${window.innerHeight * 0.75}px - 70px)`;
    },
  },
};
</script>

<style scoped>
.popup {
  position: absolute;
  left: 50%;
  top: 0;
}

.popup__bg {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0);
  opacity: 0.5;
  top: 0;
  left: 0;
  z-index: 400;
  display: block;
}

.popup-content {
  display: flex;
  flex-direction: column;
  gap: 35px;
  position: absolute;
  z-index: 400;
  padding: 35px;
  background-color: #fff;
  border-radius: 15px;
  transform: translateX(-50%);
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.popup__close {
  width: 30px;
  height: 30px;
  position: absolute;
  z-index: 400;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

.modeNoPadding {
  padding: 0;
}
</style>
