<template>
  <div class="rubrics-selector">
    <span class="rubrics-selector__title">
      Сферы деятельности:
    </span>
    <div @click="openRubricsTree">
      <ButtonSelector
        :content="getDescriptionForRubrics"
        is-important-selector
        width="200px"
        class="rubrics-tree-button"
        :class="{'selector-nothing-selected': nothingSelected & itIsTimeToShow}"
        font-size="17px"
      />
    </div>
  </div>
</template>

<script>
import ButtonSelector from 'button-selector';
import plural from 'plural-ru';
import { mapGetters, mapMutations, mapState } from 'vuex';

export default {
  name: 'RubricsSelector',
  components: {
    ButtonSelector,
  },

  props: {
    itIsTimeToShow: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    ...mapState('rubricsTree', [
      'loadingRubrics',
    ]),

    ...mapGetters('rubricsTree', [
      'getSelectedSections',
    ]),

    getDescriptionForRubrics() {
      const countRubrics = this.getSelectedSections.length;
      if (countRubrics === 0) return 'Не выбрано';

      return `${countRubrics} ${plural(countRubrics, 'рубрика', 'рубрики', 'рубрик')}`;
    },

    nothingSelected() {
      return (this.getSelectedSections.length === 0);
    },
  },

  methods: {
    ...mapMutations('rubricsTree', [
      'setVisibleTree',
    ]),

    openRubricsTree() {
      if (this.loadingRubrics) return;
      this.setVisibleTree(true);
    },
  },
};
</script>

<style scoped src="../../../assets/css/element.css" />

<style scoped>
.rubrics-selector {
  display: flex;
  gap: 5px;
  flex-direction: column;
}

.rubrics-selector__title {
  font-weight: bold;
  font-size: 14px;
}
</style>
