export default {
    namespaced: true,

    state: {
        cost: 0,
        count: 0,
        visibleDiscount: 0,
        speedometrAngle: 0,
        bonusValue: 0,
    },

    getters: {
        totalCount(state) {
            return state.count;
        },

        totalCost(state) {
            return state.cost;
        },
    },

    mutations: {
        setCost(state, cost) {
            state.cost = cost;
        },

        setCount(state, count) {
            state.count = count;
        },

        setBonus(state, value) {
            state.bonusValue = value;
        },

        setSpeedometrAngleValue(state, value) {
            state.speedometrAngle = value;
        },

        setVisibleDiscount(state, value) {
            state.visibleDiscount = Math.round(value);
        },

    },

    actions: {
        updatePageInfo({ commit }, data) {
            commit('setCost', data.cost.total);
            commit('setCount', data.count.total);
            commit('setSpeedometrAngleValue', data.additional.speedometerAngle);
            commit('setVisibleDiscount', data.additional.vygoda);
            commit('setBonus', Number(data.additional.userBonus) || 0);
        },

        resetPageInfo({ commit }) {
            commit('setCost', 0);
            commit('setCount', 0);
            commit('setVisibleDiscount', 0);
            commit('setRelativeDiscountValue', 0);
        },
    },
};
