const state = {
    fuse: null,
    fuseOptions: {
        keys: ['title'],
        threshold: 0.4,
    },
    expandAdditionalFilters: false,

    needRecalculation: false,
    isCalculating: false,

    searchCompanyType: '',
    companyType: {},
    companyTypeGroups: {},

    minRegYear: 2002,
    maxRegYear: 2023,
    regYears: [],

    floors: [
        {
            id: '0',
            title: 'цокольный',
            included: false,
            excluded: false,
        },
        {
            id: '1',
            title: 'первый',
            included: false,
            excluded: false,
        },
        {
            id: '2',
            title: 'второй',
            included: false,
            excluded: false,
        },
    ],

    geoData: [],
    baloonInfo: [],

    unknownCompanyTypeIncluded: false,

    legalForm: {},
    unknownLegalFormIncluded: false,
    companySize: {},
    unknownCompanySizeIncluded: false,

    mainOkved: 0,
    showRegistrationDate: 0,
    showIncAndProfit: 0,
    showKppOkpo: 0,
    showLegalName: 0,
    showSiteDescription: 0,
    withoutSite: 0,
    hasLicense: 0,
    onlyWithoutSiteDisabled: false,

    selectedBasicFilters: ['phone'],
    selectedOptionalFilters: [],

    mainBasePhoneFilter: {
        name: 'main_phone',
        title: 'Телефон компании',
        selected: true,
        partiallySelected: false,
        subFilters: [
            {
                name: 'only_mobile_phones',
                title: 'только мобильные',
                selected: false,
            },
            {
                name: 'has_site_phone',
                title: 'только телефоны с сайтов',
                selected: false,
            },
            {
                name: 'has_messenger',
                title: 'есть мессенджер',
                selected: false,
            },
        ],
    },

    mainBaseAddressFilter: {
        name: 'main_address',
        title: 'Адрес и индекс',
        selected: false,
        partiallySelected: false,
    },

    mainBaseEmailFilter: {
        name: 'main_email',
        title: 'Email компании',
        selected: false,
        partiallySelected: false,
        subFilters: [
            {
                name: 'email_verified',
                title: 'проверены валидатором',
                selected: true,
            },
        ],
    },

    mainBaseSiteFilter: {
        name: 'main_site',
        title: 'Сайт',
        selected: false,
        partiallySelected: false,
    },

    mainBaseInnOgrnFilter: {
        name: 'main_inn',
        title: 'ИНН / ОГРН / Руководитель',
        selected: false,
        partiallySelected: false,
    },

    mainBaseShowCompanySizeFilter: {
        name: 'main_show_company_size',
        title: 'Численность и выручка',
        selected: false,
        partiallySelected: false,
    },
};

export default state;
