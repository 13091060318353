import api from '../api/index';

export default {
    namespaced: true,

    state: {
        visiblePayPopup: false,
        email: '',
        orderId: '',
        clientId: 0,
        clientBalance: 0,
        customerIsAuthorized: false,
        oldBeznalData: {
            position: '',
            director: '',
            ustav: '',
            nazv: '',
            urAdr: '',
            postIndex: '',
            town: '',
            street: '',
            house: '',
            office: '',
            ogrn: '',
            inn: '',
            kpp: '',
            document_management: '',
        },
        userDiscount: 0,
        initialSection: '',
        demoCreationIsRunning: false,
        demoCreatedSuccessfully: false,
        showOrderPreloader: false,
        cost: 0,
        countCompanies: 0,
        initialTab: '',
        demoHash: '',
    },

    getters: {
        getUrlForBill(state) {
            let url = `${window.location.origin}/api/bill?email_for_beznal=${state.oldBeznalData.email_for_beznal
            }&umb_schet=${state.orderId
            }&count_for_beznal=${state.oldBeznalData.count_for_beznal
            }&cost_for_beznal=${state.oldBeznalData.cost_for_beznal
            }&inn=${state.oldBeznalData.inn
            }&nazv=${state.oldBeznalData.nazv}`;

            if (state.oldBeznalData.urAdr.length !== 0) {
                url += `&urAdr=${state.oldBeznalData.urAdr
                }&director=${state.oldBeznalData.director}`;
            }
            return url;
        },

        getBeznalData(state) {
            return state.oldBeznalData;
        },
    },

    mutations: {
        setOrderId(state, value) {
            state.orderId = value;
        },
        setCost(state, value) {
            state.cost = value;
        },
        setCountCompanies(state, value) {
            state.countCompanies = value;
        },
        setClientId(state, value) {
            state.clientId = value;
        },
        setEmail(state, value) {
            state.email = value;
        },
        setAuthStatus(state, value) {
            state.customerIsAuthorized = value;
        },
        setOldBeznalData(state, value) {
            state.oldBeznalData = value;
        },
        setUserBalance(state, balance) {
            state.clientBalance = balance;
        },
        setVisiblePayPopup(state, value) {
            state.visiblePayPopup = value;
        },
        setUserDiscount(state, value) {
            state.userDiscount = value;
        },
        setOptionOldBeznalData(state, {
            option,
            value,
        }) {
            state.oldBeznalData[option] = value;
        },
        setInitialSection(state, value) {
            state.initialSection = value;
        },
        setDemoCreationIsRunning(state, value) {
            state.demoCreationIsRunning = value;
        },
        setDemoCreatedSuccessfully(state, value) {
            state.demoCreatedSuccessfully = value;
        },
        setShowOrderPreloader(state, value) {
            state.showOrderPreloader = value;
        },
        setInitialTab(state, value) {
            state.initialTab = value;
        },
        setDemoHash(state, value) {
            state.demoHash = value;
        },
    },

    actions: {
        async getAuthStatus({ commit }) {
            const auth = await api.checkAuth();
            commit('setAuthStatus', Boolean(auth));
        },
        async getUserData({ commit }) {
            const userData = await api.getUserData();
            commit('setEmail', userData.email);
            commit('setUserBalance', userData.balance);
        },

        async getOrderData({ state }, createConfig) {
            return {
                cashless_payment_config: JSON.stringify(state.oldBeznalData),
                calculation_config: createConfig,
                type: 'export',
                email: state.email,
                cost: state.cost,
                count: state.countCompanies,
                saved_email: '',
            };
        },

        async getDataToCreateBill({ state, dispatch }, createConfig) {
            const localData = await dispatch('getOrderData', createConfig);
            localData.email = state.oldBeznalData.email_for_beznal;
            return {
                ...localData,
                plata: 1,
            };
        },

        async getUserDiscount({ commit }, email) {
            const discount = await api.getUserDiscount(email);
            commit('setUserDiscount', discount.disc);
        },

        async createOrder({
            state, getters, commit, dispatch,
        }, data) {
            await dispatch('getUserDiscount', state.email);
            const order = await api.createOrder(data);
            commit('setOrderId', String(order.id_plan));
            commit('setClientId', Number(order.customer_number));

            return order;
        },

        async getDataForDemo({ state }, calculationConfig) {
            return {
                calculationConfig,
                cost: state.cost,
                count: state.countCompanies,
            };
        },

        getDemoFileHash({}, dataForDemo) {
            return api.createDemoLink(dataForDemo);
        },

        setDemoHash({ commit }, value) {
            commit('setDemoHash', value);
        },

        async saveOrder({}, data) {
            await api.createOrder(data);
        },
    },
};
