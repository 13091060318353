<template>
  <div class="rubrics-selector">
    <span class="rubrics-selector__title">
      Сферы деятельности:
    </span>
    <div @click="openRubricsTree">
      <ButtonSelector
        :content="getDescriptionForRubrics"
        is-important-selector
        width="200px"
        class="rubrics-tree-button"
        :class="{'selector-nothing-selected': nothingSelected && itIsTimeToShow}"
        font-size="17px"
      />
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import ButtonSelector from 'button-selector';
import plural from 'plural-ru';

export default {
  name: 'RubricsSelector',
  components: {
    ButtonSelector,
  },

  props: {
    itIsTimeToShow: {
      type: Boolean,
      required: true,
    },
  },

  setup() {
    const store = useStore();
    const loadingRubrics = computed(() => store.state.rubricsTree.loadingRubrics);
    const getSelectedSections = computed(() => store.getters['rubricsTree/getSelectedSections']);

    const setVisibleTree = (value) => store.commit('rubricsTree/setVisibleTree', value);

    const getDescriptionForRubrics = computed(() => {
      const countRubrics = getSelectedSections.value.length;
      if (countRubrics === 0) return 'Не выбрано';
      return `${countRubrics} ${plural(countRubrics, 'рубрика', 'рубрики', 'рубрик')}`;
    });

    const nothingSelected = computed(() => getSelectedSections.value.length === 0);

    const openRubricsTree = () => {
      if (loadingRubrics.value) return;
      setVisibleTree(true);
    };

    return {
      getDescriptionForRubrics,
      nothingSelected,
      openRubricsTree,
    };
  },
};
</script>

<style scoped src="../../../assets/css/element.css" />

<style scoped>
.rubrics-selector {
  display: flex;
  gap: 5px;
  flex-direction: column;
}

.rubrics-selector__title {
  font-weight: bold;
  font-size: 14px;
}
</style>
