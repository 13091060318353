import axios from "axios";

export default {
    getCompanyTypeData() {
        return fetch('/api/main_base/company_type')
            .then((companyType) => companyType.json());
    },
    getLegalForm() {
        return fetch('/api/main_base/legal_form')
            .then((legalForm) => legalForm.json());
    },
    getCompanySize() {
        return fetch('/api/main_base/company_size')
            .then((companySize) => companySize.json());
    },
    getPrice() {
        return fetch('/api/main_base/price', { method: 'GET' })
            .then((price) => price.json());
    },
    getRubrics() {
        return fetch('/api/main_base/sections', {
            method: 'GET',
        }).then((res) => res.json());
    },
    async getDistricts() {
        return (await axios.get('/api/main_base/districts')).data;
    },
    getCities() {
        return fetch('/api/company_search_by_key/cities', { method: 'GET' })
            .then((cities) => cities.json());
    },
    getTerritories() {
        return fetch('/api/company_search_by_key/territories', { method: 'GET' })
            .then((cities) => cities.json());
    },
    searchRubricsByWord(searchString) {
        return fetch('/api/main_base/search_rubrics_by_word', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ word: searchString }),
        })
            .then((res) => res.json());
    },
};
