<template>
  <div>
    <!-- Первый вариант - неполные контакты разрешены -->
    <div
      v-if="incompleteContactsAllowed"
    >
      <div v-if="!appliedToPhone && !appliedToEmail">
        Сейчас у всех компаний в выгрузке будут заполнены выбранные поля.
        <br>
      </div>

      <br>
      Чтобы получить больше компаний, переключите у фильтров чекбоксы "Обязательное поле"
    </div>

    <!-- Второй вариант - одно поле и оно заполнено -->
    <div
      v-else-if="singleFieldAndFilled"
    >
      У всех компаний в выгрузке будет присутствовать <br> <b>"{{ fieldNamesAsString }}".</b>
      <br><br>
      Если вы хотите получить больше данных о компании, воспользуйтесь фильтрами.
    </div>
  </div>
</template>

<script>
export default {
  name: 'IncompleteContactsDisabledBalloon',

  props: {
    incompleteContactsAllowed: {
      type: Boolean,
      default: false,
    },

    singleFieldAndFilled: {
      type: Boolean,
      default: false,
    },

    fieldNamesAsString: {
      type: String,
      default: '',
    },

    appliedToPhone: {
      type: Boolean,
      default: false,
    },

    appliedToEmail: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
