<template>
  <div class="separator" />
</template>

<script>
export default {
  name: 'ColorSeparator',
};
</script>

<style scoped>
.separator {
  height: 5px;
  border-radius: 0 0 3px 3px;
  background: linear-gradient(to right,var(--color-main-base-block-prime),var(--color-main-base-block-secondary));
}
</style>
