import api from '../api';
import calcApi from './api';

const actions = {
    enableAllCompanyTypes({ commit, dispatch }) {
        commit('enableAllCompanyTypes');
        dispatch('calculateConfiguration');
    },
    enableAllLegalForms({ commit, dispatch }) {
        commit('enableAllLegalForms');
        dispatch('calculateConfiguration');
    },
    enableAllCompanySizes({ commit, dispatch }) {
        commit('enableAllCompanySizes');
        dispatch('calculateConfiguration');
    },

    toggleIncludeType({ commit, dispatch }, params) {
        commit('toggleIncludeType', params);

        if (params.selectorType === 'companyType') {
            commit('highlightCompanyTypeGroup', params.id);
        }
        dispatch('calculateConfiguration');
    },

    toggleExcludeType({ commit, dispatch }, params) {
        commit('toggleExcludeType', params);

        if (params.selectorType === 'companyType') {
            commit('highlightCompanyTypeGroup', params.id);
        }
        dispatch('calculateConfiguration');
    },

    multipleToggleIncludeType({ commit }, { selectorType, ids }) {
        ids.forEach((item) => {
            const params = { selectorType, id: item };
            commit('toggleIncludeType', params);
        });
        dispatch('calculateConfiguration');
    },
    resetMultipleToggleIncludeType({ commit }, { selectorType, ids }) {
        ids.forEach((item) => {
            const params = { selectorType, id: item };
            commit('resetToggleIncludeType', params);
        });
        dispatch('calculateConfiguration');
    },

    toggleUnknownLegalFormIncluded({ commit, dispatch }) {
        commit('toggleUnknownLegalFormIncluded');
        dispatch('calculateConfiguration');
    },
    toggleUnknownCompanySizeIncluded({ commit, dispatch }) {
        commit('toggleUnknownCompanySizeIncluded');
        dispatch('calculateConfiguration');
    },

    toggleIncludeClosed({ commit, dispatch }) {
        commit('toggleIncludeClosed');
        dispatch('calculateConfiguration');
    },

    toggleAdditionalFilter({
        state, commit, getters, dispatch,
    }, additionalFilter) {
        if (additionalFilter === 'showSiteDescription' && !state.showSiteDescription) {
            if (!state.mainBaseSiteFilter.selected) {
                commit('toggleSite');
            }
            if (state.withoutSite) {
                commit('setWithoutSite', 0);
            }
        }
        if (additionalFilter === 'withoutSite' && !state.withoutSite) {
            if (state.mainBaseSiteFilter.selected) {
                commit('toggleSite');
            }
            if (state.showSiteDescription) {
                commit('setShowSiteDescription', 0);
            }
        }
        commit('toggleAdditionalFilter', additionalFilter);
        dispatch('calculateConfiguration');
    },

    setCompanyTypeData({ commit }) {
        return api.getCompanyTypeData()
            .then((companyType) => {
                commit('setCompanyType', companyType.company_types);
                commit('setFuse', companyType.company_types);
                commit('setCompanyTypeGroups', companyType.groups);
            });
    },
    setLegalForm({ commit }) {
        api.getLegalForm()
            .then((legalForm) => {
                commit('setLegalForm', legalForm);
            });
    },

    setCompanySize({ commit }) {
        api.getCompanySize()
            .then((companySize) => {
                commit('setCompanySize', companySize);
            });
    },

    hideAllCompanyTypesGroups({ commit, getters }) {
        const groupIds = getters.getGroupIds;

        groupIds.forEach((groupId) => commit('setShowCompanyTypeGroup', { groupId, show: false }));
    },

    showAllCompanyTypesGroups({ commit, getters }) {
        const groupIds = getters.getGroupIds;

        groupIds.forEach((groupId) => commit('setShowCompanyTypeGroup', { groupId, show: true }));
    },

    showCompanyTypesGroupsByIds({ commit, dispatch }, groupIds) {
        dispatch('hideAllCompanyTypesGroups');
        groupIds.forEach((groupId) => commit('setShowCompanyTypeGroup', { groupId, show: true }));
    },

    visibleCompanyTypesByIds({ state, commit }, ids) {
        commit('hideTypes');

        Object.entries(state.companyType).forEach(([key]) => {
            if (ids.includes(state.companyType[key].id)) {
                state.companyType[key].visible = true;
            }
        });
    },

    resetSearch({ commit, dispatch }) {
        dispatch('showAllCompanyTypesGroups');
        commit('hideGroups');
        commit('hideTypes');
        commit('resetHiddenTypes');
    },

    search({ state, commit, dispatch }) {
        const searchedCompanyTypes = state.fuse.search(state.searchCompanyType).map((itemData) => itemData.item);

        const groupIds = [...new Set(searchedCompanyTypes.map((companyType) => companyType.groupId))];

        const typesIds = searchedCompanyTypes.map((companyType) => companyType.id);

        dispatch('showCompanyTypesGroupsByIds', groupIds);
        commit('activateAllGroups');
        commit('resetHiddenTypes');
        dispatch('visibleCompanyTypesByIds', typesIds);
    },

    toggleMainBasePhone({ commit, dispatch, getters }, isPartiallySelectedChange = false) {
        commit('togglePhone', isPartiallySelectedChange);
        if (!getters.isPartiallySelectPossible) {
            commit('resetPartiallySelected');
        }
        dispatch('calculateConfiguration');
    },

    toggleMainBaseAddress({ commit, dispatch, getters }, isPartiallySelectedChange = false) {
        commit('toggleAddress', isPartiallySelectedChange);
        if (!getters.isPartiallySelectPossible) {
            commit('resetPartiallySelected');
        }
        dispatch('calculateConfiguration');
    },

    toggleMainBaseEmail({ commit, dispatch, getters }, isPartiallySelectedChange = false) {
        commit('toggleEmail', isPartiallySelectedChange);
        if (!getters.isPartiallySelectPossible) {
            commit('resetPartiallySelected');
        }
        dispatch('calculateConfiguration');
    },

    toggleMainBaseSite({
        state, commit, dispatch, getters,
    }, isPartiallySelectedChange = false) {
        commit('toggleSite', isPartiallySelectedChange);
        if (state.mainBaseSiteFilter.selected && state.withoutSite) {
            commit('setWithoutSite', 0);
        }
        if (!state.mainBaseSiteFilter.selected && state.showSiteDescription) {
            commit('setShowSiteDescription', 0);
        }
        if (!getters.isPartiallySelectPossible) {
            commit('resetPartiallySelected');
        }
        dispatch('calculateConfiguration');
    },

    toggleMainBaseInnOgrn({ commit, dispatch, getters }, isPartiallySelectedChange = false) {
        commit('toggleInnOgrn', isPartiallySelectedChange);
        if (!getters.isPartiallySelectPossible) {
            commit('resetPartiallySelected');
        }
        dispatch('calculateConfiguration');
    },

    toggleMainBaseCompanySize({ commit, dispatch, getters }, isPartiallySelectedChange = false) {
        commit('toggleCompanySize', isPartiallySelectedChange);
        if (!getters.isPartiallySelectPossible) {
            commit('resetPartiallySelected');
        }
        dispatch('calculateConfiguration');
    },

    changeMainBasePhoneSubFilter({ commit, dispatch }, subFilterName) {
        commit('togglePhoneSubFilters', subFilterName);
        dispatch('calculateConfiguration');
    },

    changeMainBaseEmailVerifiedSubFilter({ commit, dispatch }) {
        commit('toggleEmailVerifiedSubFilter');
        dispatch('calculateConfiguration');
    },

    async calculateConfiguration({
        state, commit, getters, dispatch,
    }) {
        if (state.isCalculating) {
            commit('setNeedRecalculation', true);
            return;
        }

        commit('setNeedRecalculation', false);
        commit('setCalculating', true);

        const configData = getters.getMainBaseDataForCalculationRequest;

        const res = getters.isUniqueCalculation
            ? await calcApi.calculateUnique(configData)
            : await calcApi.calculateNotUnique(configData);
        if (!res.success) {
            dispatch('summary/resetPageInfo');
        } else {
            dispatch('summary/updatePageInfo', res.info);
            commit('mainBasePayPopup/setCost', res.info.cost.total);
            commit('mainBasePayPopup/setCountCompanies', res.info.count.total);
            commit('initializeGeoData', res.info);
            commit('initializeBallonInfo', res.info.additional.balloonKit);
        }
        commit('setCalculating', false);

        if (state.needRecalculation) {
            dispatch('calculateConfiguration');
        }
    },

    async setRegYears({ commit, state }) {
        const years = [];
        let year = state.maxRegYear;
        while (year >= state.minRegYear) {
            years.push(year);
            year -= 1;
        }
        years.push(`ранее (${state.minRegYear})`);
        const yearsItems = years.map((yearItem) => ({
            key: yearItem,
            name: yearItem,
            selected: false,
        }));
        commit('setRegYears', yearsItems);
    },

    regYearChange({ commit, dispatch }, regYear) {
        commit('changeRegYear', regYear);
        dispatch('calculateConfiguration');
    },

    allRegYearsSelect({ commit, dispatch }) {
        commit('allRegYearsSelect');
        dispatch('calculateConfiguration');
    },

    toggleAllFloorsSelected({ commit, dispatch, state }) {
        const hasSelectedFloors = Object.values(state.floors)
            .some((floor) => floor.included || floor.excluded);
        if (hasSelectedFloors) {
            Object.keys(state.floors).forEach((id) => {
                commit('setFloorInclusion', { id, included: false });
                commit('setFloorExclusion', { id, excluded: false });
            });
        }
        dispatch('calculateConfiguration');
    },

    toggleIncludeFloor({ commit, dispatch, state }, id) {
        const floor = state.floors.find((floor) => floor.id == id);

        commit('setFloorInclusion', {
            id,
            included: !floor.included,
        });

        if (floor.included) {
            commit('setFloorExclusion', { id, excluded: false });
        }

        dispatch('calculateConfiguration');
    },
    toggleExcludeFloor({ commit, dispatch, state }, id) {
        const floor = state.floors.find((floor) => floor.id == id);

        commit('setFloorExclusion', {
            id,
            excluded: !floor.excluded,
        });

        if (floor.excluded) {
            commit('setFloorInclusion', { id, included: false });
        }

        dispatch('calculateConfiguration');
    },

    async getKp({ getters }) {
        window.MFuncs.reachYandexGoal('kp_view');
        const offerData = {};
        offerData.data = await getters.getMainConfigOfferData;
        offerData.data_for_payment = await getters.getDataForCommercialOffer;

        if (!offerData.data) {
            console.error('Сформируйте конфигурацию заказа!');
            return;
        }
        const response = await calcApi.makeConfigOffer(offerData);
        if (response.data.success) {
            window.open(`/api/commercial_offer?code=${response.data.message}`);
        } else {
            echo(
                response.data.message,
                6000,
                'warning',
            );
        }
    },
};

export default actions;
