<template>
  <div class="mainConfigurator">
    <div class="mainConfiguratorBody">
      <div class="mainBlock">
        <div class="selectors-block">
          <RequiredOptions />
          <section class="dropdownSelectors">
            <MainBaseLegalFormSelector
              :show-legal-form="showSelector.legalForm"
              @selectorChange="selectorChange"
            />
            <MainBaseTypeSelector
              :show-company-type="showSelector.companyType"
              @selectorChange="selectorChange"
            />
            <MainBaseSizeSelector
              :show-company-size="showSelector.companySize"
              @selectorChange="selectorChange"
            />
          </section>
        </div>
        <FieldList />
      </div>
      <MainBaseAdditionalFilters />
    </div>
    <ColorSeparator />
    <MainBaseOrderSummary />
  </div>
</template>

<script>
import {
  ref, watch, provide, onMounted, onBeforeUnmount,
} from 'vue';
import { useStore } from 'vuex';
import RequiredOptions from '@/main_base/components/RequiredOptions/RequiredOptions.vue';
import DropdownSelectorUtil from '../../store/utils/DropdownSelectorUtil';
import FieldList from '../FieldList/FieldList.vue';
import MainBaseSizeSelector from '../MainBaseSizeSelector/MainBaseSizeSelector.vue';
import MainBaseTypeSelector from '../MainBaseTypeSelector/MainBaseTypeSelector.vue';
import MainBaseLegalFormSelector from '../MainBaseLegalFormSelector/MainBaseLegalFormSelector.vue';
import ColorSeparator from './ColorSeparator.vue';
import MainBaseOrderSummary from '../MainBaseOrderSummary/MainBaseOrderSummary.vue';
import MainBaseAdditionalFilters from '../MainBaseAdditionalFilters/MainBaseAdditionalFilters.vue';

export default {
  name: 'MainBaseConfigurator',

  components: {
    MainBaseAdditionalFilters,
    MainBaseOrderSummary,
    ColorSeparator,
    MainBaseLegalFormSelector,
    MainBaseTypeSelector,
    MainBaseSizeSelector,
    FieldList,
    RequiredOptions,
  },

  setup() {
    const store = useStore();

    const selectorsDataFetched = ref(false);
    const showSelector = ref({
      legalForm: false,
      companyType: false,
      companySize: false,
    });

    const notAllowedHash = ['#reg_date', '#okved'];

    const showEmailPhoneNotification = async () => {
      const utmValue = decodeURI(window.location.search
        .split('&')
        .filter((item) => item.includes('utm_term=')).toString()
        .split('=')[1]);
      let result = '';
      const emails = ['рассыл', 'mail', 'мэйл', 'мэил', 'мейл', 'меил', 'почт'];
      const phones = ['телефон', 'контакт', 'звон', 'номер'];

      emails.forEach((email) => {
        if (utmValue.indexOf(email) !== -1) {
          result = 'На сайте можно приобрести базу компаний с email';
        }
      });
      if (utmValue.indexOf('эл') !== -1 && utmValue.indexOf('адрес') !== -1) {
        result = 'На сайте можно приобрести базу компаний с email';
      }

      phones.forEach((phone) => {
        if (utmValue.indexOf(phone) !== -1) {
          result = 'На сайте можно приобрести базу компаний с телефонами';
        }
      });

      await new Promise((r) => { setTimeout(r, 5000); });

      if (result) {
        window.echo(result, 8000, 'success');
      }
    };

    const setShowSelector = (selector, value) => {
      showSelector.value[selector] = value;

      for (const type in showSelector.value) {
        if (type !== selector) {
          showSelector.value[type] = false;
        }
      }
    };

    const selectedOptionsDescription = (
      optionsObject,
      otherOptionIncluded,
      allEnabledDescription,
      onlyOtherIncludedDescription,
    ) => {
      if (
        !DropdownSelectorUtil.somethingIncluded(optionsObject, false)
          && !DropdownSelectorUtil.somethingExcluded(optionsObject, false)
      ) {
        return allEnabledDescription;
      }

      const includedOptionsCount = DropdownSelectorUtil
        .includedOptionsCount(optionsObject, otherOptionIncluded);
      const excludedOptionsCount = DropdownSelectorUtil
        .excludedOptionsCount(optionsObject, otherOptionIncluded);

      const mainIncludedOptionValues = DropdownSelectorUtil.mainIncludedOptionValues(optionsObject);
      const mainIncludedOptionsCount = DropdownSelectorUtil.mainIncludedOptionsCount(optionsObject);

      const mainExcludedOptionValues = DropdownSelectorUtil.mainExcludedOptionValues(optionsObject);
      const mainExcludedOptionsCount = DropdownSelectorUtil.mainExcludedOptionsCount(optionsObject);

      if (includedOptionsCount + excludedOptionsCount > 1) {
        return `${includedOptionsCount + excludedOptionsCount} шт.`;
      }

      if (mainIncludedOptionsCount === 1) {
        return mainIncludedOptionValues[0].title;
      }

      if (mainExcludedOptionsCount === 1) {
        return `Исключить ${companyTypeNameToLowerCase(mainExcludedOptionValues[0].title)}`;
      }

      return onlyOtherIncludedDescription;
    };

    const selectorChange = (selectorType) => {
      showSelector.value[selectorType] = !showSelector.value[selectorType];

      if (showSelector.value[selectorType]) {
        Object.keys(showSelector.value).forEach((type) => {
          if (type !== selectorType) {
            showSelector.value[type] = false;
          }
        });
      }
    };

    const outsideEventListenerForShownSelector = (event) => {
      const dataActionArr = [
        'lizing', 'tender', 'sro', 'online-store', 'tender44_participant', 'tender44_winner', 'lizing_takers', 'sro_only',
        'sro_member', 'dealer', 'distributor', 'manufacturer',
      ];

      const buttonMainDropdownSelector = document.getElementsByClassName('buttonMainDropdownSelector');
      const geoPopup = document.getElementsByClassName(
        'wrapper_city_conf m__wrapper_city_conf _wrapper_city_conf _main_cities_wrapper',
      )
        .item(0);

      let path = event.path || (event.composedPath && event.composedPath());
      if (path) {
        if (event.composedPath()) {
          path = event.composedPath().indexOf(geoPopup) !== -1;
        }
      }

      if (
        event.target === buttonMainDropdownSelector
          || event.target.closest('.mainDropdownSelectorVisible')
          || (event.target.closest('.popup_window') || event.target.closest('.pp_background'))
          || dataActionArr.indexOf(event.target.getAttribute('data-action')) !== -1
          || path
      ) {
        return;
      }

      for (const selector in showSelector.value) {
        if (showSelector.value[selector]) {
          showSelector.value[selector] = false;
          return;
        }
      }
    };

    const fetchSelectorsData = async () => {
      await Promise.all([
        store.dispatch('setCompanyTypeData').then(() => {
          /* инициируется открытие компонента Tip.vue,
          который в свою очередь открывает компонент MainBaseConfigurator.vue */
          window.vue_tip_balloon.initTipData();
        }),
        store.dispatch('setLegalForm'),
        store.dispatch('setCompanySize'),
      ]).then(() => {
        selectorsDataFetched.value = true;
      });
    };

    const companyTypeNameToLowerCase = (value) => {
      const isAbbreviation = value[0] === value[0].toUpperCase()
          && value[1] === value[1].toUpperCase();
      if (isAbbreviation) {
        return value;
      }
      return value.charAt(0).toLowerCase() + value.slice(1);
    };

    watch(
      showSelector,
      () => {
        let isShownSelector = false;
        Object.keys(showSelector.value).forEach((selector) => {
          if (showSelector.value[selector]) {
            isShownSelector = true;
          }
        });

        if (isShownSelector) {
          document.addEventListener('click', outsideEventListenerForShownSelector);
        } else {
          document.removeEventListener('click', outsideEventListenerForShownSelector);
        }
      },
      { deep: true },
    );

    onMounted(async () => {
      await showEmailPhoneNotification();

      if (!notAllowedHash.includes(window.location.hash)) {
        await fetchSelectorsData();
      } else {
        window.addEventListener('hashchange', () => {
          if (!selectorsDataFetched.value && window.location.hash === '') {
            fetchSelectorsData();
          }
        });
      }
    });

    onBeforeUnmount(() => {
      document.removeEventListener('click', outsideEventListenerForShownSelector);
    });

    provide('selectedOptionsDescription', selectedOptionsDescription);

    return {
      showSelector,
      selectorChange,
      setShowSelector,
    };
  },
};
</script>

<style scoped>
.mainConfiguratorBody {
  display: flex;
  flex-direction: column;
  padding: 30px 25px 20px;
  box-shadow: rgb(153 153 153) 0 -1px 5px 0;
}

.mainBlock {
  display: flex;
  gap: 110px;
}

.dropdownSelectors {
  display: flex;
  flex-direction: column;
  justify-content: left;
}

.selectors-block {
  display: flex;
  flex-direction: column;
  gap: 70px;
}
</style>
