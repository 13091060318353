<template>
  <div class="dropdownSelector companyType">
    <div class="selectorBorderVertical" />

    <div class="selectorBorderVertical" />

    <div class="selectorHead companyTypeSelectorHead">
      <InputSearch
        :value="searchCompanyType"
        focus
        class="selectorInput"
        @updateValue="updateSearch"
      />
      <div class="inclusionOptions">
        <div class="inclusionOptionsItem">
          <div class="inclusionOptionTitle">
            Включено
          </div>
          <div class="inclusionOptionSubtitle">
            в заказ
          </div>
        </div>
        <div class="inclusionOptionsItem">
          <div class="inclusionOptionTitle">
            Исключено
          </div>
          <div class="inclusionOptionSubtitle">
            из заказа
          </div>
        </div>
      </div>
    </div>

    <section class="selectorOptionsSection">
      <div class="selectorHeadOptionTitle">
        Тип компании
      </div>

      <!-- Все компании  -->
      <DropdownOptionWithExclusion
        :selector-label="enableAllOption.title"
        :all-chosen="allEnabled"
        is-exclusion-not-needed
        class="enableAllOption"
        @inclusion="enableAll"
      />

      <!-- Группы типов компаний  -->
      <DropDownGroups
        v-if="groups"
        :groups="groups"
        :options="companyType"
        is-exclusion-needed
        @inclusionIfSingleSelector="toggleIncludeOption"
        @exclusionIfSingleSelector="toggleExcludeOption"
        @clickGroupIfSingleSelector="clickGroup"
      />
    </section>
  </div>
</template>

<script>
import DropDownGroups from '@/main_base/components/DropDownGroups/DropDownGroups.vue';
import InputSearch from '@/common_components/InputSearch/InputSearch.vue';
import DropdownOptionWithExclusion
  from '@/main_base/components/DropdownOptionWIthExclusion/DropdownOptionWithExclusion.vue';
import { mapState, mapMutations, mapActions } from 'vuex';

export default {
  name: 'CompanyTypeSelector',
  components: {
    InputSearch,
    DropDownGroups,
    DropdownOptionWithExclusion,
  },

  props: {
    searchCompanyType: {
      type: String,
      required: true,
    },

    enableAllOption: {
      type: Object,
      required: false,
      default: null,
    },

    groups: {
      type: Object,
      required: false,
      default: null,
    },
  },

  emits: ['updateSearch', 'enableAll'],

  computed: {
    ...mapState([
      'companyType',
    ]),

    allEnabled() {
      return Object.keys(this.companyType).every(
        (id) => !this.companyType[id].included && !this.companyType[id].excluded,
      );
    },
  },

  methods: {
    ...mapMutations([
      'setVisibleCompanyTypesByGroupId',
      'toggleActiveGroup',
    ]),

    ...mapActions([
      'toggleIncludeType',
      'toggleExcludeType',
    ]),

    enableAll() {
      this.$emit('enableAll');
    },

    clickGroup(groupId) {
      this.toggleActiveGroup(groupId);
      this.setVisibleCompanyTypesByGroupId(groupId);
    },

    toggleIncludeOption(id) {
      this.toggleIncludeType({
        selectorType: 'companyType',
        id,
      });
    },

    toggleExcludeOption(id) {
      this.toggleExcludeType({
        selectorType: 'companyType',
        id,
      });
    },

    updateSearch(value) {
      this.$emit('updateSearch', value);
    },
  },
};
</script>

<style scoped src="./../DropdownSelector/DropdownSelector.css" />

<style scoped>
.companyTypeSelectorHead {
  padding-block: 10px;
}

.selectorInput {
  margin-left: 5px;
}
</style>
