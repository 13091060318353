<template>
  <div class="row-kp">
    <p class="row-title">
      КП на выгрузку:
    </p>
    <p
      class="description-kp"
      @click="getKp"
    >
      Получить коммерческое
    </p>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'RowKp',
  methods: {
    ...mapActions([
      'getKp',
    ]),
  },
};
</script>

<style scoped>
.row-kp {
  font-weight: bold;
  display: grid;
  grid-template-columns: 130px 1fr;
  place-items: center start;
  margin-top: 10px;
}

.description-kp {
  border-bottom: 1px dashed;
  font-size: 14px;
  font-weight: 700;
  color: var(--color-button-prime);
  cursor: pointer;
  transition: .3s;
  margin-bottom: 10px;
  display: inline-block;
}
</style>
