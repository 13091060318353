import { NodeValue, Tree } from 'tree';
import Fuse from 'fuse.js';
import plural from 'plural-ru';
import api from '../../api/index';

export default {
    namespaced: true,
    state: {
    /**
     * @var {Tree}
     */
        tree: {},

        searchString: '',

        fuse: null,

        fuseOptions: {
            keys: ['title'],
            threshold: 0.4,
        },

        /**
     * @var {Tree}
     */
        searchTree: {},

        tooltipVisible: false,

        visibleTree: false,

        geoUnits: [],

        searchDistrictString: '',

        citiesWithDistricts: {},

        districtsGroup: {},

        chosenSection: 'city',

        loadingTree: false,
    },

    getters: {
        tooltipVisible(state) {
            return state.tooltipVisible;
        },

        getGeoTree: (state) => state.tree,
        getGeoSearchTree: (state) => state.searchTree,
        isSearchMode: (state) => (state.searchString.length >= 3),
        emptySearchResult: (state) => state.searchTree.isEmpty(),

        getSelectedCities(state) {
            if (!state.tree.root) return [];

            const selectedGeoUnits = state.tree.getChosenNodesFromAllLevels()
                .flat()
                .filter((node) => !node.value.obj.second);

            return selectedGeoUnits
                .filter((geoUnit) => geoUnit.value.obj.level === 3)
                .map((city) => city.value.obj.id);
        },

        getSelectedGeoUnits(state) {
            if (!state.tree.root) return [];
            // Убираем повторы, нельзя использовать метод из дерева,
            // так как мешает уровень с центральными округами. Поэтому делается фильтрация
            const selectedGeoUnits = state.tree.getChosenNodesFromAllLevels()
                .flat()
                .filter((node) => !node.value.obj.second);

            const countries = selectedGeoUnits
                .filter((geoUnit) => geoUnit.value.obj.level === 1)
                .map((country) => country.value.obj.id);

            const regions = selectedGeoUnits
                .filter((geoUnit) => geoUnit.value.obj.level === 2
          && !countries.includes(geoUnit.value.obj.id_country))
                .map((region) => region.value.obj.id);

            const cities = selectedGeoUnits
                .filter((geoUnit) => geoUnit.value.obj.level === 3
          && !regions.includes(geoUnit.value.obj.region)
          && !countries.includes(geoUnit.value.obj.id_country))
                .map((city) => city.value.obj.id);

            return [...countries, ...regions, ...cities];
        },

        getSelectedGeoUnitsForLegacyKp(state) {
            if (!state.tree.root) return [];

            const selectedGeoUnits = state.tree.getChosenNodesFromAllLevels()
                .flat()
                .filter((node) => !node.value.obj.second);

            const countries = selectedGeoUnits
                .filter((geoUnit) => geoUnit.value.obj.level === 1)
                .map((country) => country.value.obj.id);

            const regions = selectedGeoUnits
                .filter((geoUnit) => geoUnit.value.obj.level === 2
              && !countries.includes(geoUnit.value.obj.id_country))
                .map((region) => region.value.obj.id);

            const cities = selectedGeoUnits
                .filter((geoUnit) => geoUnit.value.obj.level === 3
              && !regions.includes(geoUnit.value.obj.region)
              && !countries.includes(geoUnit.value.obj.id_country))
                .map((city) => city.value.obj.id);

            return {
                country: countries,
                region: regions,
                city: cities,
            };
        },

        getImportantCities(state) {
            return state.geoUnits
                .filter((geoUnit) => geoUnit.important && geoUnit.id_country !== 2)
                .sort((a, b) => {
                    if (a.id === 585 || a.id === 493) {
                        return -1;
                    }
                    if (b.id === 585 || b.id === 493) {
                        return 1;
                    }
                    if (a.name > b.name) return 1;
                    if (a.name < b.name) return -1;
                });
        },

        getCityIdByName: (state) => (name) => state.geoUnits.find((city) => city.name === name).id,

        getCityNameById: (state) => (id) => state.geoUnits.find((city) => city.id === id).name,

        getMillionaireCities(state) {
            return state.geoUnits.filter((city) => city.level === 3 && city.is_millionaire);
        },

        getRegionalCenters(state) {
            return state.geoUnits.filter(
                (city) => city.id_country === 1 && city.level === 3 && city.important === 1,
            );
        },

        hasSelectedCities(state) {
            // когда дерево не прогрузилось возвращаем true, так как по умолчанию выбрана вся Россия
            if (!state.tree.root) {
                return true;
            }
            return state.tree.root.value.isPartiallyChosen || state.tree.root.value.isChosen;
        },

        getSelectedCitiesWithDistrictsId(state, getters) {
            const citiesWithDistricts = Object.keys(state.citiesWithDistricts).map(
                (item) => Number(item),
            );
            return getters.getSelectedCities.filter((value) => citiesWithDistricts.includes(value));
        },

        getSelectedCitiesWithDistrictsList(state, getters) {
            const res = {};
            getters.getSelectedCitiesWithDistrictsId.forEach((cityId) => {
                res[cityId] = state.citiesWithDistricts[cityId];
                res[cityId].name = getters.getCityNameById(cityId);
            });
            return res;
        },

        getSearchDistrict(state) {
            return state.searchDistrictString;
        },
        // Данный метод работает, пока выбрать районы можно только для одного города
        getSelectedCityWithDistricts(state, getters) {
            return getters.getSelectedCitiesWithDistrictsList[
                Object.keys(getters.getSelectedCitiesWithDistrictsList)[0]
            ];
        },

        getGroupsForSelectedCity(state, getters) {
            if (getters.getSelectedCityWithDistricts) {
                return state.districtsGroup[getters.getSelectedCityWithDistricts.id].districtsGroup;
            }
            return null;
        },

        getGroupsIdsForSelectedCity(state, getters) {
            if (getters.getGroupsForSelectedCity) {
                return Object.keys(getters.getGroupsForSelectedCity);
            }
            return null;
        },

        getSelectedDistricts(state, getters) {
            if (
                getters.getSelectedCities.length === 1
                && getters.getSelectedCitiesWithDistrictsId.length === 1
            ) {
                const city = getters.getSelectedCityWithDistricts;
                const result = {};
                const selectedDistricts = [];
                for (const district in city.districts) {
                    if (city.districts[district].included) {
                        selectedDistricts.push(city.districts[district].id);
                    }
                }
                if (selectedDistricts.length === 0) {
                    return [];
                }
                result[city.id] = selectedDistricts;
                return result;
            }
            return [];
        },

        getDescription(state, getters) {
            const countCities = getters.getSelectedCities.length;
            const cityId = getters.getSelectedCities[0];

            const countDistricts = getters.getSelectedDistricts[cityId]
                ? getters.getSelectedDistricts[cityId].length : 0;

            if (countCities === 0 && !state.visibleTree) return 'Города не выбраны';

            if (countDistricts > 0) {
                return `${getters.getSelectedCityWithDistricts.name}`
                    + `, ${countDistricts} ${plural(countDistricts, 'район', 'района', 'районов')}`;
            } else {
                return `${countCities} ${plural(countCities, 'город', 'города', 'городов')}`;
            }
        },
    },

    mutations: {
        expandGeoNode: (state, nodeDesc) => {
            state.tree.expandNode(nodeDesc.level, nodeDesc.id);
        },
        chooseGeoNode: (state, nodeDesc) => {
            state.tree.chooseNode(nodeDesc.level, nodeDesc.id);
        },

        setSearchString(state, searchString) {
            state.searchString = searchString;
        },
        runSearch(state) {
            state.searchTree = state.tree.filterTreeByNodeName(state.searchString);
        },
        resetSearch(state) {
            state.searchString = '';
        },
        expandGeoSearchNode: (state, nodeDesc) => {
            state.searchTree.expandNode(nodeDesc.level, nodeDesc.id);
        },
        syncSearchTree: (state) => {
            state.searchTree.copyStateOfChoice(state.searchTree.root, state.tree);
        },

        chooseOrCancelTree: (state, choice) => {
            if (Object.keys(state.tree).length !== 0) {
                state.tree.selectOrDropAllNodes(choice);
            }
        },

        chooseOrCancelSearchTree(state, choice) {
            if (Object.keys(state.searchTree).length !== 0) {
                state.searchTree.selectOrDropAllNodes(choice);
            }
        },

        setTooltipVisible(state, value) {
            state.tooltipVisible = value;
        },

        setTree(state, { geoUnits, territories }) {
            const tree = new Tree(4);

            // страны
            let filteredCountries = geoUnits.filter((country) => country.level === 1
          && (country.id === 1 || country.id === 3 || country.id === 4));

            const images = {
                1: '/images/country1.png',
                3: '/images/country3.png',
                4: '/images/country4.png',
            };

            filteredCountries = filteredCountries.map((country) => {
                country.srcImg = images[country.id];
                return country;
            });
            filteredCountries.forEach((country) => {
                const nodeValue = new NodeValue(
                    country,
                    1,
                    // Разворачиваем только регионы России
                    country.id === 1,
                    false,
                    false,
                    false,
                );
                tree.addNodeByParentId(nodeValue, 0);
            });

            // федеральные округа (только для России)
            territories = territories
                .map((territory) => {
                    territory.id = Number(territory.id) + 10000;
                    territory.second = 1;
                    return territory;
                })
                .filter((territory) => territory.id !== 10009);

            territories.forEach((territory) => {
                const nodeValue = new NodeValue(
                    territory,
                    2,
                    // Разворачиваем только Центральный федеральный округ
                    territory.id === 10001,
                    false,
                    false,
                    false,
                );
                tree.addNodeByParentId(nodeValue, 1);
            });

            // регионы России
            let filteredRegions = geoUnits.filter(
                (region) => region.level === 2 && region.id_country === 1,
            );
            filteredRegions = filteredRegions.sort((a, b) => {
                if (a.id === 35 || a.id === 32) {
                    return -1;
                }
                if (b.id === 35 || b.id === 32) {
                    return 1;
                }
                return 0;
            });

            filteredRegions.forEach((region) => {
                let nodeValue = new NodeValue(region, 3);
                if (region.id === 35) {
                    nodeValue = new NodeValue(region, 3, true);
                }
                tree.addNodeByParentId(nodeValue, region.id_territory + 10000);
            });

            // города России
            let sortCities = geoUnits.sort((a, b) => (a.important < b.important ? 1 : -1));
            sortCities = sortCities.sort((a, b) => {
                // Остальные населенные пункты опускаются
                if (a.is_other_locality) {
                    return 1;
                }
                if (b.is_other_locality) {
                    return -1;
                }
                // Москва Питер и Севастополь поднимается
                if (a.id === 585 || a.id === 493 || a.id === 461) {
                    return -1;
                }
                if (b.id === 585 || b.id === 493 || b.id === 461) {
                    return 1;
                }
                if (a.important === 1 || b.important === 1) {
                    return 0;
                }
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            });

            sortCities = sortCities.map((city) => {
                if (city.important) {
                    city.extra = 1;
                }
                if (city.id === 585 || city.id === 461 || city.id === 493) {
                    city.important = 0;
                    city.extra = 0;
                    city.additional = 1;
                }
                return city;
            });
            sortCities = sortCities.filter((city) => city.level === 3 && city.id_country === 1);
            sortCities.forEach((city) => {
                const nodeValue = new NodeValue(city, 4);
                tree.addNodeByParentId(nodeValue, city.region);
            });

            // регионы других стран (не Россия)
            const otherRegions = geoUnits.filter(
                (region) => region.level === 2 && region.id_country !== 1,
            );
            otherRegions.forEach((region) => {
                region.extra = 0;
                region.important = 0;
                region.second = 0;

                return region;
            });

            otherRegions.forEach((region) => {
                const nodeValue = new NodeValue(region, 2);
                tree.addNodeByParentId(nodeValue, region.id_country);
            });

            // города других стран (не Россия)
            const otherCities = geoUnits.filter((city) => city.id_country !== 1);
            otherCities.forEach((city) => {
                const nodeValue = new NodeValue(city, 3);
                tree.addNodeByParentId(nodeValue, city.region);
            });

            state.tree = tree;
        },

        setVisibleTree(state, value) {
            state.visibleTree = value;
        },

        setGeoUnits(state, value) {
            state.geoUnits = JSON.parse(JSON.stringify(value));
        },

        setDistricts(state, value) {
            state.citiesWithDistricts = JSON.parse(JSON.stringify(value));
        },

        setDistrictsGroup(state, value) {
            state.districtsGroup = value;
        },

        setSearchDistrictValue(state, value) {
            state.searchDistrictString = value;
        },

        toggleCityDistrict(state, { selectorType: cityId, id }) {
            state.citiesWithDistricts[cityId].districts[id].included = !state.citiesWithDistricts[cityId].districts[id].included;

            const totalCountDistrictsInCity = Object.keys(state.citiesWithDistricts[cityId].districts).length;
            const citySelectedDistrictsArr = Object.values(state.citiesWithDistricts[cityId].districts);

            const includedDistrictsCount = citySelectedDistrictsArr.filter(district => district.included).length;

            if (includedDistrictsCount == totalCountDistrictsInCity) {
                for (const districtId in state.citiesWithDistricts[cityId].districts) {
                    state.citiesWithDistricts[cityId].districts[districtId].included = false;
                }
            }
        },

        highlightGroup(state, { selectorType: cityId, id }) {
            if (state.citiesWithDistricts[cityId].hasGroups) {
                const districtGroupId = state.citiesWithDistricts[cityId].districts[id].groupId;

                const cityDistricts = [];
                for (const [key, value] of Object.entries(
                    state.citiesWithDistricts[cityId].districts)
                ) {
                    cityDistricts.push({ ...value });
                }

                const groupDistricts = cityDistricts
                    .filter((district) => district.groupId === districtGroupId);

                state.districtsGroup[cityId].districtsGroup[districtGroupId].highlight =
                    groupDistricts.some(
                        (district) => district.included,
                    );
            }
        },

        setFuse(state, districts) {

            state.fuse = new Fuse(districts, state.fuseOptions);
        },

        setShowDistrictGroup(state, { groupId, show, selectedCityWithDistrictId }) {
            const districtsGroupId =
                Object.values(state.districtsGroup[selectedCityWithDistrictId].districtsGroup)
                    .find((group) => group.id == groupId).id;

            state.districtsGroup[selectedCityWithDistrictId].districtsGroup[districtsGroupId].show = show;
        },

        activateAllGroups(state, selectedCityWithDistrictId) {
            const cityDitrictGroups =
                state.districtsGroup[selectedCityWithDistrictId].districtsGroup;

            for (const [id, group] of Object.entries(cityDitrictGroups)) {
                group.active = true;
            }
        },

        resetHiddenTypes(state, selectedCityWithDistrictId) {
            Object.entries(state.citiesWithDistricts[selectedCityWithDistrictId].districts)
                .forEach(([, district]) => {
                    district.hidden = false;
                });
        },

        hideTypes(state, selectedCityWithDistrictId) {
            Object.entries(state.citiesWithDistricts[selectedCityWithDistrictId].districts)
                .forEach(([, district]) => {
                    district.visible = false;
                });
        },

        hideGroups(state, selectedCityWithDistrictId) {
            const cityDitrictGroups = state.districtsGroup[selectedCityWithDistrictId].districtsGroup;

            for (const [id, group] of Object.entries(cityDitrictGroups)) {
                group.active = false;
            }
        },

        setVisibleCompanyTypesByGroupId(state, { selectorType: cityId, groupId }) {
            if (state.searchDistrictString === '') {
                for (const id in state.citiesWithDistricts[cityId].districts) {
                    if (state.citiesWithDistricts[cityId].districts[id].groupId === groupId) {
                        state.citiesWithDistricts[cityId].districts[id].visible = !state.citiesWithDistricts[cityId].districts[id].visible;
                    }
                }
            } else {
                Object.entries(state.citiesWithDistricts[cityId].districts)
                    .filter(([key]) => state.citiesWithDistricts[cityId].districts[key].groupId === groupId)
                    .forEach(([key]) => {
                        state.citiesWithDistricts[cityId].districts[key].hidden = !state.citiesWithDistricts[cityId].districts[key].hidden;
                    });
            }
        },

        setChosenSection(state, value) {
            state.chosenSection = value;
        },

        setLoadingTree(state, value) {
            state.loadingTree = value;
        },

        toggleActiveDistrictGroup(state, { selectorType: cityId, groupId }) {
            let cityDitrictGroups = state.districtsGroup[cityId].districtsGroup;

            for (const [id, group] of Object.entries(cityDitrictGroups)) {
                group.active = group.id === groupId ? !group.active : group.active;
            }
        },

        resetDistricts(state, cityId) {
            if (state.citiesWithDistricts[cityId] !== undefined) {
                for (let districtId in state.citiesWithDistricts[cityId].districts) {
                    state.citiesWithDistricts[cityId].districts[districtId].included = false;
                }

                if (state.citiesWithDistricts[cityId].hasGroups) {
                    for (const groupId in state.districtsGroup[cityId].districtsGroup) {
                        state.districtsGroup[cityId].districtsGroup[groupId].highlight = false;
                    }
                }
            }
        },
    },

    actions: {
        async setGeoTree({ commit }) {
            commit('setLoadingTree', true);

            const geoUnits = await api.getCities();
            const territories = await api.getTerritories();
            commit('setGeoUnits', geoUnits);
            commit('setTree', { geoUnits, territories });

            commit('setLoadingTree', false);
        },

        async setDistricts({ commit }) {
            const { citiesWithDistricts, citiesWithDistrictsGroup } = await api.getDistricts();

            const res = {};
            for (const [key, value] of Object.entries(citiesWithDistricts)) {
                res[key] = {
                    id: key,
                    isShow: true,
                    hasGroups: value[Object.keys(value)[0]].groupId !== null,
                    districts: { ...value },
                };
            }

            const resGroup = {};
            for (const [key, value] of Object.entries(citiesWithDistrictsGroup)) {
                resGroup[key] = {
                    id: key,
                    districtsGroup: value,
                };
            }

            commit('setDistricts', res);
            commit('setDistrictsGroup', resGroup);
        },

        toggleCityDistrict({ commit }, params) {
            commit('toggleCityDistrict', params);
            commit('highlightGroup', params);
        },

        updateSearchDistrictValue({ commit }, value) {
            commit('setSearchDistrictValue', value);
        },

        enableAllCityDistricts({ commit }, cityId) {
            commit('resetDistricts', cityId);
        },

        chooseGeoSearchNode: ({ commit }, nodeDesc) => {
            commit('chooseGeoNode', nodeDesc);
            commit('syncSearchTree');
        },
        updateSearch: ({ commit, getters }, searchString) => {
            commit('setSearchString', searchString);
            if (getters.isSearchMode) {
                commit('runSearch');
            }
        },

        chooseGeoNode({ commit, getters, state }, nodeDesc) {
            commit('chooseGeoNode', nodeDesc);
            commit('runSearch');
            if (Object.keys(getters.getSelectedCitiesWithDistrictsList).length === 1) {
                commit('setFuse', Object.values(state.citiesWithDistricts[getters.getSelectedCityWithDistricts.id].districts));
            }
        },

        visibleDistrictsByIds({ state, commit, getters }, ids) {
            const selectedCityWithDistrictId = getters.getSelectedCityWithDistricts.id;

            commit('hideTypes', selectedCityWithDistrictId);

            Object.entries(state.citiesWithDistricts[selectedCityWithDistrictId].districts).forEach(([key]) => {
                if (ids.includes(state.citiesWithDistricts[selectedCityWithDistrictId].districts[key].id)) {
                    state.citiesWithDistricts[selectedCityWithDistrictId].districts[key].visible = true;
                }
            });
        },

        hideAllDistrictsGroups({ commit, getters }) {
            const groupIds = getters.getGroupsIdsForSelectedCity;
            const selectedCityWithDistrictId = getters.getSelectedCityWithDistricts.id;
            groupIds.forEach((groupId) => commit('setShowDistrictGroup', { groupId, show: false, selectedCityWithDistrictId }));
        },

        showDistrictsGroupsByIds({ commit, dispatch, getters }, groupIds) {
            dispatch('hideAllDistrictsGroups');
            const selectedCityWithDistrictId = getters.getSelectedCityWithDistricts.id;
            groupIds.forEach((groupId) => commit('setShowDistrictGroup', { groupId, show: true, selectedCityWithDistrictId }));
        },

        showAllDistrictsGroups({ commit, getters }) {
            const groupIds = getters.getGroupsIdsForSelectedCity;
            const selectedCityWithDistrictId = getters.getSelectedCityWithDistricts.id;
            groupIds.forEach((groupId) => commit('setShowDistrictGroup', { groupId, show: true, selectedCityWithDistrictId }));
        },

        resetSearch({ commit, dispatch, getters }) {
            const selectedCityWithDistrictId = getters.getSelectedCityWithDistricts.id;

            dispatch('showAllDistrictsGroups');
            commit('hideGroups', selectedCityWithDistrictId);
            commit('hideTypes', selectedCityWithDistrictId);
            commit('resetHiddenTypes', selectedCityWithDistrictId);
        },

        search({
            state, commit, dispatch, getters,
        }) {
            const selectedCityWithDistrictId = getters.getSelectedCityWithDistricts.id;

            const searchedDistricts = state.fuse.search(state.searchDistrictString)
                .map((itemData) => itemData.item);

            const groupIds = [
                ...new Set(searchedDistricts.map((district) => district.groupId)),
            ];

            const districtsIds = searchedDistricts.map((district) => district.id);

            dispatch('showDistrictsGroupsByIds', groupIds);
            commit('activateAllGroups', selectedCityWithDistrictId);
            commit('resetHiddenTypes', selectedCityWithDistrictId);
            dispatch('visibleDistrictsByIds', districtsIds);
        },

        async setMillionaireCities({ getters, commit }) {
            if (
                !confirm('Вы уверены, что хотите включить города-миллионники?'
                    + ' Все остальные города будут исключены из выборки.')) return;

            await commit('chooseOrCancelTree', false);

            getters.getMillionaireCities.forEach((city) => {
                commit('chooseGeoNode', { level: 4, id: city.id });
            });
        },

        async setRegionalCenters({ getters, commit }) {
            if (!confirm('Вы уверены, что хотите включить региональные центры?'
              + ' Все остальные города будут исключены из выборки.')) {
                return;
            }

            await commit('chooseOrCancelTree', false);

            getters.getRegionalCenters.forEach((city) => {
                commit('chooseGeoNode', { level: 4, id: city.id });
            });
        },

        async resetDistricts({ getters, commit }) {
            const cityId = getters.getSelectedCities[0];

            await commit('resetDistricts', cityId);
        },
    },
};
