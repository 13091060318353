const state = {
    fuse: null,
    fuseOptions: {
        keys: ['title'],
        threshold: 0.4,
    },
    searchCompanyType: '',
    companyType: {},
    companyTypeGroups: {},

    legalForm: {},
    unknownLegalFormIncluded: false,

    companySize: {},
    unknownCompanySizeIncluded: false,

    mainOkved: 0,
    showRegistrationDate: 0,
    highlightNewCompanies: 0,
    showIncAndProfit: 0,
    showKppOkpo: 0,
    showLegalName: 0,
    showCeoSex: 0,
    showSiteDescription: 0,
    withoutSite: 0,
    onlyMobilePhones: 0,
    hasSitePhone: 0,
    hasMessenger: 0,
    onlyUniquePhones: 0,
    hasLicense: 0,
    onlyWithoutSiteDisabled: false,
};

export default state;
