<template>
  <div class="description-wrapper">
    <div class="description-info">
      <div
        v-if="!hasSelectedCities"
        class="description-not-selected-cities"
      >
        Города не выбраны,
        <span
          class="description-open-link"
          @click="openGeoTree"
        >
          откройте список
        </span>
        и выберите
      </div>
      <div
        v-else-if="descriptionCitiesCount === 0"
        class="description-not-selected-cities"
      >
        Отрасли не выбраны.
        <span
          class="description-open-link"
          @click="openRubricsTree"
        >
          Выберите нужные рубрики.
        </span>
      </div>
      <div v-else>
        <div
          class="description-kp"
          @click="getKp"
        >
          Получить Коммерческое предложение
        </div>
        <div
          v-for="(item, index) in geoData.slice(0, visibleCitiesCount)"
          :key="item.geoId"
          class="description-one-city"
        >
          {{ index + 1 }}) {{ item.name }} — {{ item.count }} компаний
        </div>
        <div
          v-if="descriptionCitiesCount > visibleCitiesCount"
          class="city_wrapper"
        >
          <p>
            <b>...И еще {{ getRemainingNumberOfCitiesText(descriptionCitiesCount - visibleCitiesCount) }}
            </b>
          </p>
        </div>
      </div>
    </div>
    <div class="description__advantages">
      <span class="advantages-item"><b>Бесплатное обновление в течение 6 месяцев</b></span>
      <span class="advantages-item">База обновляется каждый день</span>
      <span class="advantages-item">Удобная оплата от организации </span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import numeralize from 'numeralize-ru';

export default {
  name: 'MainBaseOrderDescription',

  data() {
    return {
      visibleCitiesCount: 10,
    };
  },

  computed: {
    ...mapState([
      'geoData',
    ]),

    ...mapGetters('geoTree', [
      'hasSelectedCities',
    ]),

    descriptionCitiesCount() {
      return this.geoData.length;
    },
  },

  methods: {
    ...mapMutations('geoTree', {
      openGeo: 'setVisibleTree',
    }),

    ...mapMutations('rubricsTree', {
      openRubrics: 'setVisibleTree',
    }),

    ...mapActions([
      'getKp',
    ]),

    openGeoTree() {
      this.openGeo(true);
    },

    openRubricsTree() {
      this.openRubrics(true);
    },

    getRemainingNumberOfCitiesText(remainingCitiesCount) {
      return `${remainingCitiesCount} ${numeralize.pluralize(remainingCitiesCount, 'город', 'города', 'городов')}`;
    },
  },
};

</script>

<style scoped>
.description-wrapper {
  position: relative;
  padding: var(--cart-padding);
  border-radius: var(--cart-border-radius);
  background-color: var(--cart-background-color);
  box-shadow: var(--cart-box-shadow);
  box-sizing: border-box;
  width: 430px;
}

.description__advantages {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 1.2em;
  font-size: 14px;
}

.description__advantages .advantages-item {
  position: relative;
  padding-left: 2em;
  margin-bottom: 0.5em;
  line-height: 1.2;
}

.description__advantages .advantages-item::before {
  content: '';
  position: absolute;
  left: 0;
  top: -.2em;
  height: .8em;
  width: .4em;
  display: inline-block;
  transform: rotate(45deg);
  border-bottom: .3em solid var(--color-button-prime-hover);
  border-right: .3em solid var(--color-button-prime-hover);
}

.description-not-selected-cities {
  font-size: 16px;
  font-weight: 700;
}

.description-open-link {
  border-bottom: 1px dashed;
  cursor: pointer;
}

.description-one-city {
  position: relative;
  padding-bottom: 5px;
  line-height: 1.5;
  font-weight: 700;
}

.description-kp {
  border-bottom: 1px dashed;
  font-style: italic;
  font-size: 14px;
  font-weight: 700;
  color: var(--color-button-prime);
  cursor: pointer;
  transition: .3s;
  margin-bottom: 10px;
  display: inline-block;
}

</style>
