<template>
  <div class="multiselector">
    <p
      v-if="title"
      class="title"
    >
      {{ title }}
    </p>
    <div
      class="selectMenu"
      @click.stop="toggle"
    >
      <div class="text-wrapper">
        <div class="text">
          {{ selectedText }}
        </div>
      </div>
      <div class="button">
        <svg
          width="18"
          height="10"
          viewBox="0 0 18 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="icon"
        >
          <path
            d="M8.8544 9.99999C8.70026 10.0007 8.54754 9.97065 8.40518 9.91156C8.26283 9.85247 8.13371 9.76554 8.0254 9.65587L0.204629 1.83511C0.0486439 1.6069 -0.0217464 1.33094 0.00587241 1.0559C0.0334913 0.780865 0.157346 0.524413 0.355591 0.331778C0.553836 0.139144 0.813737 0.0227016 1.08946 0.00298839C1.36517 -0.0167248 1.639 0.0615578 1.86263 0.224029L8.8544 7.16887L15.8462 0.224029C16.0666 0.0810798 16.3287 0.0162909 16.5904 0.0400465C16.8521 0.0638021 17.0982 0.174727 17.2894 0.355041C17.4805 0.535356 17.6056 0.774618 17.6445 1.03448C17.6835 1.29434 17.634 1.55976 17.5042 1.78818L9.6834 9.60895C9.57912 9.72714 9.45181 9.82278 9.30925 9.89002C9.1667 9.95726 9.01192 9.99468 8.8544 9.99999Z"
            fill="white"
          />
        </svg>
      </div>
    </div>
    <ul
      class="list"
      :class="[
        { list_open: open },
        { opened_multiselector: open },
        { list_scrollable: open && withScroll }
      ]"
    >
      <li
        class="item item_all"
        @click="allItemSelect"
      >
        {{ allSelectedText }}
        <img
          v-if="nothingSelected"
          src="./tick.png"
          alt="./tick.png"
          width="12"
          height="12"
        >
      </li>
      <li
        v-for="item in items"
        :key="item.key"
        class="item"
        @click="itemChange(item.key)"
      >
        {{ item.name }}
        <img
          v-show="item.selected"
          src="./tick.png"
          alt="./tick.png"
          width="12"
          height="12"
        >
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'MultiSelector',
  props: {
    title: {
      type: String,
      required: false,
    },

    allSelectedText: {
      type: String,
      required: true,
    },

    items: {
      type: Array,
      required: true,
      validator(items) {
        return items.every((item) => ('key' in item && 'name' in item));
      },
    },

    forceOpen: {
      type: Boolean,
      default: false,
    },

    withScroll: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['itemChange', 'allItemSelect'],

  data() {
    return {
      open: false,
    };
  },

  computed: {
    selectedText() {
      return this.nothingSelected ? this.allSelectedText : this.selectedItemsText;
    },

    nothingSelected() {
      return this.items.every((item) => !item.selected);
    },

    selectedItemsText() {
      return this.items
        .filter((item) => item.selected)
        .map((item) => item.name)
        .join(', ');
    },
  },

  watch: {
    open() {
      if (this.open) {
        document.addEventListener('click', this.outsideEventListenerForShownMultiSelector);
      } else {
        document.removeEventListener('click', this.outsideEventListenerForShownMultiSelector);
      }
    },

    forceOpen() {
      if (this.forceOpen) {
        this.open = this.forceOpen;
      }
    },
  },

  methods: {
    itemChange(key) {
      this.$emit('itemChange', key);
    },

    allItemSelect() {
      if (this.nothingSelected) {
        return;
      }
      this.$emit('allItemSelect');
    },

    toggle() {
      this.open = !this.open;
    },

    outsideEventListenerForShownMultiSelector(event) {
      if (event.target.closest('.opened_multiselector')) {
        return;
      }
      this.open = false;
    },
  },
};
</script>

<style scoped>
.title {
  font-weight: bold;
  margin-bottom: 8px;
}

.selectMenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  color: #666;
  cursor: pointer;
  border-radius: 5px;
  max-width: 205px;
  transition: box-shadow 0.3s, border-color 0.3s, background-color 0.3s;
}

.selectMenu:hover {
  box-shadow: 0 0 3px #333;
  background: #f9f9f9;
}

.text-wrapper {
  display: flex;
  background: white;
  align-items: center;
  flex-grow: 1;
  padding: 0 9px;
  box-sizing: border-box;
  white-space: nowrap;
  width: 165px;
  height: 32px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border: 1px solid #DDD;
}

.text {
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 32px;
  background: #839F22;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  transition: background-color 0.3s;
}

.selectMenu:hover .button {
  background: #91ad2c;
}

.icon {
  display: block;
}

.list {
  position: absolute;
  max-height: 0;
  transition: max-height 1s;
  overflow: hidden;
  width: 205px;
  z-index: 70;
  box-shadow: 1px 40px 125px #666;
  margin-top: 2px;
}

.list_open {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  border-top: none;
  background-color: #FFF;
  max-height: 1000px;
}

.list_scrollable {
  max-height: 250px;
  overflow-y: auto;
}

.item {
  margin-right: 0;
  padding: 8px 9px;
  background-color: white;
  color: #666;
  cursor: pointer;
  font-weight: normal;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.item img {
  margin-right: 15%;
}

.item::first-letter {
  text-transform: uppercase;
}

.item:hover {
  background-color: #f9f9f9;
}

.item_all {
  border-bottom: 1px dashed #666;
}
</style>
