<template>
  <div>
    <template v-if="appliedToPhone && appliedToEmail">
      Вы получите базу данных с уникальными значениями <br> <b>телефонов и E-mail,</b>
      т. е. ни один электронный адрес и телефон не встретится дважды.
      <br>
    </template>

    <template v-else-if="appliedToPhone">
      Вы получите базу данных с уникальными значениями <br> <b>телефонов,</b>
      т. е. ни один телефон не встретится дважды.
      <br>
    </template>

    <template v-else-if="appliedToEmail">
      Вы получите базу данных с уникальными значениями <br> <b>E-mail,</b>
      т. е. ни один электронный адрес не встретится дважды.
      <br>
    </template>
  </div>
</template>

<script>
export default {
  name: 'UniquenessPhoneOrEmailBalloon',

  props: {
    appliedToPhone: {
      type: Boolean,
      default: false,
    },

    appliedToEmail: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
