<template>
  <TwoBlocksPopup @close-popup="hideGeoTree">
    <template #popup-left-block>
      <div class="change-cities-nav">
        <div
          class="change-cities-item"
          :class="{'change-cities-item_active': chosenSection === 'city'}"
          @click="setSection('city')"
        >
          <span class="change-cities-item__title">
            По городам
          </span>
        </div>
        <div
          class="change-cities-item"
          :class="{'change-cities-item_active': chosenSection === 'district'}"
          @click="setSection('district')"
        >
          <span class="change-cities-item__title">
            По районам города
          </span>
        </div>
      </div>

      <SectionCity
        v-if="chosenSection === 'city'"
      />
      <SectionDistrict v-if="chosenSection === 'district'" />
    </template>
    <template #popup-right-block>
      <p class="city-tree-description__title">
        Как провести конфигурацию городов?
      </p>
      <p class="city-tree-description__text">
        1. Выберите один или несколько городов для начала конфигурации.
      </p>
      <p class="city-tree-description__text">
        2. Вы можете воспользоваться поиском, чтобы найти нужный город.
      </p>
      <template v-if="chosenSection === 'city'">
        <div class="cities-filter">
          <p class="cities-filter__title">
            Выбор по критериям:
          </p>
          <div class="cities-filter__body">
            <div>
              <button
                type="button"
                class="eb-button-secondary small-button"
                @click="setMillionaireCities"
              >
                Города-миллионники
              </button>
            </div>
            <p class="cities-description">
              Выбрать только города с населением более 1 млн.
              человек по состоянию на {{ getCurrentYear }} год
            </p>
            <div>
              <button
                type="button"
                class="eb-button-secondary small-button"
                @click="setRegionalCenters"
              >
                Региональные центры
              </button>
            </div>
          </div>
        </div>
        <div class="countries-description">
          У нас также есть базы компаний
          <span
            class="eb-link-green other-countries"
            @click="scrollToBottom"
          >
            Беларуси
          </span>
          и
          <span
            class="eb-link-green other-countries"
            @click="scrollToBottom"
          >
            Казахстана
          </span>
        </div>
        <p class="cities-count-wrapper">
          Выбрано: <span class="cities-count">{{ getDescription }}</span>
        </p>
        <div class="search-block__wrapper-btns">
          <div
            class="eb-button-prime small-button"
            :class="{'disabled': !hasSelectedCities}"
            @click="chooseCities"
          >
            Выбрать
          </div>
          <div class="eb-button-secondary small-button" @click="resetChanges">
            Сброс
          </div>
        </div>
      </template>
      <template v-else>
        <p class="search-block__title">
          Фильтр по районам города:
        </p>

        <p class="cities-count-wrapper">
          <span class="cities-count">{{ getDistrictsCountText }}</span>
        </p>

        <div class="search-block__wrapper-btns">
          <div class="eb-button-prime small-button" @click="chooseCities">
            Выбрать
          </div>
          <div class="eb-button-secondary small-button" @click="resetDistricts">
            Сброс
          </div>
        </div>
      </template>
    </template>
  </TwoBlocksPopup>
</template>

<script>
import {
  mapGetters, mapMutations, mapActions, mapState,
} from 'vuex';
import SectionCity from '@/main_base/components/GeoTree/SectionCity.vue';
import SectionDistrict from '@/main_base/components/GeoTree/SectionDistrict.vue';
import TwoBlocksPopup from '@/common_components/TwoBlocksPopup/TwoBlocksPopup.vue';
import plural from 'plural-ru';

export default {
  name: 'GeoTree',
  components: {
    TwoBlocksPopup,
    SectionDistrict,
    SectionCity,
  },

  computed: {
    ...mapState('geoTree', [
      'chosenSection',
    ]),

    ...mapGetters('geoTree', [
      'tooltipVisible',
      'getSelectedGeoUnits',
      'getSelectedCities',
      'hasSelectedCities',
      'getSelectedDistricts',
      'getDescription',
      'getSelectedCitiesWithDistrictsList',
    ]),

    getCurrentYear() {
      return new Date().getFullYear();
    },

    getDistrictsCountText() {
      const cityId = this.getSelectedCities[0];
      const countDistricts = this.getSelectedDistricts[cityId] ? this.getSelectedDistricts[cityId].length : 0;

      if (
        this.getSelectedCities.length == 1
        && !countDistricts
        && Object.keys(this.getSelectedCitiesWithDistrictsList).length > 0
      ) {
        return 'Выбраны все районы';
      } if (countDistricts > 0) {
        return `${plural(countDistricts, 'Выбран', 'Выбрано', 'Выбрано')} `
          + `${countDistricts} ${plural(countDistricts, 'район', 'района', 'районов')}`;
      }
      return 'Районы не выбраны';
    },
  },

  watch: {
    hasSelectedCities(newVal) {
      if (newVal) {
        this.setTooltipVisible(null);
      }
    },

    getSelectedGeoUnits: {
      handler() {
        window.send_data_to_plan();
      },

      deep: true,
    },

    getSelectedDistricts: {
      handler() {
        window.send_data_to_plan();
      },

      deep: true,
    },
  },

  mounted() {
    if (this.tooltipVisible !== null) {
      this.setTooltipVisible(true);
    }
  },

  methods: {
    ...mapMutations('geoTree', [
      'chooseOrCancelTree',
      'chooseOrCancelSearchTree',
      'setTooltipVisible',
      'setVisibleTree',
      'setChosenSection',
      'resetSearch',
    ]),

    ...mapActions('geoTree', [
      'chooseGeoNode',
      'setMillionaireCities',
      'setRegionalCenters',
      'resetDistricts',
    ]),

    setSection(section) {
      this.setChosenSection(section);
      window.send_data_to_plan();
    },

    chooseNode(nodeDesc) {
      this.chooseGeoNode(nodeDesc);
    },

    chooseCities() {
      if (this.hasSelectedCities) {
        this.hideGeoTree();
      }
    },

    resetChanges() {
      this.resetDistricts();
      this.chooseOrCancelTree(false);
      this.chooseOrCancelSearchTree(false);
    },

    hideGeoTree() {
      this.resetSearch();
      this.setVisibleTree(false);
    },

    scrollToBottom() {
      const geoTree = document.querySelector('#geoTree');
      geoTree.scrollTo({
        top: geoTree.scrollHeight,
        behavior: 'smooth',
      });
    },
  },
};
</script>

<style scoped>
.search-block__title {
  font-size: 18px;
  font-weight: 700;
  color: #5d5d5d;
  margin: 80px 0 20px;
}

.city-tree-description__title {
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 12px;
  margin-top: 70px;
}

.city-tree-description__text {
  margin-bottom: 12px;
}

.cities-filter {
  margin-top: 20px;
  margin-bottom: 20px;
}

.cities-filter__body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.cities-description {
  display: inline-block;
  font-weight: 400;
  max-width: 250px;
}

.cities-filter__title {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
}

.change-cities-nav {
  position: absolute;
  width: 100%;
  top: 30px;
  left: 30px;
  display: flex;
  z-index: 200;
  max-width: 470px;
}

.change-cities-nav:before {
  position: absolute;
  opacity: 1;
  transition: .3s;
  content: '';
  height: 2px;
  width: 80%;
  bottom: 0;
  left: 0;
  background: #ddd;
}

.change-cities-item {
  transition: .3s;
  display: flex;
  flex-direction: row;
  height: 20px;
  gap: 5px;
  cursor: pointer;
  font-size: 16px;
  position: relative;
  padding: 0 8px 3px 0;
  align-items: flex-end;
}

.change-cities-item_active {
  font-size: 20px;
  font-weight: 700;
}

.change-cities-item::after {
  position: absolute;
  opacity: 0;
  transition: .3s;
  content: '';
  height: 2px;
  width: 100%;
  bottom: 0;
  left: 0;
  background: var(--color-green-prime);
}

.change-cities-item:hover::after {
  opacity: 1;
}

.change-cities-item_active::after {
  opacity: 1;
}

.cities-count-wrapper {
  font-size: 16px;
  margin-bottom: 20px;
}

.cities-count {
  color: var(--color-green-prime);
}

.other-countries {
  font-size: 16px;
}

.countries-description {
  display: inline-block;
  font-weight: 400;
  margin-bottom: 20px;
  max-width: 250px;
}

.search-block__wrapper-btns {
  margin-bottom: 30px;
  display: flex;
  gap: 10px;
}
</style>
