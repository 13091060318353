<template>
  <div class="dropdownSelectorWrapper companyTypeSelectorWrapper">
    <p class="dropdownSelectorHeader">
      <b>Правовая форма:</b>
    </p>
    <div
      class="dropdownSelectorButtonWrapper"
      @click.stop="$emit('selectorChange', 'legalForm')"
    >
      <ButtonSelector
        :content="selectedLegalFormsDescription"
        :width="buttonSelectorWidth"
        class="buttonMainDropdownSelector"
      />
    </div>
    <transition name="slide">
      <DropdownSelector
        v-show="showLegalForm"
        :enable-all-option="legalFormEnableAllOption"
        :options="getLegalFormRealOnly"
        :other-option="legalFormOtherOption"
        :extra-option="getLegalFormClosedOnly"
        selector-type="legalForm"
        class="mainDropdownSelector"
        :class="[{ mainDropdownSelectorVisible: showLegalForm }]"
        selector-name="Правовая форма"
        @enableAll="enableAllLegalForms"
        @include="toggleIncludeType"
        @toggleOtherOption="toggleUnknownLegalFormIncluded"
        @toggleExtraOption="toggleIncludeClosed"
      />
    </transition>
  </div>
</template>

<script>
import { computed, inject } from 'vue';
import { useStore } from 'vuex';
import ButtonSelector from 'button-selector';
import DropdownSelector from '../DropdownSelector/DropdownSelector.vue';

export default {
  name: 'MainBaseLegalFormSelector',
  components: { DropdownSelector, ButtonSelector },

  props: {
    showLegalForm: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ['selectorChange'],

  setup() {
    const store = useStore();

    const selectedOptionsDescription = inject('selectedOptionsDescription');

    const unknownLegalFormIncluded = computed(() => store.state.unknownLegalFormIncluded);
    const getLegalFormRealOnly = computed(() => store.getters.getLegalFormRealOnly);
    const getLegalFormClosedOnly = computed(() => store.getters.getLegalFormClosedOnly);

    const legalFormEnableAllOption = computed(() => ({ title: 'Все формы' }));

    const legalFormOtherOption = computed(() => ({
      title: 'Форма не определена',
      title_accusative: 'компании, у которых не указана правовая форма',
      included: unknownLegalFormIncluded.value,
      popupUrl: '/api/popup/main_absent_legal_info',
    }));

    const selectedLegalFormsDescription = computed(() => selectedOptionsDescription(
      getLegalFormRealOnly.value,
      unknownLegalFormIncluded.value,
      'Все формы',
      'Не определена',
    ));

    const enableAllLegalForms = () => store.dispatch('enableAllLegalForms');
    const toggleUnknownLegalFormIncluded = () => store.dispatch('toggleUnknownLegalFormIncluded');
    const toggleIncludeType = (type) => store.dispatch('toggleIncludeType', type);
    const toggleIncludeClosed = () => store.dispatch('toggleIncludeClosed');

    const buttonSelectorWidth = '155px';

    return {
      getLegalFormRealOnly,
      getLegalFormClosedOnly,
      unknownLegalFormIncluded,
      legalFormEnableAllOption,
      legalFormOtherOption,
      selectedLegalFormsDescription,
      buttonSelectorWidth,
      enableAllLegalForms,
      toggleUnknownLegalFormIncluded,
      toggleIncludeType,
      toggleIncludeClosed,
    };
  },
};
</script>

<style scoped>
.dropdownSelectorHeader {
  margin-bottom: 5px;
  font-size: 14px;
}

.mainDropdownSelector {
  position: absolute;
  top: 92%;
  z-index: 15;
  overflow: hidden;
  padding-bottom: 10px;
}

.slide-enter-active {
  transition-duration: 0.5s;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
  max-height: 1300px;
  overflow: hidden;
}

.slide-enter, .slide-leave-to {
  overflow: hidden;
  max-height: 0;
}

.dropdownSelectorWrapper {
  margin-bottom: 15px;
}

.dropdownSelectorWrapper.companyTypeSelectorWrapper {
  margin-bottom: 30px;
}
</style>
