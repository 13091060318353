import { createApp } from 'vue';
import initSentry from '@/vue_sentry';
import StaticMainBaseFilter
    from '@/main_base/components/StaticMainBaseFilter/StaticMainBaseFilter.vue';
import AdditionalFiltersSection2 from './components/AdditionalFilters/AdditionalFiltersSection2';
import AdditionalFiltersSection4 from './components/AdditionalFilters/AdditionalFiltersSection4';
import MainBaseConfigurator from './components/MainBaseConfigurator/MainBaseConfigurator';
import MainBasePhoneSubfilters from './components/MainBasePhoneSubfilters/MainBasePhoneSubfilters';
import store from './store/store';

const apps = [
    { id: 'vue_main_base_configurator', component: MainBaseConfigurator },
    { id: 'vue_static_main_base_field', component: StaticMainBaseFilter },
    { id: 'vue_main_base_phone_subfilters', component: MainBasePhoneSubfilters },
    { id: 'vue_main_base_additional_filters_section_2', component: AdditionalFiltersSection2 },
    { id: 'vue_main_base_additional_filters_section_4', component: AdditionalFiltersSection4 },
];

apps.forEach(({ id, component }) => {
    const el = document.getElementById(id);
    if (el) {
        const app = createApp(component);
        initSentry(app);
        app.use(store);
        window[id] = app.mount(`#${id}`);
    }
});
