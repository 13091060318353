<template>
  <div class="additional_filter_header_wrapper">
    <span class="additional_filter_header">Фильтр по дате регистрации</span>
    <p class="additional_filter_explain_text">
      Выбирая данную опцию, вы получите компании, зарегистрированные в ФНС
      за выбранный вами год или несколько лет.
    </p>
    <div class="selector_witch_instruction">
      <MultiSelector
        title="Зарегистрированы за: "
        all-selected-text="Все годы"
        :items="regYears"
        with-scroll
        @itemChange="regYearChange"
        @allItemSelect="allRegYearsSelect"
      />
      <span
        class="instruction popup"
        data-file="/popup/registration_date.php"
      />
    </div>
    <span>
      Фильтрацию <b>по месяцу</b> можно найти в разделе <a class="green_link" href="/new" target="_blank">"Новые компании"</a>
    </span>
  </div>
</template>

<script>
import { onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import MultiSelector from '../../../../common_components/MultiSelector/MultiSelector.vue';

export default {
  name: 'RegistrationFilter',
  components: { MultiSelector },

  setup() {
    const store = useStore();

    const regYears = computed(() => store.state.regYears);

    const regYearChange = (payload) => {
      store.dispatch('regYearChange', payload);
    };

    const allRegYearsSelect = (payload) => {
      store.dispatch('allRegYearsSelect', payload);
    };

    const setRegYears = async () => {
      await store.dispatch('setRegYears');
    };

    onMounted(() => {
      setRegYears();
    });

    return {
      regYears,
      regYearChange,
      allRegYearsSelect,
    };
  },
};
</script>

<style scoped src="./AdditionalFilters.css"/>
