// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/icon_query.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.additional_filter_item[data-v-46a69906] {\n  display: flex;\n  margin-bottom: 9px;\n  align-items: center;\n}\n.instruction[data-v-46a69906] {\n  cursor: pointer;\n  display: inline-block;\n  position: relative;\n  z-index: 0;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") center center no-repeat;\n  width: 17px;\n  height: 17px;\n  margin-left: 5px;\n}\n", ""]);
// Exports
module.exports = exports;
