<template>
  <div>
    {{ filterLabel }} {{ extraFiltersCount }} доп. {{ filterWord }}
  </div>
</template>

<script>
import { pluralize } from 'numeralize-ru';

export default {
  name: 'AdditionalFilterCountBalloon',
  props: {
    extraFiltersCount: {
      type: Number,
      required: true,
      default: 0,
    },
  },

  computed: {
    filterLabel() {
      return pluralize(
        this.extraFiltersCount,
        'Включен',
        'Включены',
        'Включены',
      );
    },

    filterWord() {
      return pluralize(
        this.extraFiltersCount,
        'фильтр',
        'фильтра',
        'фильтров',
      );
    },
  },
};
</script>
