<template>
  <div
    class="option"
    :class="[{active: isActive}]"
    :title="title"
  >
    <div>
      <span class="label">{{ selectorLabel }}</span>
      <img
        v-if="popupUrl"
        id=""
        src="../../../assets/images/icon_query.png"
        alt=""
        class="popup helpIcon _helpIcon"
        :data-file="popupUrl"
      >
      <span
        v-if="description"
        class="description"
      >
        <img
          id=""
          src="../../../assets/images/icon_query.png"
          alt="description"
          class="popup helpIcon"
        >
        <span class="description__text">
          {{ description }}
        </span>
      </span>
    </div>

    <div class="inclusionExclusionOptions">
      <div
        class="checkboxWrapper"
        @click="inclusionClick($event)"
      >
        <Checkbox
          v-if="!isExclusionNotNeeded"
          :mode="['exportbase', 'dark']"
          :chosen="included"
        />
        <Checkbox
          v-else
          :mode="['exportbase', 'dark']"
          :chosen="allChosen"
        />
      </div>
      <div
        class="checkboxWrapper"
        @click="exclusionClick($event)"
      >
        <Checkbox
          v-if="!isExclusionNotNeeded"
          :mode="['exportbase', 'dark']"
          :chosen="excluded"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Checkbox from 'checkbox';

export default {
  name: 'DropdownOptionWithExclusion',
  components: {
    Checkbox,
  },

  props: {
    isExclusionNotNeeded: {
      type: Boolean,
      required: false,
      default: false,
    },

    selectorLabel: {
      type: String,
      required: true,
    },

    allChosen: {
      type: Boolean,
      required: false,
      default: false,
    },

    included: {
      type: Boolean,
      required: false,
      default: false,
    },

    excluded: {
      type: Boolean,
      required: false,
      default: false,
    },

    selectorLabelAccusative: {
      type: String,
      required: false,
      default: '',
    },

    popupUrl: {
      type: String,
      required: false,
      default: '',
    },

    isActive: {
      type: Boolean,
      required: false,
    },

    description: {
      type: String,
      required: false,
      default: null,
    },
  },

  emits: ['inclusion', 'exclusion'],

  computed: {
    title() {
      return `Добавляет в ваш заказ ${this.labelAccusativeFormatted}`;
    },

    labelAccusativeFormatted() {
      if (this.labelIsAbbreviation) {
        return this.selectorLabel;
      } if (this.selectorLabelAccusative) {
        return this.selectorLabelAccusative.charAt(0).toLowerCase()
            + this.selectorLabelAccusative.slice(1);
      }
      return this.selectorLabel.charAt(0).toLowerCase() + this.selectorLabel.slice(1);
    },

    labelIsAbbreviation() {
      const secondSymbol = this.selectorLabel[1];
      return secondSymbol.toLowerCase() !== secondSymbol;
    },
  },

  methods: {
    inclusionClick(event) {
      if (event.target.classList.contains('_helpIcon')) {
        return;
      }
      this.$emit('inclusion');
    },

    exclusionClick(event) {
      if (event.target.classList.contains('_helpIcon')) {
        return;
      }
      this.$emit('exclusion');
    },
  },
};
</script>

<style scoped src="./DropdownOptionWithExclusion.css" />
