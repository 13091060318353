<template>
  <div class="additional_filter_header_wrapper">
    <span class="additional_filter_header">Расположение компании</span>
    <p class="additional_filter_explain_text">
      Данный фильтр позволяет отобрать фирмы, офисы которых находятся на определенных этажах зданий.
    </p>
    <DropdownConfigurator
      :content="selectedFloorType"
      title="Этаж помещения:"
      width="200px"
      :is-open="showFloorSelector"
      @clickButton="toggleFloorSelector"
    >
      <FloorSelector />
    </DropdownConfigurator>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FloorSelector from './FloorSelector.vue';
import DropdownConfigurator from '../../../../egrul/components/DropListConfigurator/DropdownConfigurator.vue';

export default {
  name: 'CompanyLocation',
  components: { DropdownConfigurator, FloorSelector },
  data() {
    return {
      showFloorSelector: false,
    };
  },

  computed: {
    ...mapGetters([
      'selectedFloorTypeText',
    ]),

    selectedFloorType() {
      return this.selectedFloorTypeText || 'Все этажи';
    },
  },

  watch: {
    showFloorSelector(newValue) {
      if (newValue) {
        document.addEventListener('click', this.outsideEventListenerForShownMultiSelector);
      } else {
        document.removeEventListener('click', this.outsideEventListenerForShownMultiSelector);
      }
    },
  },

  methods: {
    toggleFloorSelector() {
      this.showFloorSelector = !this.showFloorSelector;
    },

    outsideEventListenerForShownMultiSelector(event) {
      const clickedElement = event.target;
      const insideSelector = clickedElement.closest('.dropdownBody');
      const insideSelectorButton = clickedElement.closest('.selectMenu');

      if (this.showFloorSelector && !insideSelector && !insideSelectorButton) {
        this.showFloorSelector = false;
      }
    },
  },
};
</script>

<style scoped src="./AdditionalFilters.css" />
