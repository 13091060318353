<template>
  <div
    class="required-options"
    @click="handleClick"
  >
    <GeoSelector
      :it-is-time-to-show="itIsTimeToShow"
    />
    <RubricsSelector
      :it-is-time-to-show="itIsTimeToShow"
    />

    <NotificationComponent
      v-if="showNotification && itIsTimeToShow && !showLinkRubricNotification"
      class="required-options-notification"
      display-mode="overlay"
    >
      <p>
        Сначала выберите нужные <b>города и рубрики</b>,
        затем настройте другие фильтры справа
      </p>
    </NotificationComponent>

    <NotificationComponent
      v-if="showLinkRubricNotification && itIsTimeToShow"
      class="required-options-notification"
      is-highlighted
      display-mode="overlay"
    >
      <p>
        Выбрана рубрика <b>{{ rubricName }}</b>. Вы можете выбрать дополнительные рубрики,
        настроить города и фильтры.
      </p>
    </NotificationComponent>

    <RubricsTree v-if="visibleTree" />
    <GeoTree v-if="visibleGeoTree" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import RubricsTree from '@/main_base/components/RubricsTree/RubricsTree.vue';
import RubricsSelector from '@/main_base/components/RubricsSelector/RubricsSelector.vue';
import GeoSelector from '@/main_base/components/GeoSelector/GeoSelector.vue';
import GeoTree from '@/main_base/components/GeoTree/GeoTree.vue';
import NotificationComponent from '@/common_components/NotificationComponent/NotificationComponent.vue';

export default {
  name: 'RequiredOptions',
  components: {
    NotificationComponent,
    GeoTree,
    GeoSelector,
    RubricsSelector,
    RubricsTree,
  },

  data() {
    return {
      showNotification: true,
      showLinkRubricNotification: false,
      rubricName: '',
      itIsTimeToShow: false,
    };
  },

  computed: {
    ...mapState('rubricsTree', [
      'visibleTree',
    ]),

    ...mapGetters('rubricsTree', [
      'getSelectedSectionsName',
    ]),

    ...mapState('geoTree', {
      visibleGeoTree: 'visibleTree',
    }),
  },

  async mounted() {
    await this.setRubricsTree();
    await this.setGeoTree();
    await this.setDistricts();

    const urlParams = new URLSearchParams(window.location.search);
    const sId = urlParams.get('sId');
    if (sId && !Number.isNaN(sId)) {
      this.chooseRubrics([Number(sId)]);
      this.showLinkRubricNotification = true;
      [this.rubricName] = this.getSelectedSectionsName;
      urlParams.delete('sId');
      window.history.replaceState(null, '', window.location.origin);
    }

    setTimeout(() => {
      this.itIsTimeToShow = true;
    }, 3000);
  },

  methods: {
    ...mapActions('rubricsTree', [
      'setRubricsTree',
      'chooseRubrics',
    ]),

    ...mapActions('geoTree', [
      'setGeoTree',
      'setDistricts',
    ]),

    handleClick() {
      this.showNotification = false;
      this.showLinkRubricNotification = false;
    },
  },
};
</script>

<style scoped>
  .required-options {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .required-options-notification {
    z-index: 3;
    top: 115px;
    left: 0;
  }
</style>
