export default class MainBaseConfiguratorUtil {
    static snakeCaseToCamelCase(input)
    {
        return input
            .split("_")
            .reduce(
                (res, word, i) =>
                    i === 0
                        ? word.toLowerCase()
                        : `${res}${word.charAt(0).toUpperCase()}${word
                            .substr(1)
                            .toLowerCase()}`,
                ""
            );
    }

    static isFilterChosen(value)
    {
        return (Number(value) === 1);
    }
}
