<template>
  <div class="additional_filter_header_wrapper">
    <span class="additional_filter_header">Дополнительные фильтры</span>
    <p class="additional_filter_explain_text">
      Выберите уточняющие фильтры для максимально гибкой настройки вашей базы данных.
    </p>
    <div class="checkboxes">
      <div class="checkbox_col">
        <div
          v-if="!onlyWithoutSiteDisabled"
          class="additional_filter_item _option_button"
          data-filter="without_site"
        >
          <Checkbox
            :chosen="!!withoutSite"
            :disabled="onlyWithoutSiteDisabled"
            :mode="['dark']"
            title="Только компании без сайта"
            class="additional_checkbox"
            @change="toggleAdditionalFilter('withoutSite')"
          />
          <span
            class="main-base_instruction popup"
            data-file="api/popup/main_without_site_instruction"
          />
        </div>
        <div
          class="additional_filter_item _option_button"
          data-filter="has_license"
        >
          <Checkbox
            :chosen="!!hasLicense"
            :mode="['dark']"
            title="Только компании с лицензией"
            class="additional_checkbox"
            @change="toggleAdditionalFilter('hasLicense')"
          />
          <span
            class="main-base_instruction popup"
            data-file="api/popup/main_has_license_instruction"
          />
        </div>
        <div
          class="additional_filter_item _option_button"
          data-filter="main_okved"
        >
          <Checkbox
            :chosen="!!mainOkved"
            :mode="['dark']"
            title="Показать главный ОКВЭД"
            class="additional_checkbox"
            @change="toggleAdditionalFilter('mainOkved')"
          />
          <span
            class="main-base_instruction popup"
            data-file="api/popup/main_show_main_okved_instruction"
          />
        </div>
      </div>
      <div class="checkbox_col">
        <div
          class="additional_filter_item _option_button"
          data-filter="show_kpp_okpo"
        >
          <Checkbox
            :chosen="!!showKppOkpo"
            :mode="['dark']"
            title="Показать КПП / ОКПО"
            class="additional_checkbox"
            @change="toggleAdditionalFilter('showKppOkpo')"
          />
          <span
            class="main-base_instruction popup"
            data-file="api/popup/main_show_kpp_okpo_instruction"
          />
        </div>
        <div
          class="additional_filter_item _option_button"
          data-filter="show_registration_date"
        >
          <Checkbox
            :chosen="!!showRegistrationDate"
            :mode="['dark']"
            title="Показать год регистрации компании"
            class="additional_checkbox"
            @change="toggleAdditionalFilter('showRegistrationDate')"
          />
          <span
            class="main-base_instruction popup"
            data-file="api/popup/main_show_registration_date_instruction"
          />
        </div>
        <div
          class="additional_filter_item _option_button"
          data-filter="show_inc_and_profit"
        >
          <Checkbox
            :chosen="!!showIncAndProfit"
            :mode="['dark']"
            html-title
            title="Показать <span style='font-weight: 700'>выручку/прибыль</span> за послед. 4 года"
            class="additional_checkbox"
            @change="toggleAdditionalFilter('showIncAndProfit')"
          />
          <span
            class="main-base_instruction popup"
            data-method="GET"
            data-file="/api/popup/popup_income_profit"
          />
        </div>
      </div>
      <div class="checkbox_col">
        <div
          class="additional_filter_item _option_button"
          data-filter="show_legal_name"
        >
          <Checkbox
            :chosen="!!showLegalName"
            :mode="['dark']"
            class="additional_checkbox"
            title="Показать юридическое название"
            @change="toggleAdditionalFilter('showLegalName')"
          />
          <span
            class="main-base_instruction popup"
            data-file="api/popup/main_show_legal_name_instruction"
          />
        </div>
        <div
          class="additional_filter_item _option_button"
          data-filter="show_site_description"
        >
          <Checkbox
            :chosen="!!showSiteDescription"
            :mode="['dark']"
            class="additional_checkbox"
            title="Показать описание сайта (title и description)"
            @change="toggleAdditionalFilter('showSiteDescription')"
          />
          <span
            class="main-base_instruction popup"
            data-file="api/popup/main_show_site_description_instruction"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import Checkbox from 'checkbox';

const store = useStore();

const withoutSite = computed(() => store.state.withoutSite);
const hasLicense = computed(() => store.state.hasLicense);
const mainOkved = computed(() => store.state.mainOkved);
const showRegistrationDate = computed(() => store.state.showRegistrationDate);
const showIncAndProfit = computed(() => store.state.showIncAndProfit);
const showKppOkpo = computed(() => store.state.showKppOkpo);
const showLegalName = computed(() => store.state.showLegalName);
const showSiteDescription = computed(() => store.state.showSiteDescription);
const onlyWithoutSiteDisabled = computed(() => store.state.onlyWithoutSiteDisabled);

const toggleAdditionalFilter = (additionalFilter) => {
  store.dispatch('toggleAdditionalFilter', additionalFilter);
};
</script>

<style scoped src="./AdditionalFilters.css" />
