<template>
  <TwoBlocksPopup @close-popup="closePopup">
    <template #popup-left-block>
      <p class="rubrics-tree__title">
        Конфигурация рубрик
      </p>
      <div class="main-rubric-wrapper">
        <ToggleSwitch
          :id="includeMainRubricFilter.name"
          :title="includeMainRubricFilter.title"
          :chosen="includeMainRubricFilter.chosen"
          :mode="['exportbase']"
          @set-chosen-item="toggleIncludeMainRubricFilter"
        />
      </div>
      <div class="option-text">
        Опция позволяет получить профильные компании, у которых выбранные вами
        рубрики являются основным видом деятельности.
        <div class="option-example">
          <span
            class="option-example__text"
            @click="showMoreCompaniesInfo = true"
          >
            Показать пример
          </span>
          <MoreCompaniesInfoPopup
            v-if="showMoreCompaniesInfo"
            main-activity-type
            @hide-more-companies-info-popup="showMoreCompaniesInfo = false"
          />
        </div>
      </div>
      <div class="rubrics-tree-search">
        <SearchBar
          v-model="searchStringModel"
          class="searchBar"
          width="100%"
          placeholder="Поиск сферы деятельности/рубрики"
          @reset="resetSearch"
        />
      </div>
      <TreeComponent
        v-if="!isSearchMode"
        :node="getTree.getRoot()"
        with-important-nodes
        :ul-node-mode="{'tree-ul-pa-left-20': 1}"
        :checkbox-node-mode="{'text-bold': 1}"
        :important-node-mode="['tree-city-center']"
        :extra-node-mode="['tree-city', 'mv-15']"
        :close-btn="{
          maxVisibleItems: 12,
          nodeLevel: 3,
          textBtn: ['город', 'города', 'городов'],
        }"
        :chosen-node-mode="['mark']"
        class="rubrics-tree-component scrollable-Y"
        @expand-node="expandRubricNode"
        @choose-node="chooseRubricNode"
      />
      <div
        v-if="isSearchMode"
        class="search-tree scrollable-Y"
      >
        <TreeComponent
          v-if="!emptySearchResult"
          :node="getSearchTree.getRoot()"
          :checkbox-node-mode="{'text-bold': 1}"
          :search-text="searchString"
          :ul-node-mode="{'tree-ul-pa-left-20': 0}"
          :expandable="false"
          class="rubrics-search-tree-component"
          @expand-node="expandRubricSearchNode"
          @choose-node="chooseTreeNode"
        />
        <div
          v-if="showTree"
          class="similar-rubrics-tree"
        >
          <p class="search-tree-similar-title">
            Возможно вы искали следующие рубрики:
          </p>
          <TreeComponent
            :node="getSimilarRubricsTree.getRoot()"
            :search-text="searchStringModel"
            :ul-node-mode="{'tree-ul-pa-left-20': 0}"
            :checkbox-node-mode="{'hidden': 1}"
            :expandable="false"
            @choose-node="chooseTreeNode"
          />
        </div>
        <OkvedsList
          v-if="emptySearchResult && !okvedListIsEmpty"
        />
        <span
          v-if="emptySearchResult && okvedListIsEmpty && !showTree"
          class="emptySearch"
        >Ничего не найдено <br>
          Менеджер поможет с подбором базы!<br>
          Ответим на любой вопрос в чате на сайте, по Email
          <a
            class="rubrics-tree__link"
            href="mailto:info@export-base.ru"
          >info@export-base.</a>
          или по телефону
          <a
            class="rubrics-tree__link"
            href="tel:+78007752912"
            style="white-space: nowrap;"
          >8 800 775-29-12</a>
        </span>
      </div>
    </template>
    <template #popup-right-block>
      <p class="rubrics-tree-description__title">
        Как провести конфигурацию рубрик?
      </p>
      <p class="rubrics-tree-description__text">
        1. Выберите одну или несколько рубрик для начала конфигурации.
      </p>
      <p class="rubrics-tree-description__text">
        2. Вы можете воспользоваться поиском, чтобы найти нужную рубрику.
      </p>
      <div class="configuration">
        <p class="configuration__count _configuration__count">
          Кол-во компаний:
          <b class="configuration__count-value">
            <span class="configuration__count-value-value">
              {{ count.toLocaleString('ru') }}
            </span>
            шт.
          </b>
        </p>
        <p class="configuration__count _configuration__count">
          Цена:
          <b class="configuration__count-value">
            <span class="configuration__count-value-value">
              {{ cost.toLocaleString('ru') }}
            </span>
            руб.
          </b>
        </p>
      </div>

      <div class="search-block__wrapper-btns">
        <div
          class="eb-button-prime small-button"
          :class="{'disabled' : getSelectedSections.length === 0}"
          @click="chooseSections"
        >
          Выбрать
        </div>
        <div
          class="eb-button-secondary small-button"
          @click="resetTree"
        >
          Сброс
        </div>
      </div>
    </template>
  </TwoBlocksPopup>
</template>

<script>
import SearchBar from 'searchbar';
import { TreeComponent } from 'tree';
import ToggleSwitch from 'toggle-switch';
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import debounce from 'lodash.debounce';
import OkvedsList from '@/main_base/components/OkvedsList/OkvedsLIst.vue';
import MoreCompaniesInfoPopup from '@/common_components/MoreCompaniesInfoPopup/MoreCompaniesInfoPopup.vue';
import TwoBlocksPopup from '../../../common_components/TwoBlocksPopup/TwoBlocksPopup.vue';

export default {
  name: 'RubricsTree',
  components: {
    MoreCompaniesInfoPopup,
    ToggleSwitch,
    TwoBlocksPopup,
    OkvedsList,
    TreeComponent,
    SearchBar,
  },

  data() {
    return {
      showMoreCompaniesInfo: false,
    };
  },

  computed: {
    ...mapState('rubricsTree', [
      'includeMainRubricFilter',
      'searchString',
    ]),

    ...mapState('summary', [
      'cost',
      'count',
    ]),

    ...mapGetters('rubricsTree', [
      'getTree',
      'isSearchMode',
      'emptySearchResult',
      'getSearchTree',
      'getSelectedSections',
    ]),

    ...mapState('similarRubricsTree', [
      'showTree',
    ]),

    ...mapGetters('similarRubricsTree', [
      'getSimilarRubricsTree',
      'okvedListIsEmpty',
    ]),

    searchStringModel: {
      get() {
        return this.searchString;
      },

      set: debounce(function (value) {
        this.updateSearch(value);
        this.setSimilarTree(value);
      }, 500),
    },
  },

  methods: {
    ...mapMutations('rubricsTree', [
      'resetSearch',
      'expandRubricNode',
      'expandRubricSearchNode',
      'setVisibleTree',
      'chooseOrCancelTree',
      'chooseOrCancelSearchTree',
    ]),

    ...mapMutations('similarRubricsTree', [
      'chooseSimilarNode',
    ]),

    ...mapActions('rubricsTree', [
      'toggleIncludeMainRubricFilter',
      'updateSearch',
      'chooseRubricNode',
    ]),

    ...mapActions('similarRubricsTree', [
      'setSimilarTree',
    ]),

    chooseTreeNode(node) {
      this.chooseRubricNode(node);
      this.chooseSimilarNode(node);
    },

    resetTree() {
      this.chooseOrCancelTree(false);
      this.chooseOrCancelSearchTree(false);
    },

    closePopup() {
      this.resetSearch();
      this.setVisibleTree(false);
    },

    chooseSections() {
      if (this.getSelectedSections.length !== 0) {
        this.closePopup();
      }
    },
  },
};
</script>

<style scoped>

.rubrics-tree__link {
  color: #60b6ea;
  text-decoration: none;
}

.emptySearch {
  line-height: 1.5;
}

.main-rubric-wrapper {
  border: 1px solid var(--color-green-prime, #839F22);
  align-self: baseline;
  border-radius: 5px;
  padding: 5px;
}

.rubrics-tree__title {
  margin-bottom: 5px;
  font-size: 24px;
  font-weight: 700;
}

.rubrics-tree-search {
  display: flex;
  flex-direction: column;
  width: 97%;
  gap: 10px;
}

.rubrics-tree-component, .search-tree {
  min-height: 50px;
  height: 100%;
  padding-bottom: 20px;
}

.tooltip {
  position: absolute;
  background: white;
  color: black;
  bottom: 20px;
  left: 0;
  border: 1px solid black;
  padding: 10px;
  box-shadow: rgb(0 0 0 / 35%) 0 5px 15px;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
}

.option-example {
  margin-top: 5px;
}

.option-example__text {
  cursor: pointer;
  color: var(--color-green-prime, #839F22);
}

.search-tree-similar-title {
  font-weight: bold;
  margin: 20px 0 10px;
}

.rubrics-tree-description__title {
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 12px;
  margin-top: 80px;
}

.rubrics-tree-description__text {
  margin-bottom: 12px;
}

.search-block__wrapper-btns {
  margin: 30px 0;
  display: flex;
  gap: 10px;
}

.configuration {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin: 15px 0;
}

.configuration__count {
  line-height: 16px;
}

.configuration__count::after {
  display: none;
  content: '';
  position: absolute;
  bottom: 2px;
  right: -20px;
  width: 16px;
  height: 16px;
  background: url("../../../assets/images/small_preloader.gif");
}

.configuration__count-value-value {
  color: var(--color-green-prime);
}

.configuration__count.calculating .configuration__count-value {
  display: none;
}

.configuration__count.calculating::after {
  display: inline-block;
}
</style>
