<template>
  <div>
    Обращаем ваше внимание, что сейчас у ряда компаний в файле выгрузки
    могут присутствовать неполные контакты.
    <span v-if="filledFieldExample && notFilledFieldExample">
      Например, у компании есть <b>"{{ filledFieldExample }}"</b>,
      но не заполнен столбик <b>"{{ notFilledFieldExample }}"</b>.
    </span>
  </div>
</template>

<script>
export default {
  name: 'IncompleteContactsEnabledBalloon',

  props: {
    filledFieldExample: {
      type: String,
      default: '',
    },

    notFilledFieldExample: {
      type: String,
      default: '',
    },
  },
};
</script>
