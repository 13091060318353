<template>
  <section
    id="vue_main_base_summary"
    class="summary"
  >
    <GrayPreloader :visible="isCalculating" />
    <MainBaseSummary />
  </section>
</template>

<script>

import { mapState } from 'vuex';
import GrayPreloader from '../../../common_components/GrayPreloader/GrayPreloader.vue';
import MainBaseSummary from '../MainBaseSummary/MainBaseSummary.vue';

export default {
  name: 'MainBaseOrderBucket',
  components: {
    MainBaseSummary,
    GrayPreloader,
  },

  computed: {
    ...mapState([
      'isCalculating',
    ]),
  },
};
</script>

<style scoped>
#vue_main_base_summary {
  display: flex;
  justify-content: start;
  margin-right: 0;
  margin-top: 0;
  grid-row: 1/3;
  grid-column: 2/3;
}

.summary {
  position: relative;
  height: 100%;
}
</style>
