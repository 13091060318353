import { createStore } from 'vuex';
import similarRubricsTree from '@/main_base/store/modules/similarRubricsTree';
import rubricsTree from '@/main_base/store/modules/rubricsTree';
import geoTree from '@/main_base/store/modules/geoTree';
import actions from './actions';
import mutations from './mutations';
import state from './state';
import getters from './getters';
import summary from './modules/summary';
import mainBasePayPopup from './modules/mainBasePayPopup';

const store = createStore({
    state,
    getters,
    mutations,
    actions,
    modules: {
        summary,
        rubricsTree,
        geoTree,
        similarRubricsTree,
        mainBasePayPopup,
    },
});

export default store;
