export default class MainBaseConfiguratorUtil {
    static pushOrSpliceParam(isSelected, param, currentArray) {
        const resultArray = currentArray;

        if (isSelected) {
            if (resultArray.indexOf(param) === -1) {
                resultArray.push(param);
            }
        } else {
            const index = resultArray.indexOf(param);
            resultArray.splice(index, 1);
        }

        return resultArray;
    }
}
