<!-- eslint-disable vue/v-on-handler-style -->
<!-- eslint-disable vue/html-self-closing -->
<!-- eslint-disable vue/html-closing-bracket-newline -->

<template>
  <div>
    <div class="additional_filter_header_wrapper">
      <span class="additional_filter_header">2. Дополнительные фильтры вашей базы</span>
      <p class="additional_filter_explain_text">
        Выберите уточняющие фильтры для максимально гибкой настройки вашей базы данных.
      </p>
    </div>
    <div
      v-if="!onlyWithoutSiteDisabled"
      class="additional_filter_item _option_button"
      data-filter="without_site"
    >
      <Checkbox
        :chosen="!!withoutSite"
        :mode="onlyWithoutSiteCheckBoxMode"
        :disabled="onlyWithoutSiteDisabled"
        title="Только компании без сайта"
        @change="toggleAdditionalFilter('without_site')"
      />
      <span
        class="instruction popup"
        data-file="api/popup/main_without_site_instruction"></span>
    </div>
    <div
      v-if="onlyWithoutSiteDisabled"
      class="additional_filter_item"
      data-filter="without_site"
      @click.self.stop=""
    >
      <Checkbox
        :chosen="!!withoutSite"
        :mode="onlyWithoutSiteCheckBoxMode"
        :disabled="onlyWithoutSiteDisabled"
        title="Только компании без сайта"
        @change=""
      />
      <span
        class="instruction popup"
        data-file="api/popup/main_without_site_instruction"></span>
    </div>
    <div
      class="additional_filter_item _option_button"
      data-filter="has_license">
      <Checkbox
        :chosen="!!hasLicense"
        :mode="['dark']"
        :html-title="htmlTitle"
        title="Только <b>компании с лицензией</b>"
        @change="toggleAdditionalFilter('has_license')"
      />
      <span
        class="instruction popup"
        data-file="api/popup/main_has_license_instruction"></span>
    </div>
  </div>
</template>

<script>
import Checkbox from 'checkbox';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'AdditionalFiltersSection2',
  components: {
    Checkbox,
  },

  data() {
    return {
      htmlTitle: true,
    };
  },

  computed: {
    ...mapState([
      'withoutSite',
      'hasLicense',
      'onlyWithoutSiteDisabled',
    ]),

    onlyWithoutSiteCheckBoxMode() {
      if (this.onlyWithoutSiteDisabled) {
        return ['dark', 'disabled'];
      }
      return ['dark'];
    },
  },

  methods: {
    ...mapActions([
      'toggleAdditionalFilter',
    ]),
  },
};
</script>

<style scoped src="./AdditionalFilters.css" />
