<template>
  <div class="dropdownSelectorWrapper">
    <p class="dropdownSelectorHeader dropdownSelectorHeader_type">
      <b>Тип компании:</b>
    </p>
    <div
      class="dropdownSelectorButtonWrapper"
      @click.stop="$emit('selectorChange', 'companyType')"
    >
      <ButtonSelector
        :content="selectedCompanyTypesDescription"
        :width="buttonSelectorWidthForImportantSelector"
        is-important-selector
        class="buttonMainDropdownSelector _button-company-type"
      />
    </div>
    <transition name="slide">
      <CompanyTypeSelector
        v-if="showCompanyType"
        :search-company-type="searchCompanyType"
        :enable-all-option="companyTypeEnableAllOption"
        :groups="companyTypeGroups"
        :class="[{ mainDropdownSelectorVisible: showCompanyType }]"
        class="mainDropdownSelector"
        @enableAll="enableAllCompanyTypes"
        @updateSearch="updateSearchCompanyType"
      />
    </transition>
  </div>
</template>

<script>
import {
  computed, inject, watch,
} from 'vue';
import { useStore } from 'vuex';
import debounce from 'lodash.debounce';
import ButtonSelector from 'button-selector';
import CompanyTypeSelector from '../CompanyTypeSelector/CompanyTypeSelector.vue';

export default {
  name: 'MainBaseTypeSelector',
  components: { CompanyTypeSelector, ButtonSelector },

  props: {
    showCompanyType: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ['selectorChange'],

  setup() {
    const store = useStore();

    const selectedOptionsDescription = inject('selectedOptionsDescription');

    const companyType = computed(() => store.state.companyType);
    const companyTypeGroups = computed(() => store.state.companyTypeGroups);
    const searchCompanyType = computed(() => store.state.searchCompanyType);

    const companyTypeEnableAllOption = computed(() => ({ title: 'Все компании' }));

    const selectedCompanyTypesDescription = computed(() => selectedOptionsDescription(
      companyType.value,
      false,
      'Все компании',
      'Без типа',
    ));

    const enableAllCompanyTypes = () => store.dispatch('enableAllCompanyTypes');
    const updateSearchCompanyType = (value) => store.commit('setSearchCompanyType', value);

    watch(
      searchCompanyType,
      debounce(() => {
        if (searchCompanyType.value === '') {
          store.dispatch('resetSearch');
        } else {
          store.dispatch('search');
        }
      }, 300),
    );

    const buttonSelectorWidthForImportantSelector = '155px';

    return {
      companyType,
      companyTypeGroups,
      searchCompanyType,
      companyTypeEnableAllOption,
      selectedCompanyTypesDescription,
      buttonSelectorWidthForImportantSelector,
      enableAllCompanyTypes,
      updateSearchCompanyType,
    };
  },
};
</script>

<style scoped>
.dropdownSelectorHeader {
  margin-bottom: 5px;
  font-size: 14px;
}

.dropdownSelectorHeader_type {
  font-size: 14px;
}

.mainDropdownSelector {
  position: absolute;
  top: 92%;
  z-index: 45;
  overflow: hidden;
  padding-bottom: 10px;
}

.slide-enter-active {
  transition-duration: 0.5s;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
  max-height: 1300px;
  overflow: hidden;
}

.slide-enter, .slide-leave-to {
  overflow: hidden;
  max-height: 0;
}

.dropdownSelectorWrapper {
  margin-bottom: 15px;
}
</style>
