<template>
  <div class="section-city">
    <div>
      <span class="change-cities__title">Выберите нужные города:</span>
      <div
        class="notSelectedCitiesAttention"
        :class="{'selectedCities' : !tooltipVisible}"
      >
        <img
          src="/images/icons/simple_shapes/triangle-attention.svg"
          alt="Внимание! Города не выбраны."
          width="28px"
          height="28px"
        >
        <span class="notSelectedCitiesText">
          Города не выбраны. Настройте свою выборку.
        </span>
      </div>
    </div>
    <SearchBar
      v-model="searchStringModel"
      class="searchBar"
      width="97%"
      placeholder="Поиск города/региона"
      @reset="resetSearch"
    />
    <TreeComponent
      v-if="!isSearchMode"
      id="geoTree"
      :node="getGeoTree.getRoot()"
      with-important-nodes
      :extra-node-mode="['tree-city-center']"
      :additional-node-mode="['tree-city', 'mv-15']"
      :view-line-node-mode="['ml-15', 'mv-15']"
      :second-node-mode="['text-bold']"
      :chosen-node-mode="['mark']"
      :checkbox-node-mode="{'flag': 1}"
      :close-btn="{
        maxVisibleItems: 11,
        nodeLevel: 3,
        textBtn: ['город', 'города', 'городов'],
      }"
      class="geo-tree scrollable-Y"
      @expand-node="expandGeoNode"
      @choose-node="chooseGeoNode"
    />
    <div
      v-if="isSearchMode"
      class="search-tree scrollable-Y"
    >
      <span
        v-if="emptySearchResult"
        class="emptySearch "
      >Ничего не найдено</span>
      <TreeComponent
        v-else
        :node="getGeoSearchTree.getRoot()"
        :search-text="searchStringModel"
        :ul-node-mode="{'tree-ul-pa-left-20': 0}"
        :expandable="false"
        class="geo-tree"
        @expand-node="expandGeoSearchNode"
        @choose-node="chooseSearchNode"
      />
    </div>
  </div>
</template>

<script>
import { TreeComponent } from 'tree';
import SearchBar from 'searchbar';
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';

export default {
  name: 'SectionCity',
  components: {
    SearchBar,
    TreeComponent,
  },

  computed: {
    ...mapState('geoTree', [
      'searchString',
    ]),

    ...mapGetters('geoTree', [
      'getGeoSearchTree',
      'isSearchMode',
      'emptySearchResult',
      'getGeoTree',
      'tooltipVisible',
    ]),

    searchStringModel: {
      get() {
        return this.searchString;
      },

      set(value) {
        this.updateSearch(value);
      },
    },
  },

  methods: {
    ...mapMutations('geoTree', [
      'expandGeoNode',
      'resetSearch',
    ]),

    ...mapActions('geoTree', [
      'chooseGeoNode',
      'chooseGeoSearchNode',
      'updateSearch',
      'expandGeoSearchNode',
    ]),

    chooseSearchNode(nodeDesc) {
      this.chooseGeoSearchNode(nodeDesc);
    },
  },
};
</script>

<style scoped>
.section-city {
  height: calc(100% - 50px);
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  gap: 15px;
}

.notSelectedCitiesAttention {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 97%;
  border: var(--color-warning-light) 1px solid;
  border-radius: 5px;
  box-sizing: border-box;
  transition: .5s;
  opacity: 1;
  height: 30px;
  margin-top: 15px;
}

.notSelectedCitiesText {
  color: var(--color-warning-light);
  font-size: 14px;
  font-weight: bold;
}

.selectedCities {
  opacity: 0;
  height: 0;
  margin-top: 0;
}

.geo-tree {
  padding-left: 30px;
  padding-bottom: 30px;
}

.change-cities__title{
  font-size: 20px;
  font-weight: bold;
}
</style>
