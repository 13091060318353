<template>
  <div>
    <div>
      <span class="notification_left_icon_wrapper">
        <img
          class="notification_left_icon"
          src="/dist/css/images/icon_warning.png"
          alt=""
        >
      </span>
      <span class="notification_after_icon_text">
        У ИП в настоящее время не указываются ИНН, ОГРН и Руководитель.
        Все ИП обезличены, предоставляем только брендовые названия компаний.
      </span>
    </div>
  </div>
</template>

<script setup>

</script>

<style scoped>
.notification_left_icon_wrapper {
  display: inline-block;
  float: left;
  margin-right: 2%;
  width: 8%;
}

.notification_after_icon_text{
  display: inline-block;
  float: left;
  width: 90%;
}

.notification_left_icon {
  width: 100%;
}
</style>
