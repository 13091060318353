<template>
  <div class="inputSearchWrapper">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      class="bi bi-search"
      viewBox="0 0 16 16"
    >
      <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
    </svg>
    <input
      ref="inputSearch"
      :value="value"
      :placeholder="placeholder"
      type="text"
      class="inputSearch"
      @input="updateValue"
    >
  </div>
</template>

<script>
export default {
  name: "InputSearch",

  props: {
    value: {
      type: String,
      required: false,
      default: '',
    },
    focus: {
      type: Boolean,
      required: false,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
  },

  mounted() {
    if (this.focus) {
      this.$refs.inputSearch.focus();
    }
  },

  methods: {
    updateValue(e) {
      this.$emit('updateValue', e.target.value);
    }
  }
};
</script>

<style scoped>
.inputSearchWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.inputSearch {
  padding: 3px 5px;
  height: 16px;
  flex-grow: 1;
}
</style>
