import Fuse from 'fuse.js';
import MainBaseConfiguratorUtil from './utils/MainBaseConfiguratorUtil';

const mutations = {
    enableAllCompanyTypes(state) {
        for (const id in state.companyType) {
            state.companyType[id].included = false;
            state.companyType[id].excluded = false;
            state.companyType[id].isActive = false;
        }
        for (const id in state.companyTypeGroups) {
            state.companyTypeGroups[id].highlight = false;
        }
    },
    enableAllLegalForms(state) {
        for (const id in state.legalForm) {
            if (id === 'closed') {
                continue;
            }
            state.legalForm[id].included = false;
        }
        state.unknownLegalFormIncluded = false;
    },
    enableAllCompanySizes(state) {
        for (const id in state.companySize) {
            state.companySize[id].included = false;
        }
        state.unknownCompanySizeIncluded = false;
    },

    toggleIncludeType(state, { selectorType, id }) {
        state[selectorType][id].included = !state[selectorType][id].included;

        if (state[selectorType][id].excluded) {
            state[selectorType][id].excluded = !state[selectorType][id].excluded;
        }
    },

    toggleExcludeType(state, { selectorType, id }) {
        state[selectorType][id].excluded = !state[selectorType][id].excluded;

        if (state[selectorType][id].included) {
            state[selectorType][id].included = !state[selectorType][id].included;
        }
    },

    resetToggleIncludeType(state, { selectorType, id }) {
        state[selectorType][id].included = false;
    },

    highlightActiveType(state, { selectorType, id }) {
        state[selectorType][id].isActive = true;

        setTimeout(() => {
            state[selectorType][id].isActive = false;
        }, 3000);
    },
    toggleUnknownLegalFormIncluded(state) {
        state.unknownLegalFormIncluded = !state.unknownLegalFormIncluded;
    },
    toggleUnknownCompanySizeIncluded(state) {
        state.unknownCompanySizeIncluded = !state.unknownCompanySizeIncluded;
    },

    toggleIncludeClosed(state) {
        state.legalForm.closed.included = !state.legalForm.closed.included;
        state.legalForm.closed.excluded = !state.legalForm.closed.excluded;
    },

    toggleAdditionalFilter(state, filter) {
        const stateFilterName = MainBaseConfiguratorUtil.snakeCaseToCamelCase(filter);
        state[stateFilterName] = +!state[stateFilterName];

        if (filter === 'show_site_description') {
            if ($('.export .filter .one_filter .of_on_one_filter[name="site"].active').length === 0) {
                $('.export .filter .one_filter .of_on_one_filter[name="site"]').click();
            }
        }

        if (filter === 'without_site' && state.withoutSite) {
            state.hasSitePhone = 0;
        }

        // todo: такой подсчет цены - решение пока не произведен полный рефакторинг конфигуратора базы предприятий. Затем как в ЕГРЮЛ сделать.
        if (state[stateFilterName]) {
            state.summary.costOfOneFirm += +state.summary.mainPrice[filter];
        } else {
            state.summary.costOfOneFirm -= +state.summary.mainPrice[filter];
        }
    },
    setOnlyWithoutSite(state, value) {
        state.withoutSite = value;
    },
    setOnlyUniquePhone(state, value) {
        state.onlyUniquePhones = value;
    },
    setOnlyMobilePhone(state, value) {
        state.onlyMobilePhones = value;
    },
    setHasSitePhone(state, value) {
        state.hasSitePhone = value;
    },
    setHasMessenger(state, value) {
        state.hasMessenger = value;
    },
    setCompanyType(state, companyType) {
        const popupUrls = {
            on_wb_active: '/api/popup/wb_description',
        };
        const descriptions = {
            usn: 'Упрощенная система налогообложения',
            eshn: 'Единый сельскохозяйственный налог',
        };

        const companyTypesState = {};
        companyType.forEach((companyType) => {
            companyTypesState[companyType.id] = {
                id: companyType.id,
                title: companyType.title,
                title_accusative: companyType.title_accusative,
                included: false,
                excluded: false,
                isActive: false,
                popupUrl: popupUrls[companyType.id],
                firstInGroup: (companyType.id === 'on_ozon' || companyType.id === 'sro'),
                groupId: companyType.id_group,
                visible: false,
                hidden: false,
                description: descriptions[companyType.id],
            };
        });
        state.companyType = companyTypesState;
    },
    setFuse(state, companyType) {
        const data = companyType.map(item => ({
            ...item,
            groupId: item.id_group,
        })).filter(item => item.id_group !== null);

        state.fuse = new Fuse(data, state.fuseOptions);
    },
    setVisibleCompanyTypesByGroupId(state, groupId) {
        if (state.searchCompanyType === '') {
            for (const id in state.companyType) {
                if (state.companyType[id].groupId === groupId) {
                    state.companyType[id].visible = !state.companyType[id].visible;
                }
            }
        } else {
            Object.entries(state.companyType)
                .filter(([key]) => state.companyType[key].groupId === groupId)
                .forEach(([key]) => {
                    state.companyType[key].hidden = !state.companyType[key].hidden;
                });
        }
    },
    setCompanyTypeGroups(state, groups) {
        const groupsState = {};

        for (const [id, groupName] of Object.entries(groups)) {
            groupsState[Number(id)] = {
                id: Number(id),
                name: groupName,
                active: false,
                show: true,
                highlight: false,
            };
        }
        state.companyTypeGroups = groupsState;
    },
    setShowCompanyTypeGroup(state, { groupId, show }) {
        state.companyTypeGroups[groupId].show = show;
    },
    toggleActiveGroup(state, groupId) {
        for (const [id, group] of Object.entries(state.companyTypeGroups)) {
            group.active = group.id === groupId ? !group.active : group.active;
        }
    },
    hideGroups(state) {
        for (const [id, group] of Object.entries(state.companyTypeGroups)) {
            group.active = false;
        }
    },
    hideTypes(state) {
        for (const id in state.companyType) {
            state.companyType[id].visible = false;
        }
    },
    resetHiddenTypes(state) {
        Object.entries(state.companyType).forEach(([, companyType]) => {
            companyType.hidden = false;
        });
    },
    setLegalForm(state, legalForms) {
        const legalFormsState = {};
        legalForms.forEach((legalForm) => {
            legalFormsState[legalForm.id] = legalForm.id !== 'closed'
                ? {
                    id: legalForm.id,
                    title: legalForm.title,
                    included: false,
                }
                : {
                    id: legalForm.id,
                    title: legalForm.title,
                    included: false,
                    excluded: true,
                };
        });
        state.legalForm = legalFormsState;
    },
    setShowCeoSex(state, value) {
        state.showCeoSex = value;
    },
    setShowSiteDescription(state, value) {
        state.showSiteDescription = value;
    },
    setCompanySize(state, companySizes) {
        const companySizesState = {};
        companySizes.forEach((companySize) => {
            companySizesState[companySize.id] = {
                id: companySize.id,
                title: companySize.title,
                included: false,
            };
        });
        state.companySize = companySizesState;
    },

    activateAllGroups(state) {
        for (const [id, group] of Object.entries(state.companyTypeGroups)) {
            group.active = true;
        }
    },
    visibleAllCompanyTypes(state) {
        Object.entries(state.companyType).forEach(([key]) => {
            state.companyType[key].visible = true;
        });
    },

    setSearchCompanyType(state, value) {
        state.searchCompanyType = value;
    },

    highlightCompanyTypeGroup(state, id) {
        const companyTypeGroupId = state.companyType[id].groupId;

        let companyTypes = [];
        for (const [key, value] of Object.entries(state.companyType)) {
            companyTypes.push({...value});
        }
        const groupCompanyTypes = companyTypes
            .filter((CompanyType) => CompanyType.groupId === companyTypeGroupId);

        state.companyTypeGroups[companyTypeGroupId].highlight = groupCompanyTypes.some(
            companyType => companyType.included
        );
    },
};

export default mutations;
