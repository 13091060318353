<template>
  <div
    :class="{ 'text_bottom__bold': isEmailValidatedSelected }"
    class="checbox_with_instruction"
  >
    <CheckBox
      id="email_verified"
      title="Только проверенные валидатором E-mail"
      :chosen="isEmailValidatedSelected"
      @change="toggleEmailValidated"
    />
    <span
      class="instruction popup"
      data-file="/popup/validated_email_info.php"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CheckBox from 'checkbox';

export default {
  name: 'EmailValidatedCheckboxBalloon',
  components: {
    CheckBox,
  },

  emits: ['changeSubFilter'],

  computed: {
    ...mapState([
      'mainBaseEmailFilter',
    ]),

    isEmailValidatedSelected() {
      const subFilter = this.mainBaseEmailFilter.subFilters.find((filter) => filter.name === 'email_verified');
      return subFilter ? subFilter.selected : false;
    },
  },

  methods: {
    toggleEmailValidated() {
      this.$emit('changeSubFilter');
    },
  },
};
</script>

<style scoped>
.text_bottom__bold {
  font-weight: bold;
}

.instruction {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-image: url('/dist/css/images/exp_question.png');
  background-size: contain;
  margin-left: 5px;
  cursor: pointer;
}

.checbox_with_instruction {
  display: flex;
  align-items: center;
}
</style>
