import DropdownSelectorUtil from './utils/DropdownSelectorUtil';
import MainBaseConfiguratorUtil from './utils/MainBaseConfiguratorUtil';

const getters = {
    getTruckLesseeSelected(state) {
        return (state.companyType.truck_lessee.included);
    },
    getCarLesseeSelected(state) {
        return (state.companyType.car_lessee.included);
    },
    getLesseeSelected(state) {
        return (state.companyType.lessee.included);
    },
    getTender44ParticipantSelected(state) {
        return (state.companyType.tender44_participant.included);
    },
    getTender44WinnerSelected(state) {
        return (state.companyType.tender44_winner.included);
    },
    getTender223ParticipantSelected(state) {
        return (state.companyType.tender223_participant.included);
    },
    getTender223WinnerSelected(state) {
        return (state.companyType.tender223_winner.included);
    },
    getSroSelected(state) {
        return (state.companyType.sro.included);
    },
    getSroMemberSelected(state) {
        return (state.companyType.sro_member.included);
    },
    getOnlineShopSelected(state) {
        return (state.companyType.online_shop.included);
    },
    getDealerSelected(state) {
        return (state.companyType.dealer.included);
    },
    getDistributorSelected(state) {
        return (state.companyType.distributor.included);
    },
    getManufacturerSelected(state) {
        return (state.companyType.manufacturer.included);
    },


    /**
     * Правовые формы без признака "в стадии ликвидации"
     * @param state
     * @returns Object
     */
    getLegalFormRealOnly(state) {
        const onlyReal = {};
        const { legalForm } = state;
        for (const key in legalForm) {
            if (key === 'closed') {
                continue;
            }
            onlyReal[key] = legalForm[key];
        }
        return onlyReal;
    },
    /**
     * Признак "в стадии ликвидации"
     * @param state
     * @returns {*}
     */
    getLegalFormClosedOnly(state) {
        return state.legalForm.closed;
    },

    getSelectedLegalFormAsArray(state, getters) {
        return DropdownSelectorUtil.optionsToIdsList(getters.getLegalFormRealOnly, state.unknownLegalFormIncluded);
    },
    getIsLegalForm(state, getters) {
        return (DropdownSelectorUtil.somethingIncluded(
            getters.getLegalFormRealOnly, state.unknownLegalFormIncluded,
        )) ? '1' : '0';
    },
    getSelectedLegalForm(state, getters) {
        return getters.getSelectedLegalFormAsArray;
    },
    getIsCompanyType(state) {
        return (DropdownSelectorUtil.somethingIncluded(
            state.companyType, false,
        )) ? '1' : '0';
    },
    getSelectedCompanyType(state) {
        const result = [];
        for (const key in state.companyType) {
            if (state.companyType[key].included) {
                result.push(key);
            } else if (state.companyType[key].excluded) {
                result.push(`no-${key}`);
            }
        }

        return result;
    },
    getSelectedCompanySizeAsArray(state) {
        return DropdownSelectorUtil.optionsToIdsList(state.companySize, state.unknownCompanySizeIncluded);
    },
    getIsCompanySize(state) {
        return (DropdownSelectorUtil.somethingIncluded(
            state.companySize, state.unknownCompanySizeIncluded,
        )) ? '1' : '0';
    },
    getSelectedCompanySize(state, getters) {
        return getters.getSelectedCompanySizeAsArray;
    },


    getShowMainOkved(state) {
        return String(state.mainOkved);
    },
    getShowRegistrationDate(state) {
        return String(state.showRegistrationDate);
    },
    getHighlightNewCompanies(state) {
        return String(state.highlightNewCompanies);
    },
    getShowIncAndProfit(state) {
        return String(state.showIncAndProfit);
    },
    getShowKppOkpo(state) {
        return String(state.showKppOkpo);
    },
    getShowLegalName(state) {
        return String(state.showLegalName);
    },
    getShowCeoSex(state) {
        return String(state.showCeoSex);
    },
    getShowSiteDescription(state) {
        return String(state.showSiteDescription);
    },
    getOnlyWithoutSite(state) {
        return String(state.withoutSite);
    },
    getOnlyMobilePhone(state) {
        return String(state.onlyMobilePhones);
    },
    getHasSitePhone(state) {
        return String(state.hasSitePhone);
    },
    getHasMessenger(state) {
        return String(state.hasMessenger);
    },
    getOnlyUniquePhone(state) {
        return String(state.onlyUniquePhones);
    },
    getOnlyWithLicense(state) {
        return String(state.hasLicense);
    },
    getClosed(state) {
        return state.legalForm.closed
            ? {
                included: state.legalForm.closed.included,
                excluded: state.legalForm.closed.excluded,
            }
            : {
                included: true,
                excluded: false,
            };
    },

    getChosenFiltersCount(state) {
        let cnt = 0;

        // доп. фильтры
        if (MainBaseConfiguratorUtil.isFilterChosen(state.withoutSite)) {
            cnt++;
        }
        if (MainBaseConfiguratorUtil.isFilterChosen(state.hasLicense)) {
            cnt++;
        }

        // доп. настройки
        if (MainBaseConfiguratorUtil.isFilterChosen(state.mainOkved)) {
            cnt++;
        }
        if (MainBaseConfiguratorUtil.isFilterChosen(state.showKppOkpo)) {
            cnt++;
        }
        if (MainBaseConfiguratorUtil.isFilterChosen(state.showRegistrationDate)) {
            cnt++;
        }
        if (MainBaseConfiguratorUtil.isFilterChosen(state.showIncAndProfit)) {
            cnt++;
        }
        if (MainBaseConfiguratorUtil.isFilterChosen(state.showLegalName)) {
            cnt++;
        }

        if (MainBaseConfiguratorUtil.isFilterChosen(state.highlightNewCompanies)) {
            cnt++;
        }
        if (MainBaseConfiguratorUtil.isFilterChosen(state.showCeoSex)) {
            cnt++;
        }
        if (MainBaseConfiguratorUtil.isFilterChosen(state.showSiteDescription)) {
            cnt++;
        }

        return cnt;
    },

    getValueCompanyTypes(state) {
        return Object.values(state.companyType).filter(companyType => companyType.groupId !== null);
    },

    getGroupIds(state) {
        return Object.keys(state.companyTypeGroups);
    },
};

export default getters;
