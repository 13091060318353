import api from '../../api/index';

export default {
  namespaced: true,

  state: {
    mainPrice: {},

    costOfOneFirm: 0,

    cost: 0,
    count: 0,
  },

  getters: {
    getOneCompanyCost(state) {
      return state.costOfOneFirm;
    },
  },

  mutations: {
    setMainBasePrice(state, price) {
      state.mainPrice = price;
    },

    setCost(state, cost) {
      state.cost = cost;
    },

    setCount(state, count) {
      state.count = count;
    },
  },

  actions: {
    setPrice(context) {
      api.getPrice().then((price) => {
        context.commit('setMainBasePrice', price);
      });
    },
  },
};
