<template>
  <div class="card">
    <section class="eb-cart-total-section total_sections">
      <div class="eb-cart-cost-and-count-section">
        <span
          class="eb-cart-cost"
          :class="classesForCost"
        >
          {{ totalCostText }} руб.
        </span>
        <div class="count_block">
          <span class="eb-cart-count count_text">{{ totalCountText }}</span>
          <div v-if="showOneCostFirm">
            <br>
            <span class="cost-one-firm">Цена за компанию - {{ getOneFirmCost }} руб. </span>
          </div>
        </div>
      </div>

      <div class="benefits">
        <div class="speedometer">
          <span
            :style="speedometerArrowStyle"
            class="arrow"
          />
          <span class="circle" />
        </div>
        <span
          v-if="!isSubscriptionMode"
          class="discount"
        >Скидка {{ visibleDiscount }}%</span>

        <div
          v-if="(balanceBonusNotice && !isSubscriptionMode)"
          class="bonus_wrapper _popup _yandex_target_bonus_for_payment_popup"
          data-file="/api/popup/bonus_for_paid_order"
          data-method="GET"
        >
          <span
            v-if="bonusValue > 0"
            class="bonus-with-value"
          > {{ bonusValue }} кешбэк на баланс </span>
          <span
            v-else
            class="text"
          >+ бонусы на баланс</span>
          <img
            class="img"
            src="/dist/css/images/exp_question.png"
            alt="Бонус за оплату заказа"
          >
        </div>
      </div>
    </section>

    <section class="notifications_section">
      <slot name="notifications" />
    </section>

    <section class="eb-cart-buttons-section buttons_section">
      <span
        v-if="isSubscriptionMode"
        key="subscription"
        class="eb-button-prime button-subscription"
        @click="subscriptionButtonClick"
      >Оформить подписку
      </span>

      <template v-else>
        <span
          key="demo"
          class="eb-button-prime button-demo _download_demo_vue"
          @click="demoClick"
        >Скачать Демо
        </span>

        <span
          key="pay"
          class="eb-button-prime button-pay"
          :class="{'disabled' : !totalCost}"
          @click="downloadButtonClick"
        >Оплатить
        </span>
      </template>
    </section>
  </div>
</template>

<script>
import numeralize from 'numeralize-ru';

export default {
  name: 'Card',
  props: {
    totalCost: {
      type: Number,
      required: true,
    },

    totalCount: {
      type: Number,
      required: true,
    },

    visibleDiscount: {
      type: Number,
      default: 0,
    },

    relativeDiscount: {
      type: Number,
      required: false,
      default: 0,
    },

    withAngleMode: {
      type: Boolean,
      default: false,
      required: false,
    },

    balanceBonusNotice: {
      type: Boolean,
      default: false,
    },

    bonusValue: {
      type: Number,
      required: false,
      default: 0,
    },

    isSubscriptionMode: {
      type: Boolean,
      required: false,
    },

    subscriptionDeposit: {
      type: Number,
      required: false,
      default: 0,
    },

    isSubscriptionModeBalanceOnly: {
      type: Boolean,
      default: false,
    },

    balance: {
      type: Number,
      required: false,
      default: 0,
    },

    showOneCostFirm: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    speedometerArrowPosition() {
      if (!this.withAngleMode) {
        const MIN_DEG = -75;
        const MAX_DEG = 75;

        if (this.isSubscriptionMode) {
          return MAX_DEG;
        }
        return Math.floor(
          MIN_DEG + (MAX_DEG - MIN_DEG) * this.relativeDiscount,
        );
      }
      return this.relativeDiscount;
    },

    speedometerArrowStyle() {
      return { transform: `rotate(${this.speedometerArrowPosition}deg)` };
    },

    totalCostText() {
      if (
        this.isSubscriptionMode
          && this.isSubscriptionModeBalanceOnly
      ) {
        return `Баланс: ${this.formatNumber(this.balance)}`;
      }

      if (this.isSubscriptionMode) {
        return this.formatNumber(this.subscriptionDeposit);
      }

      return this.formatNumber(this.totalCost);
    },

    getTotalCost() {
      if (
        this.isSubscriptionMode
          && this.isSubscriptionModeBalanceOnly
      ) {
        return this.balance;
      }

      if (this.isSubscriptionMode) {
        return this.subscriptionDeposit;
      }

      return this.totalCost;
    },

    classesForCost() {
      return {
        'eb-cart-cost-warn': (this.totalCost === 0),
      };
    },

    totalCountText() {
      const formattedCnt = this.formatNumber(this.totalCount);
      if (this.isSubscriptionMode) {
        const text = numeralize.pluralize(this.totalCount, 'компанию', 'компании', 'компаний');
        return `Хватит на ${formattedCnt} ${text}`;
      }

      const text = numeralize.pluralize(this.totalCount, 'компания', 'компании', 'компаний');
      return `${formattedCnt} ${text}`;
    },

    subscriptionDisabled() {
      return (this.subscriptionDeposit === 0);
    },

    getOneFirmCost() {
      return (this.getTotalCost / (this.totalCount || 1)).toFixed(1);
    },
  },

  methods: {
    demoClick() {
      this.$emit('demoClick');
    },

    downloadButtonClick() {
      this.$emit('downloadButtonClick');
    },

    subscriptionButtonClick() {
      if (this.subscriptionDisabled) {
        window.echo('Нельзя оплатить заказ на 0 руб.');
      } else {
        this.$emit('subscriptionButtonClick');
      }
    },

    formatNumber(n) {
      return Number(n).toLocaleString(['ru', 'en']);
    },
  },
};
</script>

<style scoped src="../../../css/elements.css"></style>

<style scoped src="./Card.less"></style>
