<template>
  <div class="geo-selector">
    <span class="geo-selector__title">
      География выборки:
    </span>
    <div
      id="open_geo_selector"
      @click="openTree"
    >
      <ButtonSelector
        :content="getDescription"
        is-important-selector
        :class="{'selector-nothing-selected': nothingSelected & itIsTimeToShow}"
        width="200px"
        font-size="17px"
      />
    </div>
  </div>
</template>

<script>
import ButtonSelector from 'button-selector';
import { mapGetters, mapMutations, mapState } from 'vuex';

export default {
  name: 'GeoSelector',
  components: {
    ButtonSelector,
  },

  props: {
    itIsTimeToShow: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    ...mapState('geoTree', [
      'chosenSection',
      'loadingTree',
    ]),

    ...mapState('map', [
      'selectedCity',
      'coords',
    ]),

    ...mapGetters('geoTree', [
      'getSelectedCities',
      'getDescription',
    ]),

    nothingSelected() {
      return (this.chosenSection === 'city' && this.getSelectedCities.length === 0);
    },
  },

  updated() {
    if (window.location.hash === '#districts') {
      this.setChosenSection('city');
      this.setVisibleTree(true);
      window.location.hash = '';
    }
  },

  methods: {
    ...mapMutations('geoTree', [
      'setVisibleTree',
      'setChosenSection',
    ]),

    openTree() {
      if (this.loadingTree) return;
      this.setChosenSection('city');
      this.setVisibleTree(true);
    },
  },
};
</script>

<style scoped src="../../../assets/css/element.css"></style>

<style scoped>
  .geo-selector{
    display: flex;
    gap: 5px;
    flex-direction: column;
  }
  .geo-selector__title{
    font-weight: bold;
    font-size: 14px;
  }
</style>
