import DropdownSelectorUtil from './utils/DropdownSelectorUtil';
import MultiSelectorUtil from '../../common_components/MultiSelector/MultiSelectorUtil';

const getters = {
    getTruckLesseeSelected(state) {
        return (state.companyType.truck_lessee.included);
    },
    getCarLesseeSelected(state) {
        return (state.companyType.car_lessee.included);
    },
    getLesseeSelected(state) {
        return (state.companyType.lessee.included);
    },
    getTender44ParticipantSelected(state) {
        return (state.companyType.tender44_participant.included);
    },
    getTender44WinnerSelected(state) {
        return (state.companyType.tender44_winner.included);
    },
    getTender223ParticipantSelected(state) {
        return (state.companyType.tender223_participant.included);
    },
    getTender223WinnerSelected(state) {
        return (state.companyType.tender223_winner.included);
    },
    getSroSelected(state) {
        return (state.companyType.sro.included);
    },
    getSroMemberSelected(state) {
        return (state.companyType.sro_member.included);
    },
    getOnlineShopSelected(state) {
        return (state.companyType.online_shop.included);
    },
    getDealerSelected(state) {
        return (state.companyType.dealer.included);
    },
    getDistributorSelected(state) {
        return (state.companyType.distributor.included);
    },
    getManufacturerSelected(state) {
        return (state.companyType.manufacturer.included);
    },

    /**
     * Правовые формы без признака "в стадии ликвидации"
     * @param state
     * @returns Object
     */
    getLegalFormRealOnly(state) {
        const onlyReal = {};
        const { legalForm } = state;
        for (const key in legalForm) {
            if (key === 'closed') {
                continue;
            }
            onlyReal[key] = legalForm[key];
        }
        return onlyReal;
    },
    /**
     * Признак "в стадии ликвидации"
     * @param state
     * @returns {*}
     */
    getLegalFormClosedOnly(state) {
        return state.legalForm.closed;
    },

    getSelectedLegalFormAsArray(state, getters) {
        return DropdownSelectorUtil.optionsToIdsList(getters.getLegalFormRealOnly, state.unknownLegalFormIncluded);
    },
    getIsLegalForm(state, getters) {
        return (DropdownSelectorUtil.somethingIncluded(getters.getLegalFormRealOnly, state.unknownLegalFormIncluded)) ? '1' : '0';
    },
    getSelectedLegalForm(state, getters) {
        return getters.getSelectedLegalFormAsArray;
    },
    getIsCompanyType(state) {
        return DropdownSelectorUtil.somethingIncluded(state.companyType, false) ? '1' : '0';
    },
    getSelectedCompanyType(state) {
        const result = [];
        for (const key in state.companyType) {
            if (state.companyType[key].included) {
                result.push(key);
            } else if (state.companyType[key].excluded) {
                result.push(`no-${key}`);
            }
        }

        return result;
    },
    getSelectedCompanySizeAsArray(state) {
        return DropdownSelectorUtil.optionsToIdsList(state.companySize, state.unknownCompanySizeIncluded);
    },
    getIsCompanySize(state) {
        return (DropdownSelectorUtil.somethingIncluded(state.companySize, state.unknownCompanySizeIncluded)) ? '1' : '0';
    },
    getSelectedCompanySize(state, getters) {
        return getters.getSelectedCompanySizeAsArray;
    },

    getShowMainOkved(state) {
        return String(state.mainOkved);
    },
    getShowRegistrationDate(state) {
        return String(state.showRegistrationDate);
    },
    getShowIncAndProfit(state) {
        return String(state.showIncAndProfit);
    },
    getShowKppOkpo(state) {
        return String(state.showKppOkpo);
    },
    getShowLegalName(state) {
        return String(state.showLegalName);
    },
    getShowSiteDescription(state) {
        return String(state.showSiteDescription);
    },
    getOnlyWithoutSite(state) {
        return String(state.withoutSite);
    },
    getOnlyMobilePhone(state) {
        const subFilter = state.mainBasePhoneFilter.subFilters.find((filter) => filter.name === 'only_mobile_phones');
        return subFilter.selected;
    },
    getHasSitePhone(state) {
        const subFilter = state.mainBasePhoneFilter.subFilters.find((filter) => filter.name === 'has_site_phone');
        return subFilter.selected;
    },
    getHasMessenger(state) {
        const subFilter = state.mainBasePhoneFilter.subFilters.find((filter) => filter.name === 'has_messenger');
        return subFilter.selected;
    },
    getOnlyWithLicense(state) {
        return String(state.hasLicense);
    },
    getClosed(state) {
        return state.legalForm.closed
            ? {
                included: state.legalForm.closed.included,
                excluded: state.legalForm.closed.excluded,
            }
            : {
                included: true,
                excluded: false,
            };
    },

    getGroupIds(state) {
        return Object.keys(state.companyTypeGroups);
    },

    getSelectedBasicFilters(state) {
        return state.selectedBasicFilters;
    },

    hasSite(state) {
        const filters = state.selectedBasicFilters;
        return filters.includes('site');
    },

    getRequiredFilters: (state) => state
        .selectedBasicFilters
        .filter((filter) => filter !== 'post_index' && !state.selectedOptionalFilters.includes(filter)),

    getMainBaseDataForCalculationRequest(state, getters) {
        const {
            'geoTree/getSelectedGeoUnits': selectedGeoUnits,
            'rubricsTree/getSelectedSections': selectedSections,
            getFilterConfig: filterConfig,
            getAdditionalOptionConfig: additionalOptionConfig,
        } = getters;

        return {
            plan: JSON.stringify({
                geography: selectedGeoUnits,
                subsections: selectedSections,
            }),
            filter_config: JSON.stringify(filterConfig),
            additional_option_config: JSON.stringify(additionalOptionConfig),
        };
    },

    getMainBaseDataForCreateRequest(state, getters) {
        const {
            'geoTree/getSelectedGeoUnits': selectedGeoUnits,
            'rubricsTree/getSelectedSections': selectedSections,
            getFilterConfig: filterConfig,
            getAdditionalOptionConfig: additionalOptionConfig,
        } = getters;

        return {
            plan: {
                geography: selectedGeoUnits,
                subsections: selectedSections,
            },
            filter_config: filterConfig,
            additional_option_config: additionalOptionConfig,
        };
    },

    isEmailVerifiedSelected(state) {
        if (!state.mainBaseEmailFilter.selected) {
            return false;
        }
        const subFilter = state.mainBaseEmailFilter.subFilters.find((filter) => filter.name === 'email_verified');
        return subFilter ? subFilter.selected : false;
    },

    selectedRegYears(state) {
        return MultiSelectorUtil.getSelectedKeys(state.regYears, 'key');
    },

    selectedFloorTypeText(state) {
        const includedFloors = state.floors.filter((floor) => floor.included);
        const excludedFloors = state.floors.filter((floor) => floor.excluded);
        const totalSelectedFloors = includedFloors.length + excludedFloors.length;

        if (totalSelectedFloors === 0) {
            return 'Все этажи';
        }

        if (totalSelectedFloors === 1) {
            if (includedFloors.length === 1) {
                return `Включить ${includedFloors[0].title}`;
            }
            return `Исключить ${excludedFloors[0].title}`;
        }

        return `${totalSelectedFloors} шт.`;
    },

    isRegistrationDate(state) {
        const regYears = MultiSelectorUtil.getSelectedKeys(state.regYears, 'key');
        return regYears.length > 0 ? '1' : '0';
    },

    isFloor(state) {
        return state.floors.some((floor) => floor.included || floor.excluded) ? '1' : '0';
    },

    getSelectedFloors(state) {
        const selectedFloors = [];

        state.floors.forEach((floor) => {
            if (floor.included) {
                selectedFloors.push(floor.id);
            }
            if (floor.excluded) {
                selectedFloors.push(`no-${floor.id}`);
            }
        });

        return selectedFloors;
    },

    isEmptyCell(state) {
        return state.selectedOptionalFilters.length > 0 ? 1 : 0;
    },

    isAdvancedExportFilter: (state) => {
        // Если все фильтры необязательные, возвращаем 0
        if (state.selectedBasicFilters.every((filter) => state.selectedOptionalFilters
            .includes(filter))) {
            return 0;
        }

        // Определяем обязательные фильтры
        const requiredFilters = state.selectedBasicFilters
            .filter((filter) => !state.selectedOptionalFilters.includes(filter));
        // Если все фильтры обязательные, возвращаем 0
        if (requiredFilters.length === state.selectedBasicFilters.length) {
            return 0;
        }
        // Если хотя бы один фильтр (но не все) необязательный, возвращаем 1
        return 1;
    },

    getFilterConfig(state, getters) {
        return {
            basic: getters.getSelectedBasicFilters,
            empty_cell: getters.isEmptyCell,
            advanced_export_filter: getters.isAdvancedExportFilter,
            required_fields: getters.getRequiredFilters,
            registration_date_year: getters.selectedRegYears,
            company_type: getters.getSelectedCompanyType,
            registration_form: getters.getSelectedLegalForm,
            floor: getters.getSelectedFloors,
            without_site: getters.getOnlyWithoutSite,
            has_license: getters.getOnlyWithLicense,
            phone: {
                unique: state.mainBasePhoneFilter.selected ? 1 : 0,
                mobile: getters.getOnlyMobilePhone ? 1 : 0,
                has_site_phone: getters.getHasSitePhone ? 1 : 0,
                has_messenger: getters.getHasMessenger ? 1 : 0,
            },
            email: {
                unique: state.mainBaseEmailFilter.selected ? 1 : 0,
                validated: getters.isEmailVerifiedSelected ? 1 : 0,
            },
            company_size: getters.getSelectedCompanySize,
            city_millionaires: '0',
            is_registration_date: getters.isRegistrationDate,
            is_floor: getters.isFloor,
            is_registration_form: getters.getIsLegalForm,
            is_company_type: getters.getIsCompanyType,
            is_company_size: getters.getIsCompanySize,
            closed: getters.getClosed,
            include_main_rubric: state.rubricsTree.includeMainRubricFilter.chosen ? '1' : '0',
            districts: getters['geoTree/getSelectedDistricts'],
        };
    },

    getAdditionalOptionConfig(state, getters) {
        return {
            highlight_new_companies: '0',
            show_registration_date: getters.getShowRegistrationDate,
            main_okved: getters.getShowMainOkved,
            social_media: getters.hasSite ? '1' : '0',
            ceo_sex: '0',
            site_description: getters.getShowSiteDescription,
            show_salary: getters.getShowIncAndProfit,
            show_profit: getters.getShowIncAndProfit,
            show_kpp_okpo: getters.getShowKppOkpo,
            show_legal_name: getters.getShowLegalName,
        };
    },

    getMainConfigOfferData(state, getters) {
        const offerData = {
            type: 'main',
        };

        const filter = {
            basic: getters.getSelectedBasicFilters,
            closed: getters.getClosed,
            districts: getters['geoTree/getSelectedDistricts'],
            phone: {
                unique: state.mainBasePhoneFilter.selected ? 1 : 0,
                mobile: getters.getOnlyMobilePhone ? 1 : 0,
                has_site_phone: getters.getHasSitePhone ? 1 : 0,
                has_messenger: getters.getHasMessenger ? 1 : 0,
            },
            additional: [],
            export_filter: getters.isEmptyCell ? '1' : '0',
        };

        offerData.filter = JSON.stringify(filter);

        offerData.cities = JSON.stringify(getters['geoTree/getSelectedGeoUnitsForLegacyKp']);
        offerData.plan = JSON.stringify(getters['rubricsTree/getSelectedSectionsForLegacyKp']);

        offerData.cost = getters['summary/totalCost'];
        offerData.count = getters['summary/totalCount'];

        // Добавляем остальные необходимые свойства
        offerData.empty_cell = getters.isEmptyCell ? '1' : '0';
        offerData.advanced_export_filter = getters.isAdvancedExportFilter ? '1' : '0';
        offerData.required_fields = getters.getRequiredFilters;

        return offerData;
    },

    getDataForCommercialOffer(state, getters) {
        return {
            cashless_payment_config: JSON.stringify(getters['mainBasePayPopup/getBeznalData']),

            calculation_config: JSON.stringify({
                filter_config: getters.getFilterConfig,
                additional_option_config: getters.getAdditionalOptionConfig,
            }),

            saved_email: '',
        };
    },

    isPartiallySelectPossible(state, getters) {
        const selectedFilters = getters.getSelectedBasicFilters.filter((filter) => filter !== 'post_index');
        return selectedFilters.length > 1;
    },

    isUniqueCalculation(state, getters) {
        return ['phone', 'email'].some((filter) => getters.getRequiredFilters.includes(filter));
    },
};

export default getters;
