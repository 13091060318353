import { NodeValue, Tree } from 'tree';
import api from '../../api/index';

export default {
    namespaced: true,

    state: {
    /**
     * @var {Tree}
     */
        tree: {},

        searchString: '',

        /**
     * @var {Tree}
     */
        searchTree: {},

        visibleTree: false,

        includeMainRubricFilter: {
            name: 'main_rubric',
            title: 'Основной вид деятельности',
            chosen: false,
        },

        loadingRubrics: false,
    },

    getters: {
        getTree(state) {
            return state.tree;
        },

        getSearchTree(state) {
            return state.searchTree;
        },

        isSearchMode(state) {
            return (state.searchString.length >= 3);
        },

        emptySearchResult(state) {
            return state.searchTree.isEmpty();
        },

        getSelectedSections(state) {
            if (Object.keys(state.tree).length === 0) return [];

            return state.tree.getChosenNodesFromLevel(3)
                .map((section) => section.value.obj.id);
        },

        getSelectedSectionsName(state) {
            if (Object.keys(state.tree).length === 0) return [];

            return state.tree.getChosenNodesFromLevel(3)
              .map((section) => section.value.obj.name);
        },

        getSelectedSectionsForLegacyKp(state) {
            if (Object.keys(state.tree).length === 0) return [];

            const subsections = state.tree.getChosenNodesFromLevel(3)
                .map(section => section.value.obj);

            const uniqueSectionsIds = subsections
                .filter((element, index) => subsections.indexOf(element) === index)
                .map(subsection => subsection.id_section);

            const result = {};

            uniqueSectionsIds.forEach((id) => {
                result[id] = subsections
                    .filter(subsection => subsection.id_section === id)
                    .map(subsection => subsection.id);
            });

            return result;
        },
    },

    mutations: {
        resetSearch(state) {
            state.searchString = '';
        },

        setSearchString(state, searchString) {
            state.searchString = searchString;
        },

        expandRubricNode: (state, nodeDesc) => {
            state.tree.expandNode(nodeDesc.level, nodeDesc.id);
        },

        runSearch(state) {
            state.searchTree = state.tree.filterTreeByNodeName(state.searchString);
        },

        setTree(state, {sections, subsections}) {
            const tree = new Tree(3);

            // корень
            const nodeValue = new NodeValue(
                { id: 1, name: 'Выбрать все рубрики' },
                1,
                true,
                false,
                false,
                true,
            );
            tree.addNodeByParentId(nodeValue, 0);

            // рубрики
            sections.forEach((section) => {
                const nodeValue = new NodeValue(
                    section,
                    2,
                    false,
                    false,
                    false,
                    false,
                );
                tree.addNodeByParentId(nodeValue, 1);
            });

            // подрубрики
            subsections.forEach((subsection) => {
                const nodeValue = new NodeValue(subsection, 3);
                tree.addNodeByParentId(nodeValue, subsection.id_section);
            });

            state.tree = tree;
        },

        expandRubricSearchNode: (state, nodeDesc) => {
            state.searchTree.expandNode(nodeDesc.level, nodeDesc.id);
        },

        syncSearchTree: (state) => {
            state.searchTree.copyStateOfChoice(state.searchTree.root, state.tree);
        },

        chooseRubricNode: (state, nodeDesc) => {
            state.tree.chooseNode(nodeDesc.level, nodeDesc.id);
        },

        chooseOrCancelTree: (state, choice) => {
            state.tree.selectOrDropAllNodes(choice);
        },

        chooseOrCancelSearchTree(state, choice) {
            state.searchTree.selectOrDropAllNodes(choice);
        },

        setVisibleTree(state, value) {
            state.visibleTree = value;
        },

        toggleIncludeMainRubricFilter(state) {
            state.includeMainRubricFilter.chosen = !state.includeMainRubricFilter.chosen;
        },

        setLoadingRubrics(state, value) {
            state.loadingRubrics = value;
        },
    },

    actions: {
        async setRubricsTree({ commit }) {
            commit('setLoadingRubrics', true);
            const res = await api.getRubrics();
            await commit('setTree', res);
            commit('setLoadingRubrics', false);
        },

        updateSearch: ({ commit, getters }, searchString) => {
            commit('setSearchString', searchString);
            if (getters.isSearchMode) {
                commit('runSearch');
            }
        },

        chooseRubricNode({ commit }, nodeDesc) {
            commit('chooseRubricNode', nodeDesc);
            commit('runSearch');
        },

        chooseRubricSearchNode: ({ commit }, nodeDesc) => {
            commit('chooseRubricNode', nodeDesc);
            commit('syncSearchTree');
        },

        toggleIncludeMainRubricFilter({ commit, dispatch }) {
            commit('toggleIncludeMainRubricFilter');
        },

        chooseRubrics({ commit }, rubrics) {
            commit('chooseOrCancelTree', false);

            rubrics.forEach((id) => {
                commit('chooseRubricNode', { level: 3, id });
            });
        },
    },
};
