<template>
  <div
    class="required-options"
    @click="handleClick"
  >
    <GeoSelector :it-is-time-to-show="itIsTimeToShow" />
    <RubricsSelector :it-is-time-to-show="itIsTimeToShow" />

    <NotificationComponent
      v-if="showNotification && itIsTimeToShow && !showLinkRubricNotification"
      class="required-options-notification"
      display-mode="overlay"
    >
      <p>
        Сначала выберите нужные <b>города и рубрики</b>, затем настройте другие фильтры справа
      </p>
    </NotificationComponent>

    <NotificationComponent
      v-if="showLinkRubricNotification && itIsTimeToShow"
      class="required-options-notification"
      is-highlighted
      display-mode="overlay"
    >
      <p>
        Выбрана рубрика <b>{{ rubricName }}</b>. Вы можете выбрать дополнительные рубрики, настроить города и фильтры.
      </p>
    </NotificationComponent>

    <RubricsTree v-if="visibleTree" />
    <GeoTree v-if="visibleGeoTree" />
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import RubricsTree from '@/main_base/components/RubricsTree/RubricsTree.vue';
import RubricsSelector from '@/main_base/components/RubricsSelector/RubricsSelector.vue';
import GeoSelector from '@/main_base/components/GeoSelector/GeoSelector.vue';
import GeoTree from '@/main_base/components/GeoTree/GeoTree.vue';
import NotificationComponent from '@/common_components/NotificationComponent/NotificationComponent.vue';

export default {
  name: 'RequiredOptions',
  components: {
    NotificationComponent,
    GeoTree,
    GeoSelector,
    RubricsSelector,
    RubricsTree,
  },

  setup() {
    const store = useStore();
    const showNotification = ref(true);
    const showLinkRubricNotification = ref(false);
    const rubricName = ref('');
    const itIsTimeToShow = ref(false);

    const visibleTree = computed(() => store.state.rubricsTree.visibleTree);
    const visibleGeoTree = computed(() => store.state.geoTree.visibleTree);
    const getSelectedSectionsName = computed(() => store.getters['rubricsTree/getSelectedSectionsName']);

    const setRubricsTree = () => store.dispatch('rubricsTree/setRubricsTree');
    const setGeoTree = () => store.dispatch('geoTree/setGeoTree');
    const setDistricts = () => store.dispatch('geoTree/setDistricts');
    const chooseRubrics = (rubrics) => store.dispatch('rubricsTree/chooseRubrics', rubrics);

    onMounted(() => {
      Promise.all([setRubricsTree(), setGeoTree(), setDistricts()]).then(() => {
        setTimeout(() => {
          itIsTimeToShow.value = true;
        }, 3000);
      });

      const urlParams = new URLSearchParams(window.location.search);
      const sId = urlParams.get('sId');
      if (sId && !Number.isNaN(sId)) {
        chooseRubrics([Number(sId)]);
        showLinkRubricNotification.value = true;
        [rubricName.value] = getSelectedSectionsName.value;
        urlParams.delete('sId');
        window.history.replaceState(null, '', window.location.origin);
      }
    });

    const handleClick = () => {
      showNotification.value = false;
      showLinkRubricNotification.value = false;
    };

    return {
      showNotification,
      showLinkRubricNotification,
      rubricName,
      itIsTimeToShow,
      visibleTree,
      visibleGeoTree,
      handleClick,
    };
  },
};
</script>

<style scoped>
.required-options {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.required-options-notification {
  z-index: 3;
  top: 115px;
  left: 0;
}
</style>
