<template>
  <div>
    <span class="notification_left_icon_wrapper">
      <img
        class="notification_left_icon"
        src="/dist/css/images/icon_warning.png"
        alt=""
      >
    </span>
    <span class="notification_after_icon_text">
      В нашей базе данные об <b>ИНН</b> присутствуют, в основном, у компаний из России.
    </span>
  </div>
</template>

<script setup>

</script>

<style scoped>
.notification_left_icon_wrapper {
  display: inline-block;
  float: left;
  margin-right: 2%;
  width: 8%;
}

.notification_left_icon {
  width: 100%;
}
</style>
