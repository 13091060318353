import { createApp } from 'vue';
import initSentry from '@/vue_sentry';
import MainBaseConfigurator from './components/MainBaseConfigurator/MainBaseConfigurator.vue';
import store from './store/store';

const el = document.getElementById('ssr-main-base');
if (el) {
    const app = createApp(MainBaseConfigurator);
    initSentry(app);
    app.use(store);
    window.vue_main_base_configurator = app.mount('#ssr-main-base');
}
