<template>
  <div v-if="count > 0">
    <NotificationComponent
      class="card-notification__body"
      flipper-position="without-flipper"
    >
      <ForeignInnBalloon
        v-if="baloonInfo.foreignInn"
      />
      <EntrepreneurInnOgrnLprBalloon
        v-else-if="baloonInfo.entrepreneurInnOgrnLpr"
      />
      <NoBasicFiltersBaloon
        v-else-if="baloonInfo.noBasicFilters"
      />
      <span
        v-else
        class="filter_baloons"
      >
        <UniquenessPhoneOrEmailBalloon
          v-if="baloonInfo.uniqueConfig.appliedToEmail || baloonInfo.uniqueConfig.appliedToPhone"
          :applied-to-email="baloonInfo.uniqueConfig.appliedToEmail"
          :applied-to-phone="baloonInfo.uniqueConfig.appliedToPhone"
        />
        <IncompleteContactsEnabledBalloon
          v-if="baloonInfo.incompleteContactsEnabled"
          :filled-field-example="baloonInfo.filledFieldExample"
          :not-filled-field-example="baloonInfo.notFilledFieldExample"
        />
        <IncompleteContactsDisabledBalloon
          v-else
          :applied-to-email="baloonInfo.uniqueConfig.appliedToEmail"
          :applied-to-phone="baloonInfo.uniqueConfig.appliedToPhone"
          :incomplete-contacts-allowed="baloonInfo.incompleteContactsAllowed"
          :single-field-and-filled="baloonInfo.singleFieldAndFilled"
          :field-names-as-string="baloonInfo.fieldNamesAsString"
        />

        <MobilePhoneCheckboxBalloon
          v-if="baloonInfo.phoneOptions"
          @change-sub-filter="changePhoneSubFilter"
        />
        <EmailValidatedCheckboxBalloon
          v-if="baloonInfo.emailOptions"
          @change-sub-filter="changeEmailSubFilter"
        />

        <AdditionalFilterCountBalloon
          v-if="baloonInfo.extraFiltersCount > 0"
          :extra-filters-count="baloonInfo.extraFiltersCount"
        />
        <CashlessNotificationBalloon
          v-if="cost > 3000"
          @open-cashless="$emit('openCashless')"
        />
      </span>
    </NotificationComponent>
  </div>
</template>

<script>

import { mapActions, mapState } from 'vuex';
import UniquenessPhoneOrEmailBalloon from './baloons/UniquenessPhoneOrEmailBalloon.vue';
import NotificationComponent from '../../../../common_components/NotificationComponent/NotificationComponent.vue';
import IncompleteContactsEnabledBalloon from './baloons/IncompleteContactsEnabledBalloon.vue';
import IncompleteContactsDisabledBalloon from './baloons/IncompleteContactsDisabledBalloon.vue';
import MobilePhoneCheckboxBalloon from './baloons/MobilePhoneCheckboxBalloon.vue';
import EmailValidatedCheckboxBalloon from './baloons/EmailValidatedCheckboxBalloon.vue';
import AdditionalFilterCountBalloon from './baloons/AdditionalFilterCountBalloon.vue';
import CashlessNotificationBalloon from './baloons/CashlessNotificationBalloon.vue';
import NoBasicFiltersBaloon from './baloons/NoBasicFiltersBaloon.vue';
import ForeignInnBalloon from './baloons/ForeignInnBalloon.vue';
import EntrepreneurInnOgrnLprBalloon from './baloons/EntrepreneurInnOgrnLprBalloon.vue';

export default {
  name: 'MainBaseNotificationList',

  components: {
    EntrepreneurInnOgrnLprBalloon,
    ForeignInnBalloon,
    NoBasicFiltersBaloon,
    CashlessNotificationBalloon,
    AdditionalFilterCountBalloon,
    EmailValidatedCheckboxBalloon,
    MobilePhoneCheckboxBalloon,
    IncompleteContactsDisabledBalloon,
    IncompleteContactsEnabledBalloon,
    NotificationComponent,
    UniquenessPhoneOrEmailBalloon,
  },

  emits: ['openCashless'],

  computed: {
    ...mapState([
      'baloonInfo',
    ]),

    ...mapState('summary', [
      'count',
      'cost',
    ]),
  },

  methods: {

    ...mapActions([
      'changeMainBasePhoneSubFilter',
      'changeMainBaseEmailVerifiedSubFilter',
    ]),

    changePhoneSubFilter() {
      this.changeMainBasePhoneSubFilter('only_mobile_phones');
    },

    changeEmailSubFilter() {
      this.changeMainBaseEmailVerifiedSubFilter();
    },
  },

};
</script>

<style scoped>
.with-warn-icon {
  display: inline-block;
  padding-left: 35px;
  background-image: url("/dist/css/images/icon_warning.png");
  background-repeat: no-repeat;
  background-size: 27px;
  background-position: left center;
}

.card-notification__body {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.filter_baloons {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

</style>
