import axios from 'axios';

export default {
    createOrder(data) {
        return fetch('/api/main_base/payment_main_base', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((res) => res.json())
            .catch(() => echo(
                'Произошла ошибка! Просим связаться с тех. поддержкой по тел. 8 800 775-29-12 '
            + 'или написать на info@export-base.ru',
                6000,
                'error',
            ));
    },

    getUserData() {
        return fetch('/api/authorized_client_data', { method: 'GET' })
            .then((res) => res.json());
    },

    checkAuth() {
        return fetch('api/client_is_authenticated', { method: 'GET' })
            .then((res) => res.json());
    },

    getUserDiscount(email) {
        return fetch('/handlers/email_discount.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
                email,
            }),
        })
            .then((res) => res.json())
            .catch(() => {
                echo(
                    'Произошла ошибка! Пожалуйста, обратитесь в тех. поддержку: 8 (800) 775 - 29 - 12 (доб. 1)',
                    6000,
                    'error',
                );
            });
    },

    createDemoLink(data) {
        return fetch('/api/main_base/create_demo', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(
                data,
            ),
        })
            .then((res) => res.json())
            .catch(() => {
                echo(
                    'Произошла ошибка! Пожалуйста, обратитесь в тех. поддержку: 8 (800) 775 - 29 - 12 (доб. 1)',
                    6000,
                    'error',
                );
            });
    },

    async calculateUnique(configuration) {
        try {
            const response = await axios.post('/api/main_base/calculate_unique', configuration, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.data == null) {
                echo(
                    'Данные не получены! Пожалуйста, попробуйте еще раз.',
                    6000,
                    'error',
                );
                return null;
            }
            return response.data;
        } catch (error) {
            echo(
                'Произошла ошибка! Пожалуйста, обратитесь в тех. поддержку: 8 (800) 775 - 29 - 12 (доб. 1)',
                6000,
                'error',
            );
            return null;
        }
    },

    async calculateNotUnique(configuration) {
        try {
            const response = await axios.post('/api/main_base/calculate_not_unique', configuration, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.data == null) {
                echo(
                    'Данные не получены! Пожалуйста, попробуйте еще раз.',
                    6000,
                    'error',
                );
                return null;
            }
            return response.data;
        } catch (error) {
            echo(
                'Произошла ошибка! Пожалуйста, обратитесь в тех. поддержку: 8 (800) 775 - 29 - 12 (доб. 1)',
                6000,
                'error',
            );
            return null;
        }
    },

    async makeConfigOffer(offerData) {
        try {
            const params = new URLSearchParams();
            params.append('data', JSON.stringify(offerData.data));
            params.append('data_for_payment', JSON.stringify(offerData.data_for_payment));

            return await axios.post('/create_comm_offer.php', params);
        } catch (error) {
            echo(
                'Произошла ошибка! Пожалуйста, обратитесь в тех. поддержку: 8 (800) 775 - 29 - 12 (доб. 1)',
                6000,
                'error',
            );

            // Обработка ошибки с кодом 403
            if (error.response && error.response.status === 403) {
                location.reload();
            }

            return null;
        }
    },

};
