<template>
  <div class="additionalFiltersWrapper">
    <div
      class="additionalFiltersHeader"
      title="Настройте доп. фильтры у вашей базы"
      @click="toggleExpandAdditionalFilters"
    >
      <div class="sepparate_word">
        Дополнительные фильтры
      </div>
      <img
        src="/images/arrow_down.svg"
        :class="{'arrow-up': expandAdditionalFilters}"
        alt="arrow"
        class="arrow-img"
      />
      <div class="clr" />
    </div>
    <div
      v-show="expandAdditionalFilters"
      class="additionalFiltersBody"
    >
      <AdditionalOptions />
      <div class="AdditionalFilters__second_row">
        <RegistrationFilter />
        <CompanyLocation />
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import AdditionalOptions from './components/AdditionalOptions.vue';
import RegistrationFilter from './components/RegistrationFilter.vue';
import CompanyLocation from './components/CompanyLocation.vue';

export default {
  name: 'MainBaseAdditionalFilters',
  components: {
    CompanyLocation,
    RegistrationFilter,
    AdditionalOptions,
  },

  setup() {
    const store = useStore();

    const expandAdditionalFilters = computed(() => store.state.expandAdditionalFilters);

    const toggleExpandAdditionalFilters = () => {
      store.commit('toggleExpandAdditionalFilters');
    };

    return {
      expandAdditionalFilters,
      toggleExpandAdditionalFilters,
    };
  },
};
</script>

<style scoped>
.additionalFiltersHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #353535;;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
}

.additionalFiltersBody {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

.AdditionalFilters__second_row {
  display: flex;
  gap: 10px;
}

.sepparate_word {
  width: 25%;
}

.arrow-img {
  transition: transform 0.3s ease;
}

.arrow-up {
  transform: rotate(180deg);
}
</style>
