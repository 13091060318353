// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/icon_query.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.subFilter-list[data-v-236f6ee2] {\n  display: flex;\n  flex-direction: column;\n  list-style-type: disc;\n}\n.subFilter-row[data-v-236f6ee2] {\n  display: flex;\n  flex-direction: row;\n  width: 200px;\n  align-items: center;\n  gap: 5px;\n}\n.explanation[data-v-236f6ee2] {\n  width: 17px;\n  height: 17px;\n  margin-left: 3px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") center center no-repeat;\n  cursor: pointer;\n}\n\n", ""]);
// Exports
module.exports = exports;
