<template>
  <div class="text_top">
    Вы не выбрали фильтры в конфигураторе.
    <br>
    В выгрузку попадет только название компании, отрасль <br> и город, в котором находится компания.
    <br><br>
    Чтобы добавить данные, <b>воспользуйтесь фильтрами</b>.
  </div>
</template>

<script>
export default {
  name: 'NoBasicFiltersBaloon',
};
</script>
