<template>
  <div @click="toggleName" class="static_filter">
    <div class="checkbox-wrapper static_filter_checkbox-wrapper">
      <div class="toggle">
        <span class="circle"></span>
      </div>
      <div class="static_filter_text">
        <div class="green_background">
          Название компании
        </div>
        <span class="more_fields_tooltip_title">{{ moreFieldsText }}</span>
        <ul class="more_fields_tooltip_ul">
          <li
            v-for="field in fields"
            :key="field"
          >
            {{ field }}
          </li>
        </ul>
      </div>
    </div>
    <div class="clear"></div>
  </div>
</template>

<script>
import plural from 'plural-ru';

export default {
  name: 'StaticMainBaseFilter',
  data() {
    return {
      fields: [
        'Тип компании',
        'Город',
        'Регион',
        'Район города',
        'Федеральный округ',
        'Рубрика',
        'Подрубрика',
        'Тип подрубрики',
        'Координаты(x, y)',
        'Часы работы компании по местному времени',
        'Часовой пояс',
        'Заголовок сайта (title)',
        'Рейтинг компании в Интернете',
      ],
    };
  },

  methods: {
    toggleName() {
      echo('Данную опцию нельзя отключить');
    },
  },

  computed: {
    moreFieldsText() {
      return `и еще ${this.fields.length} ${plural(this.fields.length, 'поле', 'поля', 'полей')}`;
    },
  },
};
</script>

<style scoped>
.static_filter {
  margin-bottom: 12px;
}

.static_filter  .green_background {
  border-radius: 3px;
  color: #353535;
  font-weight: 700 !important;
  padding: 3px 5px;
  width: 115px;
}

.static_filter_text {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px
}

.more_fields_tooltip_title {
  color: rgba(0, 0, 0, 0.7);
  border-bottom: 1px dashed;
}

.more_fields_tooltip_title:hover {
  cursor: pointer;
}

.more_fields_tooltip_ul {
  display: none;
  position: absolute;
}

.static_filter_checkbox-wrapper {
  float: left;
  cursor: pointer;
  padding: 0 2px;
}

.static_filter_checkbox-wrapper .toggle .circle {
  transform: translate(20px, -50%);
  box-shadow: 0 3px 8px rgba(79, 46, 220, 0.2);
}

.static_filter_checkbox-wrapper .toggle:before {
  background: var(--color-button-prime-hover);
}

.more_fields_tooltip_title:hover + .more_fields_tooltip_ul {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 7px;
  z-index: 100;
  border: 1px solid #999;
  border-radius: 4px;
  color: #353535;
  background: var(--notification-color);
  font-size: 13px;
  line-height: 1.2;
  width: 180px;
  padding: 10px;
  transform: translateY(56%);
}

.more_fields_tooltip_ul li {
  padding-left: 16px;
  background: url("/dist/css/images/tick_small.png") no-repeat left center;
}

@media screen and (min-width:800px) and (max-width:1050px){
  .static_filter_text{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    top: 17px;
    margin-bottom: 13px;
  }

  .more_fields_tooltip_title:hover + .more_fields_tooltip_ul {
    transform: translateY(14%) translateX(-12%);
  }
}

</style>
