<template>
  <div class="row-relevant">
    <p class="row-relevant__description">
      Актуальность базы:
    </p>
    <p class="row-relevant__value">
      95-98%
    </p>
  </div>
</template>

<script>
export default {
  name: 'RowBase',
};
</script>

<style>
.row-relevant {
  font-weight: bold;
  display: grid;
  grid-template-columns: 130px 1fr;
  place-items: center start;
}

.row-relevant__value {
  font-weight: normal;
  color: #629a00;
}
</style>
