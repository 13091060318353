<template>
  <div
    :class="{ 'text_bottom__bold': isMobilePhoneSelected }"
    class="checbox_with_instruction"
  >
    <CheckBox
      id="only_mobile_phones"
      title="Только мобильные телефоны"
      :chosen="isMobilePhoneSelected"
      @change="toggleMobilePhoneFilter"
    />
    <span
      class="instruction popup"
      data-file="api/popup/main_only_mobile_instruction"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CheckBox from 'checkbox';

export default {
  name: 'MobilePhoneCheckboxBalloon',
  components: {
    CheckBox,
  },

  emits: ['changeSubFilter'],

  computed: {
    ...mapState([
      'mainBasePhoneFilter',
    ]),

    isMobilePhoneSelected() {
      const mobilePhoneFilter = this.mainBasePhoneFilter.subFilters.find(
        (filter) => filter.name === 'only_mobile_phones',
      );
      return mobilePhoneFilter ? mobilePhoneFilter.selected : false;
    },
  },

  methods: {
    toggleMobilePhoneFilter() {
      this.$emit('changeSubFilter');
    },
  },
};
</script>

<style scoped>
.text_bottom__bold {
  font-weight: bold;
}

.instruction {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-image: url('/dist/css/images/exp_question.png');
  background-size: contain;
  margin-left: 5px;
  cursor: pointer;
}

.checbox_with_instruction {
  display: flex;
  align-items: center;
}
</style>
