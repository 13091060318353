<template>
  <div class="okveds" @click="clickOkveds">
    <p class="okveds__title">
      Возможно вы искали следующие ОКВЭДы:
    </p>
    <ul class="okveds-list">
      <li v-for="(okved, index) in okvedsList" class="okveds-item" :key="index">
        <a
          :href="'/okved?okvedCode=' + okved.code"
          class="okveds-item__href"
          target="_blank"
        >
          {{ okved.code }} — {{ okved.name }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: 'OkvedsList',

  computed: {
    ...mapState('similarRubricsTree', [
        'okvedsList',
    ]),
  },

  methods: {
    clickOkveds() {
      try {
        window.yaCounter23743225.reachGoal('tree-okveds-list');
      } catch (err) {
        console.groupCollapsed('ERROR Yandex-metric:');
        console.log(err);
        console.groupEnd();
      }
    },
  },
}
</script>

<style scoped>
.okveds__title{
  font-weight: bold;
  margin: 20px 0 10px 0;
}

.okveds-list{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.okveds-item__href{
  text-decoration: none;
}
</style>