<template>
  <div>
    Можно оплатить от организации по
    <span 
      class="dashed_text"
      @click="$emit('openCashless')"
    >
      безналичному расчету
    </span>
  </div>
</template>
<script>
export default {
  name: 'CashlessNotificationBalloon',
  emits: ['openCashless'],
}
</script>


<style scoped>
.dashed_text {
  border-bottom: 1px dashed !important;
  cursor: pointer;
}
</style>
