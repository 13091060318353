<template>
  <div
    class="static_filter"
    @click="toggleName"
  >
    <div class="checkbox-wrapper static_filter_checkbox-wrapper">
      <div class="toggle">
        <span class="circle" />
      </div>
      <div class="static_filter_text">
        <div class="green_background">
          Название компании
        </div>
        <span class="more_fields_tooltip_title">{{ moreFieldsText }}</span>
        <ul class="more_fields_tooltip_ul">
          <li
            v-for="field in fields"
            :key="field"
          >
            {{ field }}
          </li>
        </ul>
      </div>
    </div>
    <div class="clear" />
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import plural from 'plural-ru';

export default {
  name: 'StaticMainBaseFilter',

  setup() {
    const fields = ref([
      'Тип компании',
      'Город',
      'Регион',
      'Район города',
      'Федеральный округ',
      'Рубрика',
      'Подрубрика',
      'Тип подрубрики',
      'Координаты(x, y)',
      'Часы работы компании по местному времени',
      'Часовой пояс',
      'Заголовок сайта (title)',
      'Рейтинг компании в Интернете',
    ]);

    const moreFieldsText = computed(() => `и еще ${fields.value.length} ${plural(fields.value.length, 'поле', 'поля', 'полей')}`);

    const toggleName = () => {
      echo('Данную опцию нельзя отключить');
    };

    return {
      fields,
      moreFieldsText,
      toggleName,
    };
  },
};
</script>

<style scoped>
.static_filter  .green_background {
  border-radius: 3px;
  color: #353535;
  font-weight: 700 !important;
  padding: 3px 5px;
  width: 115px;
}

.static_filter_text {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px
}

.more_fields_tooltip_title {
  color: rgb(0 0 0 / 70%);
  border-bottom: 1px dashed;
}

.more_fields_tooltip_title:hover {
  cursor: pointer;
}

.more_fields_tooltip_ul {
  display: none;
  position: absolute;
}

.static_filter_checkbox-wrapper .toggle .circle {
  transform: translate(20px, -50%);
  box-shadow: 0 3px 8px rgb(79 46 220 / 20%);
}

.static_filter_checkbox-wrapper .toggle::before {
  background: var(--color-button-prime-hover);
}

.more_fields_tooltip_title:hover + .more_fields_tooltip_ul {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 7px;
  z-index: 100;
  border: 1px solid #999;
  border-radius: 4px;
  color: #353535;
  background: var(--notification-color);
  font-size: 13px;
  line-height: 1.2;
  width: 180px;
  padding: 10px;
  transform: translateY(56%);
}

.more_fields_tooltip_ul li {
  padding-left: 16px;
  background: url("/dist/css/images/tick_small.png") no-repeat left center;
}

@media screen and (width >=800px) and (width <=1050px) {
  .static_filter_text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    top: 17px;
    margin-bottom: 13px;
  }

  .more_fields_tooltip_title:hover + .more_fields_tooltip_ul {
    transform: translateY(14%) translateX(-12%);
  }
}

</style>
