<template>
  <div
    class="option"
    :class="[{chosen: chosen, active: isActive}]"
    :title="title"
    @click="inclusionClick($event)"
  >
    <div>
      <span class="label">{{ selectorLabel }}</span>
      <img
        v-if="popupUrl"
        id=""
        src="../../../assets/images/icon_query.png"
        alt=""
        class="popup helpIcon _helpIcon"
        :data-file="popupUrl"
      >
      <span
        v-if="description"
        class="description"
      >
        <img
          id=""
          src="../../../assets/images/icon_query.png"
          alt="description"
          class="popup helpIcon"
        >
        <span class="description__text">
          {{ description }}
        </span>
      </span>
    </div>

    <div class="checkboxWrapper">
      <Checkbox
        :mode="['exportbase', 'dark']"
        :chosen="chosen"
      />
    </div>
  </div>
</template>

<script>
import Checkbox from 'checkbox';

export default {
  name: 'DropdownOption',
  components: {
    Checkbox,
  },

  props: {
    selectorLabel: {
      type: String,
      required: true,
    },

    selectorLabelAccusative: {
      type: String,
      required: false,
      default: '',
    },

    chosen: {
      type: Boolean,
      required: false,
    },

    popupUrl: {
      type: String,
      required: false,
      default: '',
    },

    isActive: {
      type: Boolean,
      required: false,
    },

    description: {
      type: String,
      required: false,
      default: null,
    },
  },

  emits: ['inclusion'],

  computed: {
    title() {
      return `Добавляет в ваш заказ ${this.labelAccusativeFormatted}`;
    },

    labelAccusativeFormatted() {
      if (this.labelIsAbbreviation) {
        return this.selectorLabel;
      } if (this.selectorLabelAccusative) {
        return this.selectorLabelAccusative.charAt(0).toLowerCase()
            + this.selectorLabelAccusative.slice(1);
      }
      return this.selectorLabel.charAt(0).toLowerCase() + this.selectorLabel.slice(1);
    },

    labelIsAbbreviation() {
      const secondSymbol = this.selectorLabel[1];
      return secondSymbol.toLowerCase() !== secondSymbol;
    },
  },

  methods: {
    inclusionClick(event) {
      if (event.target.classList.contains('_helpIcon')) {
        return;
      }
      this.$emit('inclusion');
    },
  },
};
</script>

<style scoped src="./DropdownOption.css" />
