<template>
  <div>
    <PayPopup
      :visible-pay-popup="visiblePayPopup"
      :email="email"
      :initial-section="initialSection"
      :order-id="orderId"
      :cost="cost"
      :customer-is-authorized="customerIsAuthorized"
      :demo-creation-is-running="demoCreationIsRunning"
      :demo-created-successfully="demoCreatedSuccessfully"
      :show-order-preloader="showOrderPreloader"
      :count-companies="count"
      :user-discount="userDiscount"
      :client-id="clientId"
      :client-balance="clientBalance"
      :initial-tab="initialTab"
      with-demo-section
      with-save-section
      with-promocode
      payment-target="database"
      service="database"
      order-type="main_base"
      @close-popup="closePayPopup"
      @input-email="changeEmail"
      @init-login="initLogin"
      @create-bill="createBill"
      @create-order="createOrder"
      @download-demo="downloadDemoFile"
      @save-order="saveOrder"
    >
      <template #description>
        <Description />
      </template>
    </PayPopup>
    <div>
      <Card
        :total-cost="cost"
        :total-count="count"
        with-angle-mode
        :relative-discount="speedometrAngle"
        :visible-discount="visibleDiscount"
        balance-bonus-notice
        :bonus-value="bonusValue"
        @demoClick="demoClick"
        @downloadButtonClick="downloadButtonClick"
      >
        <template #notifications>
          <MainBaseNotificationList
            @open-cashless="openCashlessSection"
          />
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import Card from '../../../common_components/Card/Card.vue';
import PayPopup from '../../../common_components/PayPopup/PayPopup.vue';
import Description from '../../../main_base_pay_popup/components/Description/Description.vue';
import MainBaseNotificationList from './MainBaseNotificationList/MainBaseNotificationList.vue';

export default {
  name: 'MainBaseSummary',
  components: {
    MainBaseNotificationList,
    Description,
    PayPopup,
    Card,
  },

  computed: {
    ...mapState('mainBasePayPopup', [
      'visiblePayPopup',
      'email',
      'clientId',
      'clientBalance',
      'orderId',
      'userDiscount',
      'customerIsAuthorized',
      'initialSection',
      'demoCreationIsRunning',
      'demoCreatedSuccessfully',
      'showOrderPreloader',
      'initialTab',
    ]),

    ...mapState('summary', [
      'cost',
      'count',
      'visibleDiscount',
      'speedometrAngle',
      'bonusValue',
    ]),

    ...mapGetters('mainBasePayPopup', [
      'getUrlForBill',
    ]),

    ...mapGetters([
      'getMainBaseDataForCreateRequest',
    ]),
  },

  methods: {
    ...mapMutations('mainBasePayPopup', [
      'setVisiblePayPopup',
      'setEmail',
      'setOldBeznalData',
      'setDemoCreationIsRunning',
      'setDemoCreatedSuccessfully',
      'setInitialSection',
      'setShowOrderPreloader',
      'setOrderId',
      'setInitialTab',
      'setDemoHash',
    ]),

    ...mapActions('mainBasePayPopup', [
      'getAuthStatus',
      'getUserData',
      'getOrderData',
      'getUserDiscount',
      'getDataToCreateBill',
      'getDataForDemo',
      'getDemoFileHash',
    ]),

    ...mapActions('mainBasePayPopup', {
      createOrderStore: 'createOrder',
      saveOrderStore: 'saveOrder',
    }),

    closePayPopup() {
      this.setOrderId('');
      this.setVisiblePayPopup(false);
      this.setInitialSection('');
      this.setInitialTab('');
    },

    async demoClick() {
      if (this.cost <= 0) {
        await window.vue_demo_popup.$store.dispatch('openPopup');
        return;
      }
      await this.getAuthStatus();
      if (this.customerIsAuthorized) {
        await this.getUserData();
      }
      this.setInitialSection('demo');
      this.setVisiblePayPopup(true);
    },

    async createOrder() {
      this.setShowOrderPreloader(true);

      const orderData = await this.getOrderData(this.getMainBaseDataForCreateRequest);
      const order = await this.createOrderStore({
        ...orderData,
        plata: 0,
      });
      window.openWebSocketChannel(order.id_plan, order.hash);

      this.setShowOrderPreloader(false);
    },

    changeEmail(email) {
      this.setEmail(email);
      this.getUserDiscount(email);
    },

    async createBill(data) {
      this.setOldBeznalData(data);
      const config = this.getMainBaseDataForCreateRequest;
      await this.createOrderStore(await this.getDataToCreateBill(config));

      if (window.MFuncs.goYandexKassaWithParams) {
        window.MFuncs.goYandexKassaWithParams(this.orderId, this.cost);
      }
      if (window.MFuncs.yandexReachGoalWithTarget) {
        window.MFuncs.yandexReachGoalWithTarget('beznal');
      }

      // костыль для лучшего достижения целей при медленном интернете
      // переходим на страницу счета с небольшой задержкой, чтобы цели дошли до Яндекса
      setTimeout(() => {
        window.location.href = this.getUrlForBill;
      }, 100);
    },

    async downloadButtonClick() {
      if (this.cost === 0) {
        return echo('Выберите больше фирм', 6000, 'warning');
      }
      window.MFuncs.reachYandexGoal('config');
      await this.getAuthStatus();

      if (this.customerIsAuthorized) {
        await this.getUserData();
      }

      this.setVisiblePayPopup(true);
    },

    initLogin() {
      window.open_balance_block = true;
      this.addEventForBalanceBlock();
      this.setVisiblePayPopup(false);
      document.dispatchEvent(new Event('openAuthWrapper'));
    },

    addEventForBalanceBlock() {
      document.body.addEventListener('open-balance-block', async () => {
        await this.getAuthStatus();
        if (this.customerIsAuthorized) {
          await this.getUserData();
        }
        this.setInitialTab('BalanceForm');
        await this.setVisiblePayPopup(true);
        this.setInitialTab('');
      });
    },

    async downloadDemoFile({ format, option, email }) {
      this.setDemoCreationIsRunning(true);
      const dataForDemo = await this.getDataForDemo(this.getMainBaseDataForCreateRequest);
      dataForDemo.format = format;
      dataForDemo.email = email;
      const optionText = option ? '&options=hyperlinks_phone' : '';
      const { message, error } = await this.getDemoFileHash(dataForDemo);

      this.setDemoCreationIsRunning(false);
      if (error) {
        this.setDemoCreatedSuccessfully(false);
        return echo(message, 6000, 'warning');
      }
      this.setDemoCreatedSuccessfully(true);

      this.setDemoHash(message);

      location.href = `/api/main_base/create_demo_file?code=${
        message}&format=${format}${optionText}`;

      window.echo(
        'Демо-выгрузка сформирована и скачивается. Ссылка отправлена на ваш E-mail',
        6000,
        'success',
      );
    },

    async saveOrder(email) {
      const orderData = await this.getOrderData(this.getMainBaseDataForCreateRequest);
      orderData.plata = 3;
      orderData.email = email;
      await this.saveOrderStore(orderData);
      echo('Ваш заказ сохранен и доступен для оплаты в личном кабинете.', 6000, 'success');
    },

    async openCashlessSection() {
      if (this.cost === 0) {
        return echo('Выберите больше фирм', 6000, 'warning');
      }
      window.MFuncs.reachYandexGoal('config');
      await this.getAuthStatus();

      if (this.customerIsAuthorized) {
        await this.getUserData();
      }
      this.setInitialTab('CashlessPaymentForm');
      this.setVisiblePayPopup(true);
    }
  },
};

</script>
