<template>
  <div>
    <div class="selectorBorderVertical" />
    <div class="selectorBorderVertical" />
    <div class="selectorHead">
      <div class="selectorHeadOptionTitle">
        &nbsp;
      </div>
      <div class="inclusionOptions">
        <div class="inclusionOptionsItem selectorCell">
          <div class="inclusionOptionTitle">
            Включено
          </div>
          <div class="inclusionOptionSubtitle">
            в заказ
          </div>
        </div>
        <div class="inclusionOptionsItem selectorCell">
          <div class="inclusionOptionTitle">
            Исключено
          </div>
          <div class="inclusionOptionSubtitle">
            из заказа
          </div>
        </div>
      </div>
    </div>

    <section class="selectorOptionsSection">
      <div class="selectorOptionsSectionTitle">
        Этаж помещения
      </div>

      <IncludeExcludeSelector
        selector-label="Все этажи"
        is-all-types-selector
        :all-types-selected="allFloorsSelected"
        @allTypes="toggleAllFloorsSelected"
      />

      <IncludeExcludeSelector
        v-for="floor in floors"
        :key="floor.id"
        :selector-label="floor.title"
        :option-obj="floor"
        @inclusion="toggleIncludeFloor(floor.id)"
        @exclusion="toggleExcludeFloor(floor.id)"
      />
    </section>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import IncludeExcludeSelector from '@/common_components/IncludeExcludeSelector/IncludeExcludeSelector.vue';

export default {
  name: 'FloorSelector',
  components: {
    IncludeExcludeSelector,
  },

  setup() {
    const store = useStore();

    const floors = computed(() => store.state.floors);

    const allFloorsSelected = computed(() => !Object.keys(floors.value)
      .some((id) => floors.value[id].included || floors.value[id].excluded));

    const toggleAllFloorsSelected = () => {
      store.dispatch('toggleAllFloorsSelected');
    };

    const toggleIncludeFloor = (id) => {
      store.dispatch('toggleIncludeFloor', id);
    };

    const toggleExcludeFloor = (id) => {
      store.dispatch('toggleExcludeFloor', id);
    };

    return {
      floors,
      allFloorsSelected,
      toggleAllFloorsSelected,
      toggleIncludeFloor,
      toggleExcludeFloor,
    };
  },
};
</script>

<style scoped src="../../../../egrul/components/EgrulConfigurator/EgrulConfigurator.css" />

<style scoped src="../../../../common_components/IncludeExcludeSelector/IncludeExcludeSelector.css" />
