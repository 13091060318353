<template>
  <div id="mainBaseConfigurator">
    <main class="mainConfigurator">
      <RequiredOptions />
      <section class="dropdownSelectors">
        <div class="dropdownSelectorWrapper companyTypeSelectorWrapper">
          <p class="dropdownSelectorHeader dropdownSelectorHeader_type">
            <b>Тип компании:</b>
          </p>
          <div
            class="dropdownSelectorButtonWrapper"
            @click.stop="selectorChange('companyType')"
          >
            <ButtonSelector
              :content="selectedCompanyTypesDescription()"
              :width="buttonSelectorWidthForImportantSelector"
              is-important-selector
              class="buttonMainDropdownSelector _button-company-type"
            />
          </div>
          <transition name="slide">
            <CompanyTypeSelector
              v-if="showSelector.companyType"
              :search-company-type="searchCompanyType"
              :enable-all-option="companyTypeEnableAllOption"
              :groups="companyTypeGroups"
              :class="[{ mainDropdownSelectorVisible: showSelector.companyType }]"
              class="mainDropdownSelector"
              @enableAll="enableAllCompanyTypes"
              @updateSearch="updateSearchCompanyType"
            />
          </transition>
        </div>

        <div class="dropdownSelectorWrapper">
          <p class="dropdownSelectorHeader">
            <b>Правовая форма:</b>
          </p>
          <div
            class="dropdownSelectorButtonWrapper"
            @click.stop="selectorChange('legalForm')"
          >
            <ButtonSelector
              :content="selectedLegalFormsDescription()"
              :width="buttonSelectorWidth"
              class="buttonMainDropdownSelector"
            />
          </div>
          <transition name="slide">
            <DropdownSelector
              v-show="showSelector.legalForm"
              :enable-all-option="legalFormEnableAllOption"
              :options="getLegalFormRealOnly"
              :other-option="legalFormOtherOption"
              :extra-option="getLegalFormClosedOnly"
              selector-type="legalForm"
              class="mainDropdownSelector"
              :class="[{ mainDropdownSelectorVisible: showSelector.legalForm }]"
              selector-name="Правовая форма"
              @enableAll="enableAllLegalForms"
              @include="toggleIncludeType"
              @toggleOtherOption="toggleUnknownLegalFormIncluded"
              @toggleExtraOption="toggleIncludeClosed"
            />
          </transition>
        </div>

        <div class="dropdownSelectorWrapper">
          <p class="dropdownSelectorHeader">
            <b>Размер компании:</b>
          </p>
          <div
            class="dropdownSelectorButtonWrapper"
            @click.stop="selectorChange('companySize')"
          >
            <ButtonSelector
              :content="selectedCompanySizesDescription()"
              :width="buttonSelectorWidth"
              class="buttonMainDropdownSelector"
            />
          </div>
          <transition name="slide">
            <DropdownSelector
              v-show="showSelector.companySize"
              :enable-all-option="companySizeEnableAllOption"
              :options="companySize"
              :other-option="companySizeOtherOption"
              selector-name="Размер компании"
              selector-type="companySize"
              class="mainDropdownSelector"
              :class="[{ mainDropdownSelectorVisible: showSelector.companySize }]"
              @enableAll="enableAllCompanySizes"
              @include="toggleIncludeType"
              @toggleOtherOption="toggleUnknownCompanySizeIncluded"
            />
          </transition>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import ButtonSelector from 'button-selector';
import {
  mapState, mapActions, mapGetters, mapMutations,
} from 'vuex';
import debounce from 'lodash.debounce';
import RequiredOptions from '@/main_base/components/RequiredOptions/RequiredOptions.vue';
import CompanyTypeSelector from '@/main_base/components/CompanyTypeSelector/CompanyTypeSelector.vue';
import DropdownSelector from '../DropdownSelector/DropdownSelector.vue';
import DropdownSelectorUtil from '../../store/utils/DropdownSelectorUtil';

export default {
  name: 'MainBaseConfigurator',

  components: {
    CompanyTypeSelector,
    RequiredOptions,
    DropdownSelector,
    ButtonSelector,
  },

  data() {
    return {
      selectorsDataFetched: false,
      showSelector: {
        legalForm: false,
        companyType: false,
        companySize: false,
      },

      buttonSelectorWidth: '155px',
      buttonSelectorWidthForImportantSelector: '155px',
      notAllowedHash: [
        '#reg_date',
        '#okved',
      ],
    };
  },

  computed: {
    ...mapState([
      'companyType',
      'companyTypeGroups',
      'searchCompanyType',
      'unknownLegalFormIncluded',
      'companySize',
      'unknownCompanySizeIncluded',
    ]),

    ...mapGetters([
      'getLegalFormRealOnly',
      'getLegalFormClosedOnly',
    ]),

    companyTypeEnableAllOption() {
      return { title: 'Все компании' };
    },

    legalFormEnableAllOption() {
      return { title: 'Все формы' };
    },

    legalFormOtherOption() {
      return {
        title: 'Форма не определена',
        title_accusative: 'компании, у которых не указана правовая форма',
        included: this.unknownLegalFormIncluded,
        popupUrl: '/api/popup/main_absent_legal_info',
      };
    },

    companySizeEnableAllOption() {
      return { title: 'Все компании' };
    },

    companySizeOtherOption() {
      return {
        title: 'Размер не определен',
        title_accusative: 'компании, у которых не указан размер',
        included: this.unknownCompanySizeIncluded,
        popupUrl: '/api/popup/main_absent_legal_info',
      };
    },
  },

  watch: {
    searchCompanyType: {
      handler: debounce(function () {
        if (this.searchCompanyType === '') {
          this.resetSearch();
        } else {
          this.search();
        }
      }, 300),
    },

    showSelector: {
      handler() {
        let isShownSelector = false;

        for (const selector in this.showSelector) {
          if (this.showSelector[selector]) {
            isShownSelector = true;
          }
        }

        if (isShownSelector) {
          document.addEventListener('click', this.outsideEventListenerForShownSelector);
        } else {
          document.removeEventListener('click', this.outsideEventListenerForShownSelector);
        }
      },

      deep: true,
    },
  },

  created() {
    this.showEmailPhoneNotification();

    if (!this.notAllowedHash.includes(window.location.hash)) {
      this.fetchSelectorsData();
    } else {
      window.addEventListener('hashchange', () => {
        if (!this.selectorsDataFetched && window.location.hash === '') {
          this.fetchSelectorsData();
        }
      });
    }
  },

  methods: {
    ...mapActions([
      'enableAllCompanyTypes',

      'enableAllLegalForms',
      'toggleUnknownLegalFormIncluded',

      'enableAllCompanySizes',
      'toggleUnknownCompanySizeIncluded',

      'toggleIncludeType',

      'toggleIncludeClosed',
    ]),

    ...mapActions([
      'setCompanyTypeData',
      'setLegalForm',
      'setCompanySize',
      'resetSearch',
      'search',
    ]),

    ...mapActions('summary', [
      'setPrice',
    ]),

    ...mapMutations([
      'setSearchCompanyType',
    ]),

    companyTypeNameToLowerCase(value) {
      const isAbbreviation = value[0] === value[0].toUpperCase()
          && value[1] === value[1].toUpperCase();
      if (isAbbreviation) {
        return value;
      }
      return value.charAt(0).toLowerCase() + value.slice(1);
    },

    async showEmailPhoneNotification() {
      const utmValue = decodeURI(window.location.search
        .split('&')
        .filter((item) => item.includes('utm_term=')).toString()
        .split('=')[1]);
      let result = '';
      const emails = ['рассыл', 'mail', 'мэйл', 'мэил', 'мейл', 'меил', 'почт'];
      const phones = ['телефон', 'контакт', 'звон', 'номер'];

      emails.forEach((email) => {
        if (utmValue.indexOf(email) !== -1) {
          result = 'На сайте можно приобрести базу компаний с email';
        }
      });
      if (utmValue.indexOf('эл') !== -1 && utmValue.indexOf('адрес') !== -1) {
        result = 'На сайте можно приобрести базу компаний с email';
      }

      phones.forEach((phone) => {
        if (utmValue.indexOf(phone) !== -1) {
          result = 'На сайте можно приобрести базу компаний с телефонами';
        }
      });

      await new Promise((r) => { setTimeout(r, 5000); });

      if (result) {
        window.echo(result, 8000, 'success');
      }
    },

    updateSearchCompanyType(value) {
      this.setSearchCompanyType(value);
    },

    // используется снаружи компонента
    setShowSelector(selector, value) {
      this.showSelector[selector] = value;

      for (const type in this.showSelector) {
        if (type !== selector) {
          this.showSelector[type] = false;
        }
      }
    },

    selectedCompanyTypesDescription() {
      return this.selectedOptionsDescription(
        this.companyType,
        false,
        'Все компании',
        'Без типа',
      );
    },

    selectedLegalFormsDescription() {
      return this.selectedOptionsDescription(
        this.getLegalFormRealOnly,
        this.unknownLegalFormIncluded,
        'Все формы',
        'Не определена',
      );
    },

    selectedCompanySizesDescription() {
      return this.selectedOptionsDescription(
        this.companySize,
        this.unknownCompanySizeIncluded,
        'Все компании',
        'Не определен',
      );
    },

    selectedOptionsDescription(
      optionsObject,
      otherOptionIncluded,
      allEnabledDescription,
      onlyOtherIncludedDescription,
    ) {
      if (
        !DropdownSelectorUtil.somethingIncluded(optionsObject, false)
          && !DropdownSelectorUtil.somethingExcluded(optionsObject, false)
      ) {
        return allEnabledDescription;
      }

      const includedOptionsCount = DropdownSelectorUtil
        .includedOptionsCount(optionsObject, otherOptionIncluded);
      const excludedOptionsCount = DropdownSelectorUtil
        .excludedOptionsCount(optionsObject, otherOptionIncluded);

      const mainIncludedOptionValues = DropdownSelectorUtil.mainIncludedOptionValues(optionsObject);
      const mainIncludedOptionsCount = DropdownSelectorUtil.mainIncludedOptionsCount(optionsObject);

      const mainExcludedOptionValues = DropdownSelectorUtil.mainExcludedOptionValues(optionsObject);
      const mainExcludedOptionsCount = DropdownSelectorUtil.mainExcludedOptionsCount(optionsObject);

      if (includedOptionsCount + excludedOptionsCount > 1) {
        return `${includedOptionsCount + excludedOptionsCount} шт.`;
      }

      if (mainIncludedOptionsCount === 1) {
        return mainIncludedOptionValues[0].title;
      }

      if (mainExcludedOptionsCount === 1) {
        return `Исключить ${this.companyTypeNameToLowerCase(mainExcludedOptionValues[0].title)}`;
      }

      return onlyOtherIncludedDescription;
    },

    selectorChange(selectorType) {
      this.showSelector[selectorType] = !this.showSelector[selectorType];

      if (this.showSelector[selectorType]) {
        for (const type in this.showSelector) {
          if (type !== selectorType) {
            this.showSelector[type] = false;
          }
        }
      }
    },

    outsideEventListenerForShownSelector(event) {
      const dataActionArr = [
        'lizing', 'tender', 'sro', 'online-store', 'tender44_participant', 'tender44_winner', 'lizing_takers', 'sro_only',
        'sro_member', 'dealer', 'distributor', 'manufacturer',
      ];

      const buttonMainDropdownSelector = document.getElementsByClassName('buttonMainDropdownSelector');
      const geoPopup = document.getElementsByClassName(
        'wrapper_city_conf m__wrapper_city_conf _wrapper_city_conf _main_cities_wrapper',
      )
        .item(0);

      // Event.path не поддерживается в некоторых браузерах, специально для firefox
      // Используем composedPath(), а не event.path
      let path = event.path || (event.composedPath && event.composedPath());
      if (path) {
        if (event.composedPath()) {
          path = event.composedPath().indexOf(geoPopup) !== -1;
        }
      }

      if (
        event.target === buttonMainDropdownSelector
          || event.target.closest('.mainDropdownSelectorVisible')
          || (event.target.closest('.popup_window') || event.target.closest('.pp_background'))
          || dataActionArr.indexOf(event.target.getAttribute('data-action')) !== -1
          || path
      ) {
        return;
      }

      for (const selector in this.showSelector) {
        if (this.showSelector[selector]) {
          this.showSelector[selector] = false;
          return;
        }
      }
    },

    async fetchSelectorsData() {
      await Promise.all([
        this.setCompanyTypeData().then(() => {
          /* инициируется открытие компонента Tip.vue,
          который в свою очередь открывает компонент MainBaseConfigurator.vue */
          window.vue_tip_balloon.initTipData();
        }),
        this.setLegalForm(),
        this.setCompanySize(),
        this.setPrice(),
      ]).then(() => {
        this.selectorsDataFetched = true;
      });
    },
  },
};
</script>

<style scoped src="./MainBaseConfigurator.css" />
