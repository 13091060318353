<template>
  <div
    id="result_for_balloon_egrul_hint_scrolling"
    class="mainBaseSelectorAndSummary"
  >
    <MainBaseOrderDescription />
    <MainBaseOrderBucket />
  </div>
</template>

<script>

import MainBaseOrderBucket from '../MainBaseOrderBucket/MainBaseOrderBucket.vue';
import MainBaseOrderDescription from '../MainBaseOrderDescription/MainBaseOrderDescription.vue';

export default {
  name: 'MainBaseOrderSummary',
  components: { MainBaseOrderDescription, MainBaseOrderBucket },
};
</script>

<style scoped>
.mainBaseSelectorAndSummary {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: var(--margin-left-cart);
  padding-top: var(--margin-under-configurator);
  background-color: #f7f7f7;
}
</style>
