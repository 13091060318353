<template>
  <div v-if="groups">
    <template
      v-for="[id, group] in Object.entries(groups)"
    >
      <div
        v-if="group.show"
        :key="id"
        :class="{'group_active': group.active, 'highlight': group.highlight}"
        class="group"
      >
        <div
          class="group-header"
          @click="clickGroup(group.id)"
        >
          <span class="group__title">
            {{ group.name }}
          </span>
          <div class="incl_excl_count_text" v-if="isExclusionNeeded">
            <span
              v-if="getGroupIncludedCount(group)"
              class="company-group_included_count"
            >
              Вкл: {{ getGroupIncludedCount(group) }}
            </span>
            <span
              v-if="getGroupIncludedCount(group)
                && getGroupExcludedCount(group)"
            >
              |
            </span>
            <span
              v-if="getGroupExcludedCount(group)"
              class="company-group_excluded_count"
            >
              Искл: {{ getGroupExcludedCount(group) }}
            </span>
          </div>
          <img
            alt="arrow"
            src="/images/arrow_right_black.svg"
            class="group__arrow"
          >
        </div>
        <div v-if="isExclusionNeeded">
          <DropdownOptionWithExclusion
            v-for="(option) in filteredOptions(group)"
            :class="{firstInGroup: option.firstInGroup}"
            :selector-label="option.title"
            :selector-label-accusative="option.title_accusative"
            :included="option.included"
            :excluded="option.excluded"
            :is-active="option.isActive"
            :popup-url="option.popupUrl"
            :description="option.description"
            @inclusion="toggleIncludeOption(option.id)"
            @exclusion="toggleExcludeOption(option.id)"
          />
        </div>
        <div v-else>
          <DropdownOption
            v-for="(option) in filteredOptions(group)"
            :class="{firstInGroup: option.firstInGroup}"
            :selector-label="option.title"
            :selector-label-accusative="option.title_accusative"
            :chosen="option.included"
            :is-active="option.isActive"
            :popup-url="option.popupUrl"
            :description="option.description"
            @inclusion="toggleIncludeOption(option.id)"
          />
        </div>
      </div>
    </template>
  </div>
  <div v-else />
</template>

<script>

import DropdownOption from '@/main_base/components/DropdownOption/DropdownOption.vue';
import DropdownOptionWithExclusion
  from '@/main_base/components/DropdownOptionWIthExclusion/DropdownOptionWithExclusion.vue';

export default {
  name: 'DropDownGroups',
  components: {
    DropdownOptionWithExclusion,
    DropdownOption,
  },

  props: {
    options: {
      type: Object,
      required: true,
    },

    groups: {
      type: Object,
      required: true,
    },

    selectorType: {
      type: String,
      required: false,
      default: undefined,
    },

    isExclusionNeeded: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ['inclusionIfSingleSelector', 'inclusionIfManySelectorsExist',
    'exclusionIfSingleSelector', 'exclusionIfManySelectorsExist',
    'clickGroupIfSingleSelector', 'clickGroupIfManySelectorsExist'],

  methods: {
    filteredOptions(group) {
      return Object.fromEntries(
        Object.entries(this.options)
          .filter(([key, option]) => option.groupId === group.id && option.visible && !option.hidden),
      );
    },

    clickGroup(groupId) {
      if (this.selectorType === undefined) {
        this.$emit('clickGroupIfSingleSelector', groupId);
      } else {
        this.$emit('clickGroupIfManySelectorsExist', {
          selectorType: this.selectorType,
          groupId,
        });
      }
    },

    toggleIncludeOption(id) {
      if (this.selectorType === undefined) {
        this.$emit('inclusionIfSingleSelector', id);
      } else {
        this.$emit('inclusionIfManySelectorsExist', {
          selectorType: this.selectorType,
          id,
        });
      }
    },

    toggleExcludeOption(id) {
      if (this.selectorType === undefined) {
        this.$emit('exclusionIfSingleSelector', id);
      } else {
        this.$emit('exclusionIfManySelectorsExist', {
          selectorType: this.selectorType,
          id,
        });
      }
    },

    getGroupIncludedCount(group) {
      return Object.entries(this.options)
        .filter(([key, option]) => option.groupId === group.id && !option.hidden && option.included).length;
    },

    getGroupExcludedCount(group) {
      return Object.entries(this.options)
        .filter(([key, option]) => option.groupId === group.id && !option.hidden && option.excluded).length;
    },
  },
};
</script>

<style scoped>
  .group_active .group__arrow{
    transform: rotate(270deg);
  }
  .group__title{
    padding-left: 20px;
  }
  .group__arrow{
    transform: rotate(90deg);
    max-width: 15px;
    transition: .3s;
  }
  .group-header{
    cursor: pointer;
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 10px 0 5px;
    font-weight: bold;
  }
  .highlight .group-header{
    background-color: #e9f4e5;
  }
  .company-group_included_count {
    color: var(--color-green-prime);
  }
  .company-group_excluded_count {
    color: var(--color-orange);
  }
  .incl_excl_count_text {
    display: flex;
    gap: 10px;
  }
</style>
