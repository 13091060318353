<!-- eslint-disable vue/v-on-handler-style -->
<!-- eslint-disable vue/html-self-closing -->
<!-- eslint-disable vue/html-closing-bracket-newline -->

<template>
  <div class="additional_filters_section_of_show">
    <div class="additional_filter_header_wrapper">
      <span class="additional_filter_header">4. Дополнительные настройки вашей базы</span>
    </div>

    <div class="additional_filter_header_wrapper_sections">
      <div
        class="additional_filter_item _option_button"
        data-filter="main_okved"
      >
        <Checkbox
          :chosen="!!mainOkved"
          :mode="['dark']"
          title="Показать главный ОКВЭД"
          @change="toggleAdditionalFilter('main_okved')"
        />
        <span
          class="instruction popup"
          data-file="api/popup/main_show_main_okved_instruction">
        </span>
      </div>

      <div
        class="additional_filter_item _option_button"
        data-filter="show_kpp_okpo"
      >
        <Checkbox
          :chosen="!!showKppOkpo"
          :mode="['dark']"
          title="Показать КПП / ОКПО"
          @change="toggleAdditionalFilter('show_kpp_okpo')"
        />
        <span
          class="instruction popup"
          data-file="api/popup/main_show_kpp_okpo_instruction">
        </span>
      </div>

      <div
        class="additional_filter_item _option_button"
        data-filter="show_registration_date"
      >
        <Checkbox
          :chosen="!!showRegistrationDate"
          :mode="['dark']"
          title="Показать год регистрации компании"
          @change="toggleAdditionalFilter('show_registration_date')"
        />
        <span
          class="instruction popup"
          data-file="api/popup/main_show_registration_date_instruction"
        ></span>
      </div>

      <div
        class="additional_filter_item _option_button"
        data-filter="show_inc_and_profit"
      >
        <Checkbox
          :chosen="!!showIncAndProfit"
          :mode="['dark']"
          html-title
          title="Показать <span style='font-weight: 700'>выручку/прибыль</span> за послед. 4 года"
          @change="toggleAdditionalFilter('show_inc_and_profit')"
        />
        <span
          class="instruction popup"
          data-method="GET"
          data-file="/api/popup/popup_income_profit">
        </span>
      </div>

      <div
        class="additional_filter_item _option_button"
        data-filter="show_legal_name"
      >
        <Checkbox
          :chosen="!!showLegalName"
          :mode="['dark']"
          title="Показать юридическое название"
          @change="toggleAdditionalFilter('show_legal_name')"
        />
        <span
          class="instruction popup"
          data-file="api/popup/main_show_legal_name_instruction">
        </span>
      </div>

      <div
        class="additional_filter_item _option_button"
        data-filter="highlight_new_companies"
      >
        <Checkbox
          :chosen="!!highlightNewCompanies"
          :mode="['dark']"
          title="Выделить цветом новые фирмы"
          @change="toggleAdditionalFilter('highlight_new_companies')"
        />
        <span
          class="instruction popup"
          data-file="api/popup/main_highlight_new_companies_instruction">
        </span>
      </div>

      <div
        class="additional_filter_item _option_button"
        data-filter="show_site_description"
      >
        <Checkbox
          :chosen="!!showSiteDescription"
          :mode="['dark']"
          title="Показать описание сайта (title и description)"
          @change="toggleAdditionalFilter('show_site_description')"
        />
        <span
          class="instruction popup"
          data-file="api/popup/main_show_site_description_instruction">
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Checkbox from 'checkbox';

export default {
  name: 'AdditionalFiltersSection4',
  components: {
    Checkbox,
  },

  data() {
    return {
      htmlTitle: true,
    };
  },

  computed: {
    ...mapState([
      'mainOkved',
      'showRegistrationDate',
      'highlightNewCompanies',
      'showIncAndProfit',
      'showKppOkpo',
      'showLegalName',
      'showCeoSex',
      'showSiteDescription',
    ]),
  },

  methods: {
    ...mapActions([
      'toggleAdditionalFilter',
    ]),
  },
};
</script>

<style scoped src="./AdditionalFilters.css" />
