import api from "../api";

const actions = {
    enableAllCompanyTypes({ commit }) {
        commit('enableAllCompanyTypes');
        send_data_to_plan();
    },
    enableAllLegalForms({ commit }) {
        commit('enableAllLegalForms');
        send_data_to_plan();
    },
    enableAllCompanySizes({ commit }) {
        commit('enableAllCompanySizes');
        send_data_to_plan();
    },

    toggleIncludeType({ commit }, params) {
        commit('toggleIncludeType', params);

        if (params.selectorType === 'companyType') {
            commit('highlightCompanyTypeGroup', params.id);
        }
        send_data_to_plan();
    },

    toggleExcludeType({ commit }, params) {
        commit('toggleExcludeType', params);

        if (params.selectorType === 'companyType') {
            commit('highlightCompanyTypeGroup', params.id);
        }
        send_data_to_plan();
    },

    multipleToggleIncludeType({ commit }, {selectorType, ids}) {
        ids.forEach(item => {
            let params = {selectorType: selectorType, id: item};
            commit('toggleIncludeType', params);
        });
        send_data_to_plan();
    },
    resetMultipleToggleIncludeType({ commit }, {selectorType, ids}) {
        ids.forEach(item => {
            let params = {selectorType: selectorType, id: item};
            commit('resetToggleIncludeType', params);
        });
        send_data_to_plan();
    },

    toggleUnknownLegalFormIncluded({ commit }) {
        commit('toggleUnknownLegalFormIncluded');
        send_data_to_plan();
    },
    toggleUnknownCompanySizeIncluded({ commit }) {
        commit('toggleUnknownCompanySizeIncluded');
        send_data_to_plan();
    },

    toggleIncludeClosed({ commit }) {
        commit('toggleIncludeClosed');
        send_data_to_plan();
    },

    toggleAdditionalFilter({ commit, getters, }, additionalFilter) {
        commit('toggleAdditionalFilter', additionalFilter);
        window.dispatchEvent(new CustomEvent("MFilterMultiOptionSelector:option"));
    },

    setOnlyWithoutSite({ commit }, value) {
        commit('setOnlyWithoutSite', value);
    },
    setShowCeoSex({ commit }, value) {
        commit('setShowCeoSex', value);
    },
    setShowSiteDescription({ commit }, value) {
        commit('setShowSiteDescription', value);
    },
    setOnlyUniquePhone({ commit }, value) {
        commit('setOnlyUniquePhone', value);
    },
    setOnlyMobilePhone({ commit }, value) {
        commit('setOnlyMobilePhone', value);
    },
    setHasSitePhone({ commit }, value) {
        commit('setHasSitePhone', value);
    },
    setHasMessenger({ commit }, value) {
        commit('setHasMessenger', value);
    },
    setCompanyTypeData({ commit }) {
        return api.getCompanyTypeData()
            .then(companyType => {
                commit('setCompanyType', companyType['company_types']);
                commit('setFuse', companyType['company_types']);
                commit('setCompanyTypeGroups', companyType['groups']);
            });
    },
    setLegalForm({ commit }) {
        api.getLegalForm()
            .then(legalForm => {
                commit('setLegalForm', legalForm);
            });
    },
    setCompanySize({ commit }) {
        api.getCompanySize()
            .then(companySize => {
                commit('setCompanySize', companySize);
            });
    },
    hideCompanyTypes({ commit }) {
        commit('hideTypes');
        commit('hideGroups');
    },

    hideAllCompanyTypesGroups({ commit, getters }) {
        const groupIds = getters.getGroupIds;

        groupIds.forEach(groupId => commit('setShowCompanyTypeGroup', { groupId, show: false }));
    },

    showAllCompanyTypesGroups({ commit, getters }) {
        const groupIds = getters.getGroupIds;

        groupIds.forEach(groupId => commit('setShowCompanyTypeGroup', { groupId, show: true }));
    },

    showCompanyTypesGroupsByIds({ commit, dispatch }, groupIds) {
        dispatch('hideAllCompanyTypesGroups');
        groupIds.forEach(groupId => commit('setShowCompanyTypeGroup', { groupId, show: true }));
    },

    visibleCompanyTypesByIds({ state, commit }, ids) {
        commit('hideTypes');

        Object.entries(state.companyType).forEach(([key]) => {
            if (ids.includes(state.companyType[key].id)) {
                state.companyType[key].visible = true;
            }
        });
    },

    resetSearch({ commit, dispatch }) {
        dispatch('showAllCompanyTypesGroups');
        commit('hideGroups');
        commit('hideTypes');
        commit('resetHiddenTypes');
    },

    search({ state, commit, dispatch }) {
        const searchedCompanyTypes = state.fuse.search(state.searchCompanyType).map(itemData => itemData.item);

        const groupIds = [...new Set(searchedCompanyTypes.map(companyType => companyType.groupId))];

        const typesIds = searchedCompanyTypes.map(companyType => companyType.id);

        dispatch('showCompanyTypesGroupsByIds', groupIds);
        commit('activateAllGroups');
        commit('resetHiddenTypes');
        dispatch('visibleCompanyTypesByIds', typesIds);
    },
};

export default actions;
