import { NodeValue, Tree } from 'tree';
import api from '../../api/index';

export default {
  namespaced: true,

  state: {
    /**
     * @var {Tree}
     */
    similarRubricsTree: {},

    showTree: false,

    okvedsList: [],
  },

  getters: {
    getSimilarRubricsTree(state) {
      return state.similarRubricsTree;
    },

    okvedListIsEmpty(state) {
      return state.okvedsList.length === 0;
    },
  },

  mutations: {
    setSimilarRubricsTree(state) {
      state.similarRubricsTree = new Tree(3);

      const nodeValue = new NodeValue(
        { id: 1, name: 'Выбрать все рубрики' },
        1,
        true,
        false,
        false,
        true,
      );

      state.similarRubricsTree.addNodeByParentId(nodeValue, 0);
    },

    setSectionsSimilarRubricsTree(state, sections) {
      sections.forEach((section) => {
        const nodeValue = new NodeValue(
          section,
          2,
          true,
          false,
          false,
          false,
        );

        state.similarRubricsTree.addNodeByParentId(nodeValue, 1);
      });
    },

    setSubsectionsSimilarRubricsTree(state, subsections) {
      subsections.forEach((subsection) => {
        const nodeValue = new NodeValue(
          subsection,
          3,
        );
        state.similarRubricsTree.addNodeByParentId(nodeValue, subsection.id_section);
      });
    },

    chooseSimilarNode: (state, nodeDesc) => {
      state.similarRubricsTree.chooseNode(nodeDesc.level, nodeDesc.id);
    },

    setShowTree(state, value) {
      state.showTree = value;
    },

    setOkvedsList(state, value) {
      state.okvedsList = value;
    },
  },

  actions: {
    async setSimilarTree({ commit, rootGetters }, searchString) {
      const res = await api.searchRubricsByWord(searchString);

      const chosenSections = rootGetters['rubricsTree/getSelectedSections']
        .filter(id => res.rubrics.subsections.find(section => id === section.id));

      commit('setSimilarRubricsTree');
      commit('setSectionsSimilarRubricsTree', res.rubrics.sections);
      commit('setSubsectionsSimilarRubricsTree', res.rubrics.subsections);

      const showTree = res.rubrics.subsections.length !== 0;
      commit('setShowTree', showTree);

      // выбираем пункты, для синхронизации с основным деревом
      chosenSections.forEach((idSection) => {
        commit('chooseSimilarNode', {
          level: 3,
          id: idSection,
        });
      });

      commit('setOkvedsList', res.okveds);
    },
  },
};
