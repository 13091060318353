export default class DropdownSelectorUtil {
    static optionsToIdsList(optionsObject, otherOptionIncluded) {
        if (!this.somethingIncluded(optionsObject, otherOptionIncluded)) {
            return [];
        }

        const idsList = [];
        for (let id in optionsObject) {
            const optionId = optionsObject[id].id;
            const optionIncluded = optionsObject[id].included;
            if (otherOptionIncluded) {
                if (!optionIncluded) {
                    idsList.push(`no-${optionId}`);
                }
            } else {
                if (optionIncluded) {
                    idsList.push(optionId);
                }
            }
        }

        return idsList;
    }

    /**
     * выбраны какие-либо пункты, но не все
     */
    static somethingIncluded(optionsObject, otherOptionIncluded) {
        return (
            !this.nothingIncluded(optionsObject, otherOptionIncluded) &&
            !this.allIncluded(optionsObject, otherOptionIncluded)
        )
    }

    static somethingExcluded(optionsObject, otherOptionIncluded) {
        return (
            !this.nothingExcluded(optionsObject, otherOptionIncluded) &&
            !this.allIncluded(optionsObject, otherOptionIncluded)
        )
    }

    static nothingIncluded(optionsObject, otherOptionIncluded) {
        const everyOptionNotIncluded = Object.keys(optionsObject).every(id => !optionsObject[id].included);
        return (everyOptionNotIncluded && !otherOptionIncluded);
    }

    static nothingExcluded(optionsObject, otherOptionIncluded) {
        const everyOptionNotExcluded = Object.keys(optionsObject).every(id => !optionsObject[id].excluded);
        return (everyOptionNotExcluded && !otherOptionIncluded);
    }

    /**
     * в текущей логике фильтра равносильно случаю "ничего не включено"
     */
    static allIncluded(optionsObject, otherOptionIncluded) {
        const everyOptionIncluded = Object.keys(optionsObject).every(id => optionsObject[id].included);
        return (everyOptionIncluded && otherOptionIncluded);
    }

    static includedOptionsCount(optionsObject, otherOptionIncluded) {
        const includedMainOptions = Object.keys(optionsObject).filter(id => optionsObject[id].included);
        let cnt = includedMainOptions.length;
        if (otherOptionIncluded) {
            cnt++;
        }
        return cnt;
    }

    static excludedOptionsCount(optionsObject, otherOptionIncluded) {
        const excludedMainOptions = Object.keys(optionsObject).filter(id => optionsObject[id].excluded);
        let cnt = excludedMainOptions.length;
        if (otherOptionIncluded) {
            cnt++;
        }
        return cnt;
    }

    static mainIncludedOptions(optionsObject) {
        const included = {};
        for (let id in optionsObject) {
            if (optionsObject[id].included) {
                included[id] = optionsObject[id];
            }
        }
        return included;
    }

    static mainExcludedOptions(optionsObject) {
        const included = {};
        for (let id in optionsObject) {
            if (optionsObject[id].excluded) {
                included[id] = optionsObject[id];
            }
        }
        return included;
    }

    static mainIncludedOptionValues(optionsObject) {
        return Object.values(this.mainIncludedOptions(optionsObject));
    }

    static mainExcludedOptionValues(optionsObject) {
        return Object.values(this.mainExcludedOptions(optionsObject));
    }

    static mainIncludedOptionsCount(optionsObject) {
        return Object.keys(this.mainIncludedOptions(optionsObject)).length;
    }

    static mainExcludedOptionsCount(optionsObject) {
        return Object.keys(this.mainExcludedOptions(optionsObject)).length;
    }
}
