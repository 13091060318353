<template>
  <div class="field">
    <div
      :style="{ 'width': fieldWidth }"
      class="filter__item_switch"
    >
      <div :class="hasSubFilters ? 'filter__item_detach' : ''">
        <ToggleSwitch
          :id="filter.name"
          :title="filter.title"
          :chosen="filter.selected"
          :disabled="filter.disabled"
          :mode="['exportbase']"
          @set-chosen-item="toggleFilter"
        />
        <div
          v-if="hasSubFilters && filter.selected"
          class="container_subFilter_title-list"
        >
          <SubFilterCheckBoxes
            :items="subFilters"
            :mode="['dark']"
            :space-between="5"
            :filter-name="filter.name"
            @chooseCheckBox="changeSubFilter"
          />
        </div>
      </div>
      <span
        v-if="filter.name === 'main_inn'"
        class="inn_instruction instruction_for_e_filter popup"
        data-file="/api/popup/main_inn_ogrn_decision_maker_instruction"
      />
    </div>
    <div
      v-if="!withoutCheckBox"
      class="filter__item_checkbox"
    >
      <div :class="hasSubFilters ? 'filter__item_detach' : ''">
        <div @click="showFilterAlert(filter)">
          <CheckBox
            v-if="filter.name !== 'name'"
            :id="filter.name"
            :chosen="filter.selected && !filter.partiallySelected"
            :disabled="!filter.selected || filter.disabled"
            :partially-fill="filter.selected && filter.partiallySelected"
            :title="getFieldText"
            :mode="getCheckBoxMode"
            @change="changeFilter"
          />
        </div>
        <MoreFieldsEnumTooltip
          v-if="filter.name === 'name'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue';
import MoreFieldsEnumTooltip from '@/egrul/components/MoreFieldsEnumTooltip/MoreFieldsEnumTooltip.vue';
import SubFilterCheckBoxes from '@/main_base/components/SubFilters/SubFilterCheckBoxes.vue';
import ToggleSwitch from 'toggle-switch';
import CheckBox from 'checkbox';

export default defineComponent({
  name: 'MainBaseField',
  components: {
    ToggleSwitch,
    CheckBox,
    MoreFieldsEnumTooltip,
    SubFilterCheckBoxes,
  },

  props: {
    filter: {
      type: Object,
      required: false,
      default: () => {},
    },

    subFilters: {
      type: Array,
      required: false,
      default: () => [],
    },

    fieldWidth: {
      type: String,
      required: false,
      default: '',
    },

    withoutCheckBox: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ['changeFilter', 'toggleFilter', 'changeSubFilter'],

  setup(props, { emit }) {
    const getFieldText = computed(() => {
      if (props.filter.selected) {
        if (props.filter.partiallySelected) {
          return 'Заполнено там, где есть';
        }
        return 'Обязательное поле';
      }
      return 'Поле не выбрано';
    });

    const getCheckBoxMode = computed(() => (props.filter.selected && !props.filter.disabled ? ['dark'] : ['dark', 'disabled']));

    const hasSubFilters = computed(() => !!props.subFilters.length);

    const changeFilter = () => {
      emit('changeFilter', props.filter.name);
    };

    const toggleFilter = () => {
      emit('toggleFilter', props.filter.name);
    };

    const changeSubFilter = (payload) => {
      emit('changeSubFilter', payload.subFilterName);
    };

    const showFilterAlert = (filter) => {
      if (!filter.selected) {
        window.echo('Данный фильтр не выбран');
      }
    };

    return {
      getFieldText,
      getCheckBoxMode,
      hasSubFilters,
      changeFilter,
      toggleFilter,
      changeSubFilter,
      showFilterAlert,
    };
  },
});
</script>

<style scoped>
.field {
  display: flex;
  gap: 5px;
  width: 450px;
}

.filter__item_switch {
  display: flex;
}

.filter__item_checkbox {
  display: block;
  text-align: left;
}

.container_subFilter_title-list {
  margin-top: 15px;
  margin-left: 25px;
}

.actualization__explain_email_subFilter__wrapper {
  padding: 2% 0 3%;
  width: 280px;
  font-style: italic;
}

.actualization__explain_phone_subFilter__wrapper {
  padding-top: 15px;
  font-style: italic;
  color: rgb(0 0 0 / 50%);
  line-height: 1.3;
}

.explanationWarning {
  padding-top: 2%;
  color: #F9701F;
}

.field :deep(.checkbox-button_disabled) {
  opacity: 0;
}

.inn_instruction {
  float: none;
  cursor: pointer;
  display: inline-block;
  position: relative;
  z-index: 0;
  background: url('/dist/css/images/exp_question.png') top left no-repeat;
  width: 17px;
  height: 17px;
  margin-left: -15px;
}

@media (width <= 1100px) and (width >= 1030px) {
  .actualization__explain_phone_subFilter__wrapper {
    width: 260px;
  }
}

@media (width <= 1030px) and (width >= 800px) {
  .actualization__explain_phone_subFilter__wrapper {
    width: 240px;
  }
}

@media (width <= 1030px) and (width >= 800px) {
  .actualization__explain_email_subFilter__wrapper {
    width: 210px;
  }
}
</style>
