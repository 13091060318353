<template>
  <div class="section-district scrollable-Y">
    <div v-if="getSelectedCities.length !== 1">
      <div>
        {{ getSelectedCities.length > 0 ? 'У вас выбрано: ' : '' }}
        <span class="span__selected_cities_count">{{ selectedCitiesCountText }}</span>
      </div>
      <br>
      <div class="change-district__title">
        Для того, чтобы настроить выборку по районам, необходимо выбрать
        {{ getSelectedCities.length > 1 ? 'только один' : '' }} город.
      </div>
      <br>
      <div
        v-if="districtSelectionIsDisabled"
        class="returnToCitySelectionText"
        @click="openCitySelector"
      >
        Вернуться к выбору города.
      </div>
    </div>
    <div v-else-if="Object.keys(getSelectedCitiesWithDistrictsList).length === 0">
      <div class="change-district__title">
        К сожалению, для выбранного города нельзя настроить выборку по районам.
      </div>
      <div
        class="returnToCitySelectionText"
        @click="openCitySelector"
      >
        Вернуться к выбору города.
      </div>
    </div>
    <div v-else>
      <div>
        <span class="change-district__title">
          Выберите нужные районы для города
        </span>
        <span class="change-district__title__city">
          {{ getSelectedCityWithDistricts.name }}
        </span>
      </div>
      <div class="cityDistrictSelector">
        <transition name="slide">
          <div>
            <div
              v-if="getSelectedCityWithDistricts.hasGroups"
              class="dropdownSelector mainDropdownDistrictSelector"
            >
              <section class="selectorOptionsSection">
                <div class="selectorHead companyTypeSelectorHead">
                  <InputSearch
                    :value="getSearchDistrict"
                    @updateValue="updateSearchDistrictValue"
                    :focus="true"
                    class="selectorInput"
                  />
                  <div class="inclusionOptionsItem">
                    <div class="inclusionOptionTitle">
                      Включено
                    </div>
                    <div class="inclusionOptionSubtitle">
                      в заказ
                    </div>
                  </div>
                </div>
                <DropdownOption
                  selector-label="Все районы"
                  :chosen="allEnabled"
                  class="enableAllOption"
                  @inclusion="enableAllCityDistricts(getSelectedCityWithDistricts.id)"
                />
                <DropDownGroups
                  :groups="getGroupsForSelectedCity"
                  :options="getSelectedCityWithDistricts.districts"
                  :selector-type="getSelectedCityWithDistricts.id"
                  @inclusionIfManySelectorsExist="toggleCityDistrict"
                  @clickGroupIfManySelectorsExist="clickGroup"
                />
              </section>
            </div>
            <div v-else>
              <DropdownSelector
                class="mainDropdownSelector mainDropdownDistrictSelector"
                :enable-all-option="cityEnableAllOption"
                :options="getSelectedCityWithDistricts.districts"
                selector-name="Районы"
                :selector-type="getSelectedCityWithDistricts.id"
                @enableAll="enableAllCityDistricts(getSelectedCityWithDistricts.id)"
                @include="toggleCityDistrict"
              />
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import DropdownSelector from '@/main_base/components/DropdownSelector/DropdownSelector.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import DropdownOption from '@/main_base/components/DropdownOption/DropdownOption.vue';
import DropDownGroups from '@/main_base/components/DropDownGroups/DropDownGroups.vue';
import InputSearch from '@/common_components/InputSearch/InputSearch.vue';
import debounce from 'lodash.debounce';
import plural from 'plural-ru';

export default {
  name: 'SectionDistrict',
  components: {
    InputSearch,
    DropDownGroups,
    DropdownOption,
    DropdownSelector,
  },

  computed: {
    ...mapGetters('geoTree', [
      'getSelectedCitiesWithDistrictsList',
      'getSelectedCities',
      'getGroupsForSelectedCity',
      'getSelectedCityWithDistricts',
      'getSearchDistrict',
    ]),

    districtSelectionIsDisabled() {
      return this.getSelectedCities.length !== 1;
    },

    cityEnableAllOption() {
      return { title: 'Все районы' };
    },

    selectedCitiesCountText() {
      if (this.getSelectedCities.length == 0) {
        return 'Города не выбраны';
      }

      return `${this.getSelectedCities.length} ${plural(this.getSelectedCities.length, 'город', 'города', 'городов')}.`;
    },

    allEnabled() {
      return Object.keys(
        this.getSelectedCityWithDistricts.districts,
      )
        .every((id) => !this.getSelectedCityWithDistricts.districts[id].included);
    },
  },

  watch: {
    getSearchDistrict: {
      handler: debounce(function () {
        if (this.getSearchDistrict === '') {
          this.resetSearch();
        } else {
          this.search();
        }
      }, 300),
    },
  },

  methods: {
    ...mapActions(
      'geoTree',
      [
        'toggleCityDistrict',
        'enableAllCityDistricts',
        'updateSearchDistrictValue',
        'resetSearch',
        'search',
      ],
    ),

    ...mapMutations(
      'geoTree',
      ['toggleActiveDistrictGroup', 'setChosenSection', 'setVisibleCompanyTypesByGroupId'],
    ),

    openCitySelector() {
      this.setChosenSection('city');
    },

    clickGroup(cityAndGroup) {
      this.toggleActiveDistrictGroup(cityAndGroup);
      this.setVisibleCompanyTypesByGroupId(cityAndGroup);
    },
  },

};
</script>

<style scoped src="./../DropdownSelector/DropdownSelector.css" />

<style scoped>
.change-district__title {
  margin: 0 0 20px 0;
}

.change-district__title__city {
  font-weight: bold;
  font-size: 20px;
  position: relative;
  top: 1px;
  padding-left: 2px;
}

.section-district {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 50px;
}

.cityDistrictSelector {
  margin-top: 20px;
  margin-bottom: 30px;
}

.returnToCitySelectionText {
  text-decoration: underline;
  font-weight: bolder;
  margin-bottom: 30px;
}
.returnToCitySelectionText:hover {
  cursor: pointer;
}
.span__selected_cities_count {
  font-weight: bolder;
}
.dropdownSelector {
  width: 90%;
}
</style>
