<template>
  <div class="subFilter-list">
    <div
      v-for="(item, index) in items"
      :key="index + '_' + filterName + 'sub-filter-checkbox'"
      :style="spaceBetweenItems(index)"
      class="subFilter-row"
    >
      <CheckBox
        :id="item.name"
        :title="item.title"
        :title-width="titleWidth"
        :chosen="item.selected"
        :disabled="isDisabled(item.name)"
        :mode="mode"
        @change="chooseCheckBox"
      />
      <span
        v-if="item.name === 'only_mobile_phones'"
        class="explanation popup"
        data-file="/api/popup/main_only_mobile_instruction"
      />
      <span
        v-if="item.name === 'has_site_phone'"
        class="explanation popup"
        data-file="/api/popup/main_has_site_phone_instruction"
      />
      <span
        v-if="item.name === 'has_messenger'"
        class="explanation popup"
        data-file="/api/popup/main_has_messenger_instruction"
      />
      <span
        v-if="item.name === 'email_verified'"
        class="explanation _popup"
        data-method="GET"
        data-file="api/popup/egrul_validated_email_info"
      />
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue';
import { useStore } from 'vuex';
import CheckBox from 'checkbox';

export default defineComponent({
  name: 'SubFilterCheckBoxes',
  components: {
    CheckBox,
  },

  props: {
    items: {
      type: Array,
      required: false,
      default: () => [],
    },

    filterName: {
      type: String,
      required: false,
      default: () => [],
    },

    mode: {
      type: Array,
      required: false,
      default: () => [],
    },

    titleWidth: {
      type: Number,
      required: false,
    },

    spaceBetween: {
      type: Number,
      required: false,
      default: 5,
    },
  },

  emits: ['chooseCheckBox'],

  setup(props, { emit }) {
    const store = useStore();

    const withoutSite = computed(() => store.state.withoutSite);

    const isDisabled = (subfilterName) => subfilterName === 'has_site_phone' && Boolean(withoutSite.value);

    const chooseCheckBox = (id) => {
      emit('chooseCheckBox', {
        mainFilterName: props.filterName,
        subFilterName: id,
      });
    };

    const spaceBetweenItems = (index) => {
      if (props.spaceBetween === undefined) {
        return '';
      }
      return index === props.items.length - 1 ? '' : `margin-bottom:${props.spaceBetween}px`;
    };

    return {
      withoutSite,
      isDisabled,
      chooseCheckBox,
      spaceBetweenItems,
    };
  },
});
</script>

<style scoped>
.subFilter-list {
  display: flex;
  flex-direction: column;
  list-style-type: disc;
}

.subFilter-row {
  display: flex;
  flex-direction: row;
  width: 200px;
  align-items: center;
  gap: 5px;
}

.explanation {
  width: 17px;
  height: 17px;
  margin-left: 3px;
  background: url("../../../assets/images/icon_query.png") center center no-repeat;
  cursor: pointer;
}

</style>
