<!-- eslint-disable vue/v-on-handler-style -->

<template>
  <div>
    <div
      class="additional_filter_item _option_button"
      data-filter="only_mobile_phones"
    >
      <Checkbox
        :chosen="!!onlyMobilePhones"
        :mode="['dark']"
        title="только мобильные"
        @change="toggleAdditionalFilter('only_mobile_phones')"
      />
      <span
        class="instruction popup"
        data-file="api/popup/main_only_mobile_instruction"
      />
    </div>
    <div class="additional_filter_item _option_button">
      <Checkbox
        :chosen="!!hasSitePhone"
        :disabled="!!withoutSite"
        :mode="getHasSitePhoneCheckBoxMode"
        title="только телефоны с сайтов"
        @change="toggleAdditionalFilter('has_site_phone')"
      />
      <span
        class="instruction popup"
        data-file="api/popup/main_has_site_phone_instruction"
      />
    </div>
    <div class="additional_filter_item _option_button">
      <Checkbox
        :chosen="!!hasMessenger"
        :mode="['dark']"
        title="есть мессенджер"
        @change="toggleAdditionalFilter('has_messenger')"
      />
      <span
        class="instruction popup"
        data-file="api/popup/main_has_messenger_instruction"
      />
    </div>
  </div>
</template>

<script>
import Checkbox from 'checkbox';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'MainBasePhoneSubfilters',
  components: {
    Checkbox,
  },

  computed: {
    ...mapState([
      'onlyMobilePhones',
      'onlyUniquePhones',
      'hasMessenger',
      'hasSitePhone',
      'withoutSite',
    ]),

    getHasSitePhoneCheckBoxMode() {
      return this.withoutSite
          ? ['dark', 'main_filters_option', 'disabled']
          : ['dark', 'main_filters_option'];
    },
  },

  methods: {
    ...mapActions([
      'toggleAdditionalFilter',
    ]),
  },
};
</script>

<style scoped>
.additional_filter_item {
  display: flex;
  margin-bottom: 9px;
  align-items: center;
}

.instruction {
  cursor: pointer;
  display: inline-block;
  position: relative;
  z-index: 0;
  background: url("../../../assets/images/icon_query.png") center center no-repeat;
  width: 17px;
  height: 17px;
  margin-left: 5px;
}
</style>
