import Fuse from 'fuse.js';
import MainBaseConfiguratorUtil from './utils/MainBaseConfiguratorUtil';
import MultiSelectorUtil from '../../common_components/MultiSelector/MultiSelectorUtil';

const mutations = {
    setCalculating(state, value) {
        state.isCalculating = value;
    },
    setDemoCreationIsRunning(state, value) {
        state.demoCreationIsRunning = value;
    },
    enableAllCompanyTypes(state) {
        for (const id in state.companyType) {
            state.companyType[id].included = false;
            state.companyType[id].excluded = false;
            state.companyType[id].isActive = false;
        }
        for (const id in state.companyTypeGroups) {
            state.companyTypeGroups[id].highlight = false;
        }
    },
    enableAllLegalForms(state) {
        for (const id in state.legalForm) {
            if (id === 'closed') {
                continue;
            }
            state.legalForm[id].included = false;
        }
        state.unknownLegalFormIncluded = false;
    },
    enableAllCompanySizes(state) {
        for (const id in state.companySize) {
            state.companySize[id].included = false;
        }
        state.unknownCompanySizeIncluded = false;
    },

    toggleIncludeType(state, { selectorType, id }) {
        state[selectorType][id].included = !state[selectorType][id].included;

        if (state[selectorType][id].excluded) {
            state[selectorType][id].excluded = !state[selectorType][id].excluded;
        }
    },

    toggleExcludeType(state, { selectorType, id }) {
        state[selectorType][id].excluded = !state[selectorType][id].excluded;

        if (state[selectorType][id].included) {
            state[selectorType][id].included = !state[selectorType][id].included;
        }
    },

    resetToggleIncludeType(state, { selectorType, id }) {
        state[selectorType][id].included = false;
    },

    highlightActiveType(state, { selectorType, id }) {
        state[selectorType][id].isActive = true;

        setTimeout(() => {
            state[selectorType][id].isActive = false;
        }, 3000);
    },
    toggleUnknownLegalFormIncluded(state) {
        state.unknownLegalFormIncluded = !state.unknownLegalFormIncluded;
    },
    toggleUnknownCompanySizeIncluded(state) {
        state.unknownCompanySizeIncluded = !state.unknownCompanySizeIncluded;
    },

    toggleIncludeClosed(state) {
        state.legalForm.closed.included = !state.legalForm.closed.included;
        state.legalForm.closed.excluded = !state.legalForm.closed.excluded;
    },

    toggleAdditionalFilter(state, filter) {
        state[filter] = +!state[filter];

        if (filter === 'withoutSite' && state.withoutSite) {
            const sitePhoneSubfilter = state.mainBasePhoneFilter.subFilters.find((subFilter) => subFilter.name === 'has_site_phone');
            if (sitePhoneSubfilter) {
                sitePhoneSubfilter.selected = false;
            }
        }
    },
    setOnlyWithoutSite(state, value) {
        state.withoutSite = value;
    },
    setWithoutSite(state, value) {
        state.withoutSite = value;
    },
    setShowSiteDescription(state, value) {
        state.showSiteDescription = value;
    },
    setRegYears(state, years) {
        state.regYears = years;
    },
    changeRegYear(state, value) {
        const changedYear = MultiSelectorUtil.findByKey(state.regYears, value);
        changedYear.selected = !changedYear.selected;
    },
    allRegYearsSelect(state) {
        state.regYears = MultiSelectorUtil.unselectAll(state.regYears);
    },

    setCompanyType(state, companyType) {
        const popupUrls = {
            on_wb_active: '/api/popup/wb_description',
        };
        const descriptions = {
            usn: 'Упрощенная система налогообложения',
            eshn: 'Единый сельскохозяйственный налог',
        };

        const companyTypesState = {};
        companyType.forEach((companyType) => {
            companyTypesState[companyType.id] = {
                id: companyType.id,
                title: companyType.title,
                title_accusative: companyType.title_accusative,
                included: false,
                excluded: false,
                isActive: false,
                popupUrl: popupUrls[companyType.id],
                firstInGroup: (companyType.id === 'on_ozon' || companyType.id === 'sro'),
                groupId: companyType.id_group,
                visible: false,
                hidden: false,
                description: descriptions[companyType.id],
            };
        });
        state.companyType = companyTypesState;
    },
    setFuse(state, companyType) {
        const data = companyType.map((item) => ({
            ...item,
            groupId: item.id_group,
        })).filter((item) => item.id_group !== null);

        state.fuse = new Fuse(data, state.fuseOptions);
    },
    setVisibleCompanyTypesByGroupId(state, groupId) {
        if (state.searchCompanyType === '') {
            for (const id in state.companyType) {
                if (state.companyType[id].groupId === groupId) {
                    state.companyType[id].visible = !state.companyType[id].visible;
                }
            }
        } else {
            Object.entries(state.companyType)
                .filter(([key]) => state.companyType[key].groupId === groupId)
                .forEach(([key]) => {
                    state.companyType[key].hidden = !state.companyType[key].hidden;
                });
        }
    },
    setCompanyTypeGroups(state, groups) {
        const groupsState = {};

        for (const [id, groupName] of Object.entries(groups)) {
            groupsState[Number(id)] = {
                id: Number(id),
                name: groupName,
                active: false,
                show: true,
                highlight: false,
            };
        }
        state.companyTypeGroups = groupsState;
    },
    setShowCompanyTypeGroup(state, { groupId, show }) {
        state.companyTypeGroups[groupId].show = show;
    },
    toggleActiveGroup(state, groupId) {
        for (const [id, group] of Object.entries(state.companyTypeGroups)) {
            group.active = group.id === groupId ? !group.active : group.active;
        }
    },
    hideGroups(state) {
        for (const [id, group] of Object.entries(state.companyTypeGroups)) {
            group.active = false;
        }
    },
    hideTypes(state) {
        for (const id in state.companyType) {
            state.companyType[id].visible = false;
        }
    },
    resetHiddenTypes(state) {
        Object.entries(state.companyType).forEach(([, companyType]) => {
            companyType.hidden = false;
        });
    },
    setLegalForm(state, legalForms) {
        const legalFormsState = {};
        legalForms.forEach((legalForm) => {
            legalFormsState[legalForm.id] = legalForm.id !== 'closed'
                ? {
                    id: legalForm.id,
                    title: legalForm.title,
                    included: false,
                }
                : {
                    id: legalForm.id,
                    title: legalForm.title,
                    included: false,
                    excluded: true,
                };
        });
        state.legalForm = legalFormsState;
    },
    setCompanySize(state, companySizes) {
        const companySizesState = {};
        companySizes.forEach((companySize) => {
            companySizesState[companySize.id] = {
                id: companySize.id,
                title: companySize.title,
                included: false,
            };
        });
        state.companySize = companySizesState;
    },

    activateAllGroups(state) {
        for (const [id, group] of Object.entries(state.companyTypeGroups)) {
            group.active = true;
        }
    },
    visibleAllCompanyTypes(state) {
        Object.entries(state.companyType).forEach(([key]) => {
            state.companyType[key].visible = true;
        });
    },

    setSearchCompanyType(state, value) {
        state.searchCompanyType = value;
    },

    highlightCompanyTypeGroup(state, id) {
        const companyTypeGroupId = state.companyType[id].groupId;

        const companyTypes = [];
        for (const [key, value] of Object.entries(state.companyType)) {
            companyTypes.push({ ...value });
        }
        const groupCompanyTypes = companyTypes
            .filter((CompanyType) => CompanyType.groupId === companyTypeGroupId);

        state.companyTypeGroups[companyTypeGroupId].highlight = groupCompanyTypes.some(
            (companyType) => companyType.included,
        );
    },

    togglePhone(state, isPartiallySelectedChange = false) {
        if (isPartiallySelectedChange ) {
            state.mainBasePhoneFilter.partiallySelected = !state
                .mainBasePhoneFilter.partiallySelected;
            state.selectedOptionalFilters = MainBaseConfiguratorUtil
                .pushOrSpliceParam(
                    state.mainBasePhoneFilter.partiallySelected,
                    'phone',
                    state.selectedOptionalFilters,
                );
        } else {
            state.mainBasePhoneFilter.selected = !state
                .mainBasePhoneFilter.selected;
            state.selectedBasicFilters = MainBaseConfiguratorUtil
                .pushOrSpliceParam(
                    state.mainBasePhoneFilter.selected,
                    'phone',
                    state.selectedBasicFilters,
                );
        }
    },

    toggleAddress(state, isPartiallySelectedChange = false) {
        if (isPartiallySelectedChange) {
            state.mainBaseAddressFilter.partiallySelected = !state
                .mainBaseAddressFilter.partiallySelected;
            state.selectedOptionalFilters = MainBaseConfiguratorUtil
                .pushOrSpliceParam(
                    state.mainBaseAddressFilter.partiallySelected,
                    'address',
                    state.selectedOptionalFilters,
                );
        } else {
            state.mainBaseAddressFilter.selected = !state
                .mainBaseAddressFilter.selected;
            state.selectedBasicFilters = MainBaseConfiguratorUtil
                .pushOrSpliceParam(
                    state.mainBaseAddressFilter.selected,
                    'address',
                    state.selectedBasicFilters,
                );
            state.selectedBasicFilters = MainBaseConfiguratorUtil.pushOrSpliceParam(
                state.mainBaseAddressFilter.selected,
                'post_index',
                state.selectedBasicFilters,
            );
        }
    },

    toggleEmail(state, isPartiallySelectedChange = false) {
        if (isPartiallySelectedChange) {
            state.mainBaseEmailFilter.partiallySelected = !state
                .mainBaseEmailFilter.partiallySelected;
            state.selectedOptionalFilters = MainBaseConfiguratorUtil
                .pushOrSpliceParam(
                    state.mainBaseEmailFilter.partiallySelected,
                    'email',
                    state.selectedOptionalFilters,
                );
        } else {
            state.mainBaseEmailFilter.selected = !state
                .mainBaseEmailFilter.selected;
            state.selectedBasicFilters = MainBaseConfiguratorUtil
                .pushOrSpliceParam(
                    state.mainBaseEmailFilter.selected,
                    'email',
                    state.selectedBasicFilters,
                );
        }
    },

    toggleSite(state, isPartiallySelectedChange = false) {
        if (isPartiallySelectedChange) {
            state.mainBaseSiteFilter.partiallySelected = !state
                .mainBaseSiteFilter.partiallySelected;
            state.selectedOptionalFilters = MainBaseConfiguratorUtil
                .pushOrSpliceParam(
                    state.mainBaseSiteFilter.partiallySelected,
                    'site',
                    state.selectedOptionalFilters,
                );
        } else {
            state.mainBaseSiteFilter.selected = !state.mainBaseSiteFilter.selected;
            state.selectedBasicFilters = MainBaseConfiguratorUtil.pushOrSpliceParam(
                state.mainBaseSiteFilter.selected,
                'site',
                state.selectedBasicFilters,
            );
        }
    },

    toggleCompanySize(state, isPartiallySelectedChange = false) {
        if (isPartiallySelectedChange) {
            state.mainBaseShowCompanySizeFilter.partiallySelected = !state
                .mainBaseShowCompanySizeFilter.partiallySelected;
            state.selectedOptionalFilters = MainBaseConfiguratorUtil.pushOrSpliceParam(
                state.mainBaseShowCompanySizeFilter.partiallySelected,
                'show_company_size',
                state.selectedOptionalFilters,
            );
        } else {
            state.mainBaseShowCompanySizeFilter.selected = !state
                .mainBaseShowCompanySizeFilter.selected;
            state.selectedBasicFilters = MainBaseConfiguratorUtil.pushOrSpliceParam(
                state.mainBaseShowCompanySizeFilter.selected,
                'show_company_size',
                state.selectedBasicFilters,
            );
        }
    },

    toggleInnOgrn(state, isPartiallySelectedChange = false) {
        if (isPartiallySelectedChange) {
            state.mainBaseInnOgrnFilter.partiallySelected = !state
                .mainBaseInnOgrnFilter.partiallySelected;
            state.selectedOptionalFilters = MainBaseConfiguratorUtil.pushOrSpliceParam(
                state.mainBaseInnOgrnFilter.partiallySelected,
                'inn',
                state.selectedOptionalFilters,
            );
        } else {
            state.mainBaseInnOgrnFilter.selected = !state.mainBaseInnOgrnFilter.selected;
            state.selectedBasicFilters = MainBaseConfiguratorUtil.pushOrSpliceParam(
                state.mainBaseInnOgrnFilter.selected,
                'inn',
                state.selectedBasicFilters,
            );
        }
    },

    togglePhoneSubFilters(state, subFilterName) {
        const currentSubFilterIndex = state.mainBasePhoneFilter
            .subFilters.findIndex((subFilters) => subFilters.name === subFilterName);

        const updatedSubFilter = { ...state.mainBasePhoneFilter.subFilters[currentSubFilterIndex] };
        updatedSubFilter.selected = !updatedSubFilter.selected;

        state.mainBasePhoneFilter.subFilters = [
            ...state.mainBasePhoneFilter.subFilters.slice(0, currentSubFilterIndex),
            updatedSubFilter,
            ...state.mainBasePhoneFilter.subFilters.slice(currentSubFilterIndex + 1),
        ];
    },

    toggleEmailVerifiedSubFilter(state) {
        state.mainBaseEmailFilter.subFilters[0].selected = !state
            .mainBaseEmailFilter.subFilters[0].selected;
    },

    toggleExpandAdditionalFilters(state) {
        state.expandAdditionalFilters = !state.expandAdditionalFilters;
    },

    setFloorInclusion(state, { id, included }) {
        const floor = state.floors.find((floor) => floor.id == id);

        floor.included = included;
    },

    setFloorExclusion(state, { id, excluded }) {
        const floor = state.floors.find((floor) => floor.id == id);

        floor.excluded = excluded;
    },

    setNeedRecalculation(state, value) {
        state.needRecalculation = value;
    },

    initializeGeoData(state, data) {
        const { count, name } = data;
        const { countByGeoSubject } = count;

        state.geoData = Object.keys(countByGeoSubject).map((geoId) => {
            const regionName = name.regions[geoId] || '';
            const count = countByGeoSubject[geoId] || 0;

            return { geoId, name: regionName, count };
        });
    },

    initializeBallonInfo(state, data) {
        state.baloonInfo = data;
    },

    resetPartiallySelected(state) {
        const filterNames = [
            'mainBasePhoneFilter',
            'mainBaseAddressFilter',
            'mainBaseEmailFilter',
            'mainBaseSiteFilter',
            'mainBaseInnOgrnFilter',
            'mainBaseShowCompanySizeFilter',
        ];

        filterNames.forEach((filterName) => {
            if (state[filterName]) {
                state[filterName].partiallySelected = false;
            }
        });

        state.selectedOptionalFilters = [];
    },
};

export default mutations;
