<template>
  <div class="dropdownSelector">
    <div class="selectorBorderVertical" />

    <div class="selectorHead">
      <div class="selectorHeadOptionTitle">
        {{ this.selectorName }}
      </div>
      <div class="inclusionOptionsItem">
        <div class="inclusionOptionTitle">
          Включено
        </div>
        <div class="inclusionOptionSubtitle">
          в заказ
        </div>
      </div>
    </div>

    <section class="selectorOptionsSection">
      <DropdownOption
        :selector-label="enableAllOption.title"
        :chosen="allEnabled"
        @inclusion="enableAll"
        class="enableAllOption"
      />

      <DropdownOption
        v-for="(option) in options"
        v-if="!groups"
        :class="{firstInGroup: option.firstInGroup}"
        :selector-label="option.title"
        :selector-label-accusative="option.title_accusative"
        :chosen="option.included"
        @inclusion="toggleIncludeOption(option.id)"
        :is-active="option.isActive"
        :popup-url="option.popupUrl"
        :description="option.description"
      />

      <DropdownOption
        v-if="otherOption"
        :selector-label="otherOption.title"
        :selector-label-accusative="otherOption.title_accusative"
        :chosen="otherOption.included"
        :popup-url="otherOption.popupUrl"
        @inclusion="toggleOtherOption"
        class="otherOption"
      />
    </section>

    <section
      v-if="extraOption"
      class="selectorOptionsSection extraOption"
    >
      <DropdownOption
        :selector-label="extraOption.title"
        :selector-label-accusative="extraOption.title_accusative"
        :chosen="extraOption.included"
        @inclusion="toggleExtraOption"
      />
    </section>
  </div>
</template>

<script>
import DropdownOption from '../DropdownOption/DropdownOption';

export default {
  name: 'DropdownSelector',
  components: {
    DropdownOption,
  },
  props: {
    selectorName: {
      type: String,
      required: true,
    },
    enableAllOption: {
      type: Object,
      required: false,
    },
    options: {
      type: Object,
      required: true,
    },
    groups: {
      type: Array,
      required: false,
      default: null,
    },
    otherOption: {
      type: Object,
      required: false,
    },
    extraOption: {
      type: Object,
      required: false,
    },
    selectorType: {
      type: String,
      required: true,
    },
  },

  computed: {
    allEnabled() {
      return (
        Object.keys(this.options).every(id => !this.options[id].included)
        && (!this.otherOption || !this.otherOption.included)
      );
    },
  },

  methods: {
    enableAll() {
      this.$emit('enableAll');
    },

    toggleIncludeOption(id) {
      this.$emit('include', {
        selectorType: this.selectorType,
        id,
      });
    },

    toggleOtherOption() {
      this.$emit('toggleOtherOption');
    },

    toggleExtraOption() {
      this.$emit('toggleExtraOption');
    },
  },
};
</script>

<style scoped src="./DropdownSelector.css" />
