<template>
  <Popup
    @close="$emit('hideMoreCompaniesInfoPopup')"
  >
    <template #content>
      <div class="contentWrapper">
        <span v-if="allMethods" class="popupHeader">Как получить больше компаний?</span>
        <div
          v-if="mainActivityType || allMethods"
          class="oneOfMethods"
        >
          <span v-if="allMethods" class="methodHeader">
            Отключение опции “Основной вид деятельности”
          </span>
          <span v-else class="popupHeader">
            Основной вид деятельности
          </span>
          <span v-if="allMethods" class="methodDescription">
            Отключив фильтрацию по основному виду деятельности, вы получите компании у которых
            выбранный вид деятельности необязательно является основным.
          </span>
          <div class="example">
            <div class="enabledFilter">
              <img
                src="./img/car_wash.webp"
                style="border-radius: 5px;"
                alt="Фильтр включен"
                width="210px"
                height="194px"
              >
              <div>
                <p class="exampleHeader">Фильтр включен</p>
                <div class="exampleText">
                  В выборку попадают только компании где выбранный
                  вид деятельности является основным.
                  <br><br>
                  <b>Пример:</b>
                  <br>
                  При выборе рубрики “автомойки” в выборке будут <b>только автомойки</b>,
                  автомастерские с мойками будут исключены.
                </div>
              </div>
            </div>
            <div class="disabledFilter">
              <img
                src="./img/car_service.webp"
                style="border-radius: 5px;"
                alt="Фильтр выключен"
                width="210px"
                height="194px"
              >
              <div>
                <p class="exampleHeader">Фильтр выключен</p>
                <div class="exampleText">
                  В выборку попадают все компании с выбранным видом деятельности.
                  <br><br>
                  <b>Пример:</b>
                  <br>
                  При выборе рубрики “автомойки”, в вашей выборке будут
                  <b>как автомойки так и автосервисы с автомойкой.</b>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="mainActivityTypeOkved || allMethods"
          class="oneOfMethods"
        >
          <span v-if="allMethods" class="methodHeader">
            Отключение опции “Основной вид деятельности”
          </span>
          <span v-else class="popupHeader">
            Основной вид деятельности
          </span>
          <span v-if="allMethods" class="methodDescription">
            Отключив фильтрацию по основному виду деятельности, вы получите компании у которых
            выбранный вид деятельности необязательно является основным.
          </span>
          <div class="example">
            <div class="enabledFilter">
              <img
                src="./img/car_wash.webp"
                style="border-radius: 5px;"
                alt="Фильтр включен"
                width="210px"
                height="194px"
              >
              <div>
                <p class="exampleHeader">Фильтр включен</p>
                <div class="exampleText">
                  В выборку попадают только компании где выбранный
                  вид деятельности является основным.
                  <br><br>
                  <b>Пример:</b>
                  <br>
                  При выборе ОКВЭДа "45.20.3 Мойка автотранспортных средств, полирование и предоставление аналогичных
                  услуг" в выборке будут <b>только автомойки(ОКВЭД 45.20.3)</b>,
                  автомастерские с мойками будут исключены.
                </div>
              </div>
            </div>
            <div class="disabledFilter">
              <img
                src="./img/car_service.webp"
                style="border-radius: 5px;"
                alt="Фильтр выключен"
                width="210px"
                height="194px"
              >
              <div>
                <p class="exampleHeader">Фильтр выключен</p>
                <div class="exampleText">
                  В выборку попадают все компании с выбранным видом деятельности.
                  <br><br>
                  <b>Пример:</b>
                  <br>
                  При выборе ОКВЭДа "45.20.3 Мойка автотранспортных средств, полирование и предоставление аналогичных
                  услуг", в вашей выборке будут
                  <b>как автомойки(ОКВЭД 45.20.3) так и автосервисы с автомойкой(другие ОКВЭД).</b>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Popup>
</template>

<script>
import Popup from '@/common_components/Popup/Popup.vue';

export default {
  name: 'MoreCompaniesInfoPopup',
  components: { Popup },

  props: {
    // При добавлении новых способов увеличения кол-ва компаний - не забыть про метод allMethods
    mainActivityType: {
      type: Boolean,
      default: false,
      required: false,
    },

    mainActivityTypeOkved: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  emits: ['hideMoreCompaniesInfoPopup'],

  computed: {
    allMethods() {
      return !this.mainActivityType && !this.mainActivityTypeOkved;
    },
  },
};
</script>

<style scoped>
.contentWrapper {
  width: 700px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  line-height: normal;
}

.oneOfMethods {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.popupHeader {
  font-weight: 700;
  font-size: 24px;
}

.methodHeader, .exampleHeader {
  font-weight: 700;
  font-size: 20px;
}

.methodDescription, .exampleText {
  font-weight: 400;
  font-size: 16px;
}

.exampleText {
  padding: 10px;
}

.example {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 15px;
}

.enabledFilter, .disabledFilter {
  display: flex;
  gap: 15px;
}
</style>
