<template>
  <div class="dropdownSelectorWrapper">
    <p class="dropdownSelectorHeader">
      <b>Размер компании:</b>
    </p>
    <div
      class="dropdownSelectorButtonWrapper"
      @click.stop="$emit('selectorChange','companySize')"
    >
      <ButtonSelector
        :content="selectedCompanySizesDescription"
        :width="buttonSelectorWidth"
        class="buttonMainDropdownSelector"
      />
    </div>
    <transition name="slide">
      <DropdownSelector
        v-show="showCompanySize"
        :enable-all-option="companySizeEnableAllOption"
        :options="companySize"
        :other-option="companySizeOtherOption"
        selector-name="Размер компании"
        selector-type="companySize"
        class="mainDropdownSelector"
        :class="[{ mainDropdownSelectorVisible: showCompanySize }]"
        @enableAll="enableAllCompanySizes"
        @include="toggleIncludeType"
        @toggleOtherOption="toggleUnknownCompanySizeIncluded"
      />
    </transition>
  </div>
</template>

<script>
import { computed, inject } from 'vue';
import { useStore } from 'vuex';
import ButtonSelector from 'button-selector';
import DropdownSelector from '../DropdownSelector/DropdownSelector.vue';

export default {
  name: 'MainBaseSizeSelector',

  components: { DropdownSelector, ButtonSelector },

  props: {
    showCompanySize: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ['selectorChange'],

  setup() {
    const store = useStore();

    const selectedOptionsDescription = inject('selectedOptionsDescription');

    const companySize = computed(() => store.state.companySize);
    const unknownCompanySizeIncluded = computed(() => store.state.unknownCompanySizeIncluded);

    const companySizeEnableAllOption = computed(() => ({ title: 'Все компании' }));

    const companySizeOtherOption = computed(() => ({
      title: 'Размер не определен',
      title_accusative: 'компании, у которых не указан размер',
      included: unknownCompanySizeIncluded.value,
      popupUrl: '/api/popup/main_absent_legal_info',
    }));

    const selectedCompanySizesDescription = computed(() => selectedOptionsDescription(
      companySize.value,
      unknownCompanySizeIncluded.value,
      'Все компании',
      'Не определен',
    ));

    const buttonSelectorWidth = '155px';

    return {
      companySize,
      unknownCompanySizeIncluded,
      companySizeEnableAllOption,
      companySizeOtherOption,
      selectedCompanySizesDescription,
      buttonSelectorWidth,
      enableAllCompanySizes: () => store.dispatch('enableAllCompanySizes'),
      toggleUnknownCompanySizeIncluded: () => store.dispatch('toggleUnknownCompanySizeIncluded'),
      toggleIncludeType: (type) => store.dispatch('toggleIncludeType', type),
    };
  },
};
</script>

<style scoped>
.dropdownSelectorHeader {
  margin-bottom: 5px;
  font-size: 14px;
}

.mainDropdownSelector {
  position: absolute;
  top: 92%;
  z-index: 15;
  overflow: hidden;
  padding-bottom: 10px;
}

.slide-enter-active {
  transition-duration: 0.5s;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
  max-height: 1300px;
  overflow: hidden;
}

.slide-enter, .slide-leave-to {
  overflow: hidden;
  max-height: 0;
}

.dropdownSelectorWrapper {
  margin-bottom: 15px;
}
</style>
